import apiClient from '../axios'

export async function createOrder(body) {
  return apiClient
    .post(`/order/create`, body)
    .then(response => (response && response.data ? response.data : false)) // eslint-disable-line
    .catch(error => console.log(error.message))
}

export async function createOrderWithOrderRequest(body) {
  return apiClient
    .post(`/order/create-with-requisition`, body)
    .then(response => (response && response.data ? response.data : false)) // eslint-disable-line
    .catch(error => console.log(error.message))
}

export async function getOrders({
  page,
  pages,
  searchText,
  limit,
  sortOrder,
  sortCol,
  filterPriority,
  filterLocation,
  filterDepartment,
  filterCreatedBy,
  filterVendor,
  filterStatus,
  filterPaymentStatus,
  filterDateRangeStart,
  filterDateRangeEnd,
}) {
  return apiClient
    .get(
      `/order?page=${
        pages >= page ? page : 1
      }&search=${searchText.trim()}&limit=${limit}&sort_order=${sortOrder}&sort_col=${sortCol}&filter_priority=${filterPriority}&filter_location=${filterLocation}&filter_department=${filterDepartment}&filter_createdBy=${filterCreatedBy}&filter_vendor=${filterVendor}&filter_status=${filterStatus}&filter_paymentStatus=${filterPaymentStatus}&filter_dateRangeStart=${filterDateRangeStart}&filter_dateRangeEnd=${filterDateRangeEnd}`,
    )
    .then(response => (response && response.data ? response.data : false))
    .catch(error => console.log(error))
}

export async function getOrder(orderRef) {
  return apiClient
    .get(`/order/${orderRef}`)
    .then(response => (response && response.data ? response.data : false))
    .catch(error => console.log(error))
}

export async function updateOrder(body) {
  return apiClient
    .post(`/order/update/${body.orderRef}`, body)
    .then(response => (response && response.data ? response.data : false)) // eslint-disable-line
    .catch(error => console.log(error))
}

export async function submitOrder(orderRef) {
  return apiClient
    .post(`/order/submit/${orderRef}`)
    .then(response => (response && response.data ? response.data : false)) // eslint-disable-line
    .catch(error => console.log(error))
}

export async function openOrder(orderRef) {
  return apiClient
    .post(`/order/open/${orderRef}`)
    .then(response => (response && response.data ? response.data : false)) // eslint-disable-line
    .catch(error => console.log(error))
}

export async function reviseOrder(orderRef) {
  return apiClient
    .post(`/order/revise/${orderRef}`)
    .then(response => (response && response.data ? response.data : false)) // eslint-disable-line
    .catch(error => console.log(error))
}

export async function approveOrder(orderRef) {
  return apiClient
    .post(`/order/approve/${orderRef}`)
    .then(response => (response && response.data ? response.data : false)) // eslint-disable-line
    .catch(error => console.log(error))
}

export async function rejectOrder(orderRef) {
  return apiClient
    .post(`/order/reject/${orderRef}`)
    .then(response => (response && response.data ? response.data : false)) // eslint-disable-line
    .catch(error => console.log(error))
}

export async function completeOrder(orderRef) {
  return apiClient
    .post(`/order/complete/${orderRef}`)
    .then(response => (response && response.data ? response.data : false)) // eslint-disable-line
    .catch(error => console.log(error))
}

export async function addOrderItem(body) {
  return apiClient
    .post(`/order/item/create/${body.orderRef}`, body)
    .then(response => (response && response.data ? response.data : false)) // eslint-disable-line
    .catch(error => console.log(error))
}

export async function updateOrderItem(body) {
  return apiClient
    .post(`/order/item/update/${body.orderItemRef}`, body)
    .then(response => (response && response.data ? response.data : false)) // eslint-disable-line
    .catch(error => console.log(error))
}

export async function deleteOrderItem(body) {
  return apiClient
    .post(`/order/item/delete/${body.orderItemRef}`, body)
    .then(response => (response && response.data ? response.data : false)) // eslint-disable-line
    .catch(error => console.log(error))
}

export async function createComment(body) {
  return apiClient
    .post(`/order/comment/create`, body)
    .then(response => (response && response.data ? response.data : false)) // eslint-disable-line
    .catch(error => console.log(error.message))
}

export async function updateComment(body) {
  return apiClient
    .post(`/order/comment/update/${body.commentRef}`, body)
    .then(response => (response && response.data ? response.data : false)) // eslint-disable-line
    .catch(error => console.log(error))
}

export async function addCommentLike(commentRef) {
  return apiClient
    .post(`/order/comment/add-like/${commentRef}`)
    .then(response => (response && response.data ? response.data : false)) // eslint-disable-line
    .catch(error => console.log(error))
}

export async function removeCommentLike(commentRef) {
  return apiClient
    .post(`/order/comment/remove-like/${commentRef}`)
    .then(response => (response && response.data ? response.data : false)) // eslint-disable-line
    .catch(error => console.log(error))
}

export async function deleteComment(commentRef) {
  return apiClient
    .post(`/order/comment/delete/${commentRef}`)
    .then(response => (response && response.data ? response.data : false)) // eslint-disable-line
    .catch(error => console.log(error))
}

export async function addOrderPayment(body) {
  return apiClient
    .post(`/order/payment/create/${body.orderRef}`, body)
    .then(response => (response && response.data ? response.data : false)) // eslint-disable-line
    .catch(error => console.log(error))
}

export async function cancelOrderPayment(body) {
  return apiClient
    .post(`/order/payment/cancel/${body.paymentRef}`, body)
    .then(response => (response && response.data ? response.data : false)) // eslint-disable-line
    .catch(error => console.log(error))
}

export async function deleteOrder(body) {
  return apiClient
    .post(`/order/delete/${body.orderRef}`, body)
    .then(response => (response && response.data ? response.data : false)) // eslint-disable-line
    .catch(error => console.log(error))
}

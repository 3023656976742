import apiClient from '../axios'

export async function createAsset(body) {
  return apiClient
    .post(`/asset/create`, body)
    .then(response => (response && response.data ? response.data : false)) // eslint-disable-line
    .catch(error => console.log(error.message))
}

export async function getAssets({
  page,
  pages,
  searchText,
  limit,
  sortOrder,
  sortCol,
  filterSite,
  filterLocation,
  filterCategory,
  filterSubCategory,
  filterDepartment,
  filterVendor,
  filterStatus,
  filterAssignedTo,
  filterDateRangeType,
  filterDateRangeStart,
  filterDateRangeEnd,
  filterExpenditureDue,
}) {
  return apiClient
    .get(
      `/asset?page=${
        pages >= page ? page : 1
      }&search=${searchText.trim()}&limit=${limit}&sort_order=${sortOrder}&sort_col=${sortCol}&filter_site=${filterSite}&filter_location=${filterLocation}&filter_category=${filterCategory}&filter_subCategory=${filterSubCategory}&filter_department=${filterDepartment}&filter_vendor=${filterVendor}&filter_status=${filterStatus}&filter_assignedTo=${filterAssignedTo}&filter_dateRangeType=${filterDateRangeType}&filter_dateRangeStart=${filterDateRangeStart}&filter_dateRangeEnd=${filterDateRangeEnd}&filter_expenditureDue=${filterExpenditureDue}`,
    )
    .then(response => (response && response.data ? response.data : false))
    .catch(error => console.log(error))
}

export async function getAsset(id) {
  return apiClient
    .get(`/asset/${id}`)
    .then(response => (response && response.data ? response.data : false))
    .catch(error => console.log(error))
}

export async function updateAsset(body) {
  return apiClient
    .post(`/asset/update/${body.assetRef}`, body)
    .then(response => (response && response.data ? response.data : false)) // eslint-disable-line
    .catch(error => console.log(error))
}

export async function updateStatus(body) {
  return apiClient
    .post(`/asset/update-status/${body.assetRef}`, body)
    .then(response => (response && response.data ? response.data : false)) // eslint-disable-line
    .catch(error => console.log(error))
}

export async function uploadAssetImages(body) {
  return apiClient
    .post(`/asset/upload-images/${body.assetRef}`, body.data, {
      headers: body.headers,
    })
    .then(response => (response && response.data ? response.data : false)) // eslint-disable-line
    .catch(error => console.log(error))
}

export async function uploadAssetDocuments(body) {
  return apiClient
    .post(`/asset/upload-documents/${body.assetRef}`, body.data, {
      headers: body.headers,
    })
    .then(response => (response && response.data ? response.data : false)) // eslint-disable-line
    .catch(error => console.log(error))
}

export async function assignAssetToStaff(body) {
  return apiClient
    .post(`/asset/assign/${body.assetRef}`, body)
    .then(response => (response && response.data ? response.data : false)) // eslint-disable-line
    .catch(error => console.log(error))
}

export async function returnAssignedAsset(assetRef) {
  return apiClient
    .post(`/asset/return/${assetRef}`)
    .then(response => (response && response.data ? response.data : false)) // eslint-disable-line
    .catch(error => console.log(error))
}

export async function createAssetExpenditure(body) {
  return apiClient
    .post(`/asset/create-asset-expenditure/${body.assetRef}`, body)
    .then(response => (response && response.data ? response.data : false)) // eslint-disable-line
    .catch(error => console.log(error))
}

export async function deleteAssetExpenditure(assetExpenditureRef) {
  return apiClient
    .post(`/asset/delete-asset-expenditure/${assetExpenditureRef}`)
    .then(response => (response && response.data ? response.data : false)) // eslint-disable-line
    .catch(error => console.log(error))
}

export async function addExpenditurePayment(body) {
  return apiClient
    .post(`/asset/create-expenditure-payment/${body.expenditureRef}`, body)
    .then(response => (response && response.data ? response.data : false)) // eslint-disable-line
    .catch(error => console.log(error))
}

export async function completeAssetExpenditure(body) {
  return apiClient
    .post(`/asset/complete-asset-expenditure/${body.assetExpenditureRef}`, body)
    .then(response => (response && response.data ? response.data : false)) // eslint-disable-line
    .catch(error => console.log(error))
}

export async function cancelAssetExpenditure(body) {
  return apiClient
    .post(`/asset/cancel-asset-expenditure/${body.assetExpenditureRef}`, body)
    .then(response => (response && response.data ? response.data : false)) // eslint-disable-line
    .catch(error => console.log(error))
}

export async function deleteAsset(assetRef) {
  return apiClient
    .post(`/asset/delete/${assetRef}`)
    .then(response => (response && response.data ? response.data : false)) // eslint-disable-line
    .catch(error => console.log(error))
}

import React, { lazy, Suspense } from 'react'
import { Route, Redirect, Switch } from 'react-router-dom'
import { ConnectedRouter } from 'connected-react-router'
import { CSSTransition, SwitchTransition } from 'react-transition-group'
import { connect } from 'react-redux'

import Layout from 'layouts'
import ErrorBoundary from 'components/cleanui/system/ErrorBoundary'

const routes = [
  {
    path: '/profile',
    key: '/profile',
    Component: lazy(() => import('pages/profile')),
    exact: true,
  },

  {
    path: '/dashboard',
    key: '/dashboard',
    Component: lazy(() => import('pages/dashboard')),
    exact: true,
  },

  // Meeting
  {
    path: '/meetings',
    key: '/meetings',
    Component: lazy(() => import('pages/meeting')),
    exact: true,
  },
  {
    path: '/meetings/:id',
    key: '/meetings',
    Component: lazy(() => import('pages/meeting')),
    exact: true,
  },

  // Asset Pages
  {
    path: '/asset',
    key: '/asset',
    Component: lazy(() => import('pages/asset/list')),
    exact: true,
  },
  {
    path: '/asset/create',
    key: '/asset/create',
    Component: lazy(() => import('pages/asset/create')),
    exact: true,
  },
  {
    path: '/asset/:assetRef',
    key: '/asset/:assetRef',
    Component: lazy(() => import('pages/asset/single')),
    exact: true,
  },
  {
    path: '/asset/update/:assetRef',
    key: '/asset/update/:assetRef',
    Component: lazy(() => import('pages/asset/update')),
    exact: true,
  },

  // Order Pages
  {
    path: '/purchase-order',
    key: '/purchase-order',
    Component: lazy(() => import('pages/purchase-order/list')),
    exact: true,
  },
  {
    path: '/purchase-order/create',
    key: '/purchase-order/create',
    Component: lazy(() => import('pages/purchase-order/create')),
    exact: true,
  },
  {
    path: '/purchase-order/:orderRef',
    key: '/purchase-order/:orderRef',
    Component: lazy(() => import('pages/purchase-order/single')),
    exact: true,
  },
  {
    path: '/purchase-order/update/:orderRef',
    key: '/purchase-order/update/:orderRef',
    Component: lazy(() => import('pages/purchase-order/update')),
    exact: true,
  },

  // OrderRequest Pages
  {
    path: '/purchase-requisition',
    key: '/purchase-requisition',
    Component: lazy(() => import('pages/purchase-requisition/list')),
    exact: true,
  },
  {
    path: '/purchase-requisition/create',
    key: '/purchase-requisition/create',
    Component: lazy(() => import('pages/purchase-requisition/create')),
    exact: true,
  },
  {
    path: '/purchase-requisition/:orderRequestRef',
    key: '/purchase-requisition/:orderRequestRef',
    Component: lazy(() => import('pages/purchase-requisition/single')),
    exact: true,
  },
  {
    path: '/purchase-requisition/update/:orderRequestRef',
    key: '/purchase-requisition/update/:orderRequestRef',
    Component: lazy(() => import('pages/purchase-requisition/update')),
    exact: true,
  },

  // Workflow Pages
  {
    path: '/workflow',
    key: '/workflow',
    Component: lazy(() => import('pages/workflow/list')),
    exact: true,
  },
  /* {
    path: '/workflow/create',
    key: '/workflow/create',
    Component: lazy(() => import('pages/workflow/create')),
    exact: true,
  }, */
  {
    path: '/workflow/:workflowRef',
    key: '/workflow/:workflowRef',
    Component: lazy(() => import('pages/workflow/single')),
    exact: true,
  },
  /* {
    path: '/workflow/update/:workflowRef',
    key: '/workflow/update/:workflowRef',
    Component: lazy(() => import('pages/workflow/update')),
    exact: true,
  }, */

  // Role Pages
  {
    path: '/role',
    key: '/role',
    Component: lazy(() => import('pages/role/list')),
    exact: true,
  },
  /* {
    path: '/role/create',
    key: '/role/create',
    Component: lazy(() => import('pages/role/create')),
    exact: true,
  }, */
  {
    path: '/role/:roleRef',
    key: '/role/:roleRef',
    Component: lazy(() => import('pages/role/single')),
    exact: true,
  },
  /* {
    path: '/role/update/:roleRef',
    key: '/role/update/:roleRef',
    Component: lazy(() => import('pages/role/update')),
    exact: true,
  }, */

  // Staff Pages
  {
    path: '/staff',
    key: '/staff',
    Component: lazy(() => import('pages/staff/list')),
    exact: true,
  },
  /* {
    path: '/staff/create',
    key: '/staff/create',
    Component: lazy(() => import('pages/staff/create')),
    exact: true,
  }, */
  /* {
    path: '/staff/:staffRef',
    key: '/staff/:staffRef',
    Component: lazy(() => import('pages/staff/single')),
    exact: true,
  }, */
  /* {
    path: '/staff/update/:staffRef',
    key: '/staff/update/:staffRef',
    Component: lazy(() => import('pages/staff/update')),
    exact: true,
  }, */

  // Agent Pages
  {
    path: '/agent',
    key: '/agent',
    Component: lazy(() => import('pages/agent/list')),
    exact: true,
  },

  // Site Pages
  {
    path: '/site',
    key: '/site',
    Component: lazy(() => import('pages/site/list')),
    exact: true,
  },

  // Location Pages
  {
    path: '/location',
    key: '/location',
    Component: lazy(() => import('pages/location/list')),
    exact: true,
  },

  // Vendor Pages
  {
    path: '/vendor',
    key: '/vendor',
    Component: lazy(() => import('pages/vendor/list')),
    exact: true,
  },

  // Contractor Pages
  {
    path: '/contractor',
    key: '/contractor',
    Component: lazy(() => import('pages/contractor/list')),
    exact: true,
  },

  // Consultant Pages
  {
    path: '/consultant',
    key: '/consultant',
    Component: lazy(() => import('pages/consultant/list')),
    exact: true,
  },

  // Department Pages
  {
    path: '/department',
    key: '/department',
    Component: lazy(() => import('pages/department/list')),
    exact: true,
  },

  // Category Pages
  {
    path: '/category',
    key: '/category',
    Component: lazy(() => import('pages/category/list')),
    exact: true,
  },

  // Block Pages
  {
    path: '/block',
    key: '/block',
    Component: lazy(() => import('pages/block/list')),
    exact: true,
  },

  // Property Pages
  {
    path: '/property',
    key: '/property',
    Component: lazy(() => import('pages/property/list')),
    exact: true,
  },
  {
    path: '/property/create',
    key: '/property/create',
    Component: lazy(() => import('pages/property/create')),
    exact: true,
  },
  {
    path: '/property/update/:propertyRef',
    key: '/property/update/:propertyRef',
    Component: lazy(() => import('pages/property/update')),
    exact: true,
  },
  {
    path: '/property/:propertyRef',
    key: '/property/:propertyRef',
    Component: lazy(() => import('pages/property/single')),
    exact: true,
  },

  // Project Pages
  {
    path: '/project',
    key: '/project',
    Component: lazy(() => import('pages/project/list')),
    exact: true,
  },

  // Lead Pages
  {
    path: '/lead',
    key: '/lead',
    Component: lazy(() => import('pages/lead/list')),
    exact: true,
  },
  {
    path: '/lead/create',
    key: '/lead/create',
    Component: lazy(() => import('pages/lead/create')),
    exact: true,
  },
  {
    path: '/lead/update/:leadRef',
    key: '/lead/update/:leadRef',
    Component: lazy(() => import('pages/lead/update')),
    exact: true,
  },
  {
    path: '/lead/:leadRef',
    key: '/lead/:leadRef',
    Component: lazy(() => import('pages/lead/single')),
    exact: true,
  },

  // Client Pages
  {
    path: '/client',
    key: '/client',
    Component: lazy(() => import('pages/client/list')),
    exact: true,
  },
  {
    path: '/client/create',
    key: '/client/create',
    Component: lazy(() => import('pages/client/create')),
    exact: true,
  },
  {
    path: '/client/:clientRef',
    key: '/client/:clientRef',
    Component: lazy(() => import('pages/client/single')),
    exact: true,
  },
  {
    path: '/client/update/:clientRef',
    key: '/client/update/:clientRef',
    Component: lazy(() => import('pages/client/update')),
    exact: true,
  },

  // Plan Pages
  {
    path: '/plan',
    key: '/plan',
    Component: lazy(() => import('pages/plan/list')),
    exact: true,
  },

  // Sale Pages
  {
    path: '/sale',
    key: '/sale',
    Component: lazy(() => import('pages/sale/list')),
    exact: true,
  },
  {
    path: '/sale/create',
    key: '/sale/create',
    Component: lazy(() => import('pages/sale/create')),
    exact: true,
  },
  {
    path: '/sale/:saleRef',
    key: '/sale/:saleRef',
    Component: lazy(() => import('pages/sale/single')),
    exact: true,
  },

  // ExpenseRequest Pages
  {
    path: '/expense-request',
    key: '/expense-request',
    Component: lazy(() => import('pages/expense-request/list')),
    exact: true,
  },
  {
    path: '/expense-request/create',
    key: '/expense-request/create',
    Component: lazy(() => import('pages/expense-request/create')),
    exact: true,
  },
  {
    path: '/expense-request/:expenseRequestRef',
    key: '/expense-request/:expenseRequestRef',
    Component: lazy(() => import('pages/expense-request/single')),
    exact: true,
  },
  {
    path: '/expense-request/update/:expenseRequestRef',
    key: '/expense-request/update/:expenseRequestRef',
    Component: lazy(() => import('pages/expense-request/update')),
    exact: true,
  },

  // Expenditure Pages
  {
    path: '/expenditure',
    key: '/expenditure',
    Component: lazy(() => import('pages/expenditure/list')),
    exact: true,
  },
  {
    path: '/expenditure/create',
    key: '/expenditure/create',
    Component: lazy(() => import('pages/expenditure/create')),
    exact: true,
  },
  {
    path: '/expenditure/:expenditureRef',
    key: '/expenditure/:expenditureRef',
    Component: lazy(() => import('pages/expenditure/single')),
    exact: true,
  },

  // Task Pages
  {
    path: '/task',
    key: '/task',
    Component: lazy(() => import('pages/task/list')),
    exact: true,
  },
  {
    path: '/task/:taskRef',
    key: '/task/:taskRef',
    Component: lazy(() => import('pages/task/single')),
    exact: true,
  },

  // Payment Pages
  {
    path: '/payment',
    key: '/payment',
    Component: lazy(() => import('pages/payment/list')),
    exact: true,
  },

  // Auth Pages
  {
    path: '/auth/login',
    key: '/auth/login',
    Component: lazy(() => import('pages/auth/login')),
    exact: true,
  },
  /* {
    path: '/auth/register',
    Component: lazy(() => import('pages/auth/register')),
    exact: true,
  }, */
  {
    path: '/auth/forgot-password',
    key: '/auth/forgot-password',
    Component: lazy(() => import('pages/auth/forgot-password')),
    exact: true,
  },
  {
    path: '/auth/reset-password',
    key: '/auth/reset-password',
    Component: lazy(() => import('pages/auth/reset-password')),
    exact: true,
  },
  {
    path: '/auth/activate-account',
    key: '/auth/activate-account',
    Component: lazy(() => import('pages/auth/activate-account')),
    exact: true,
  },
  {
    path: '/auth/activate-staff-account',
    key: '/auth/activate-staff-account',
    Component: lazy(() => import('pages/auth/activate-staff-account')),
    exact: true,
  },
  {
    path: '/auth/404',
    key: '/auth/404',
    Component: lazy(() => import('pages/auth/404')),
    exact: true,
  },
  {
    path: '/auth/500',
    key: '/auth/500',
    Component: lazy(() => import('pages/auth/500')),
    exact: true,
  },
]

/* const routes = [
  // Dashboards
  {
    path: '/dashboard/alpha',
    Component: lazy(() => import('pages/dashboard/alpha')),
    exact: true,
  },
  {
    path: '/dashboard/beta',
    Component: lazy(() => import('pages/dashboard/beta')),
    exact: true,
  },
  {
    path: '/dashboard/gamma',
    Component: lazy(() => import('pages/dashboard/gamma')),
    exact: true,
  },
  {
    path: '/dashboard/crypto',
    Component: lazy(() => import('pages/dashboard/crypto')),
    exact: true,
  },

  // Ecommerce
  {
    path: '/ecommerce/dashboard',
    Component: lazy(() => import('pages/ecommerce/dashboard')),
    exact: true,
  },
  {
    path: '/ecommerce/orders',
    Component: lazy(() => import('pages/ecommerce/orders')),
    exact: true,
  },
  {
    path: '/ecommerce/product-catalog',
    Component: lazy(() => import('pages/ecommerce/product-catalog')),
    exact: true,
  },
  {
    path: '/ecommerce/product-details',
    Component: lazy(() => import('pages/ecommerce/product-details')),
    exact: true,
  },
  {
    path: '/ecommerce/cart',
    Component: lazy(() => import('pages/ecommerce/cart')),
    exact: true,
  },

  // Apps
  {
    path: '/apps/messaging',
    Component: lazy(() => import('pages/apps/messaging')),
    exact: true,
  },
  {
    path: '/apps/calendar',
    Component: lazy(() => import('pages/apps/calendar')),
    exact: true,
  },
  {
    path: '/apps/mail',
    Component: lazy(() => import('pages/apps/mail')),
    exact: true,
  },
  {
    path: '/apps/profile',
    Component: lazy(() => import('pages/apps/profile')),
    exact: true,
  },
  {
    path: '/apps/gallery',
    Component: lazy(() => import('pages/apps/gallery')),
    exact: true,
  },
  // Extra Apps
  {
    path: '/apps/github-explore',
    Component: lazy(() => import('pages/apps/github-explore')),
    exact: true,
  },
  {
    path: '/apps/github-discuss',
    Component: lazy(() => import('pages/apps/github-discuss')),
    exact: true,
  },
  {
    path: '/apps/digitalocean-droplets',
    Component: lazy(() => import('pages/apps/digitalocean-droplets')),
    exact: true,
  },
  {
    path: '/apps/digitalocean-create',
    Component: lazy(() => import('pages/apps/digitalocean-create')),
    exact: true,
  },
  {
    path: '/apps/google-analytics',
    Component: lazy(() => import('pages/apps/google-analytics')),
    exact: true,
  },
  {
    path: '/apps/wordpress-post',
    Component: lazy(() => import('pages/apps/wordpress-post')),
    exact: true,
  },
  {
    path: '/apps/wordpress-posts',
    Component: lazy(() => import('pages/apps/wordpress-posts')),
    exact: true,
  },
  {
    path: '/apps/wordpress-add',
    Component: lazy(() => import('pages/apps/wordpress-add')),
    exact: true,
  },
  {
    path: '/apps/todoist-list',
    Component: lazy(() => import('pages/apps/todoist-list')),
    exact: true,
  },
  {
    path: '/apps/jira-dashboard',
    Component: lazy(() => import('pages/apps/jira-dashboard')),
    exact: true,
  },
  {
    path: '/apps/jira-agile-board',
    Component: lazy(() => import('pages/apps/jira-agile-board')),
    exact: true,
  },
  {
    path: '/apps/helpdesk-dashboard',
    Component: lazy(() => import('pages/apps/helpdesk-dashboard')),
    exact: true,
  },
  // Widgets
  {
    path: '/widgets/general',
    Component: lazy(() => import('pages/widgets/general')),
    exact: true,
  },
  {
    path: '/widgets/lists',
    Component: lazy(() => import('pages/widgets/lists')),
    exact: true,
  },
  {
    path: '/widgets/tables',
    Component: lazy(() => import('pages/widgets/tables')),
    exact: true,
  },
  {
    path: '/widgets/charts',
    Component: lazy(() => import('pages/widgets/charts')),
    exact: true,
  },
  // Cards
  {
    path: '/cards/basic-cards',
    Component: lazy(() => import('pages/cards/basic-cards')),
    exact: true,
  },
  {
    path: '/cards/tabbed-cards',
    Component: lazy(() => import('pages/cards/tabbed-cards')),
    exact: true,
  },
  // UI Kits
  {
    path: '/ui-kits/bootstrap',
    Component: lazy(() => import('pages/ui-kits/bootstrap')),
    exact: true,
  },
  {
    path: '/ui-kits/antd',
    Component: lazy(() => import('pages/ui-kits/antd')),
    exact: true,
  },
  // Tables
  {
    path: '/tables/bootstrap',
    Component: lazy(() => import('pages/tables/bootstrap')),
    exact: true,
  },
  {
    path: '/tables/antd',
    Component: lazy(() => import('pages/tables/antd')),
    exact: true,
  },
  // Charts
  {
    path: '/charts/chartistjs',
    Component: lazy(() => import('pages/charts/chartistjs')),
    exact: true,
  },
  {
    path: '/charts/chartjs',
    Component: lazy(() => import('pages/charts/chartjs')),
    exact: true,
  },
  {
    path: '/charts/c3',
    Component: lazy(() => import('pages/charts/c3')),
    exact: true,
  },
  // Icons
  {
    path: '/icons/feather-icons',
    Component: lazy(() => import('pages/icons/feather-icons')),
    exact: true,
  },
  {
    path: '/icons/fontawesome',
    Component: lazy(() => import('pages/icons/fontawesome')),
    exact: true,
  },
  {
    path: '/icons/linearicons-free',
    Component: lazy(() => import('pages/icons/linearicons-free')),
    exact: true,
  },
  {
    path: '/icons/icomoon-free',
    Component: lazy(() => import('pages/icons/icomoon-free')),
    exact: true,
  },
  // Advanced
  {
    path: '/advanced/form-examples',
    Component: lazy(() => import('pages/advanced/form-examples')),
    exact: true,
  },
  {
    path: '/advanced/email-templates',
    Component: lazy(() => import('pages/advanced/email-templates')),
    exact: true,
  },
  {
    path: '/advanced/utilities',
    Component: lazy(() => import('pages/advanced/utilities')),
    exact: true,
  },
  {
    path: '/advanced/grid',
    Component: lazy(() => import('pages/advanced/grid')),
    exact: true,
  },
  {
    path: '/advanced/typography',
    Component: lazy(() => import('pages/advanced/typography')),
    exact: true,
  },
  {
    path: '/advanced/pricing-tables',
    Component: lazy(() => import('pages/advanced/pricing-tables')),
    exact: true,
  },
  {
    path: '/advanced/invoice',
    Component: lazy(() => import('pages/advanced/invoice')),
    exact: true,
  },
  {
    path: '/advanced/colors',
    Component: lazy(() => import('pages/advanced/colors')),
    exact: true,
  },
  // Auth Pages
  {
    path: '/auth/login',
    Component: lazy(() => import('pages/auth/login')),
    exact: true,
  },
  {
    path: '/auth/forgot-password',
    Component: lazy(() => import('pages/auth/forgot-password')),
    exact: true,
  },
  {
    path: '/auth/register',
    Component: lazy(() => import('pages/auth/register')),
    exact: true,
  },
  {
    path: '/auth/lockscreen',
    Component: lazy(() => import('pages/auth/lockscreen')),
    exact: true,
  },
  {
    path: '/auth/404',
    Component: lazy(() => import('pages/auth/404')),
    exact: true,
  },
  {
    path: '/auth/500',
    Component: lazy(() => import('pages/auth/500')),
    exact: true,
  },
] */

const mapStateToProps = ({ settings }) => ({
  routerAnimation: settings.routerAnimation,
})

const Router = ({ history, routerAnimation }) => {
  return (
    <ConnectedRouter history={history}>
      <Layout>
        <Route
          render={state => {
            const { location } = state
            // console.log(location.pathname)
            return (
              <SwitchTransition>
                <CSSTransition
                  key={
                    location.pathname.split('/')[1] === 'meetings' ? '/meetings' : location.pathname
                  }
                  appear
                  classNames={routerAnimation}
                  timeout={routerAnimation === 'none' ? 0 : 300}
                >
                  <Switch location={location}>
                    <Route exact path="/" render={() => <Redirect to="/dashboard" />} />
                    {routes.map(({ path, key, Component, exact }) => (
                      <Route
                        path={path}
                        key={key}
                        exact={exact}
                        render={() => {
                          return (
                            <div style={{ height: '100%' }} className={routerAnimation}>
                              <Suspense fallback={null}>
                                <ErrorBoundary>
                                  <Component />
                                </ErrorBoundary>
                              </Suspense>
                            </div>
                          )
                        }}
                      />
                    ))}
                    <Redirect to="/auth/404" />
                  </Switch>
                </CSSTransition>
              </SwitchTransition>
            )
          }}
        />
      </Layout>
    </ConnectedRouter>
  )
}

export default connect(mapStateToProps)(Router)

const actions = {
  CREATE: 'property/CREATE',
  UPDATE: 'property/UPDATE',
  UPDATE_BULK: 'property/UPDATE_BULK',
  DELETE: 'property/DELETE',
  SET_STATE: 'property/SET_STATE',
  SET_DOCS: 'property/SET_DOCS',
  SET_DOC: 'property/SET_DOC',
  SET_CURRENT_PAGE: 'property/SET_CURRENT_PAGE',
  SET_LIMIT: 'property/SET_LIMIT',
  SET_SORTING: 'property/SET_SORTING',
  SET_SEARCH_TEXT: 'property/SET_SEARCH_TEXT',
  SET_FILTERS: 'property/SET_FILTERS',
}

export default actions

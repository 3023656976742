const actions = {
  CREATE: 'order/CREATE',
  CREATE_WITH_REQUEST: 'order/CREATE_WITH_REQUEST',
  UPDATE: 'order/UPDATE',
  SET_FILTERS: 'order/SET_FILTERS',
  DELETE: 'order/DELETE',
  CREATE_ITEM: 'order/CREATE_ITEM',
  UPDATE_ITEM: 'order/UPDATE_ITEM',
  DELETE_ITEM: 'order/DELETE_ITEM',
  CREATE_COMMENT: 'order/CREATE_COMMENT',
  UPDATE_COMMENT: 'order/UPDATE_COMMENT',
  LIKE_COMMENT: 'order/LIKE_COMMENT',
  UNLIKE_COMMENT: 'order/UNLIKE_COMMENT',
  DELETE_COMMENT: 'order/DELETE_COMMENT',
  SUBMIT: 'order/SUBMIT',
  OPEN: 'order/OPEN',
  REVISE: 'order/REVISE',
  APPROVE: 'order/APPROVE',
  REJECT: 'order/REJECT',
  CREATE_PAYMENT: 'order/CREATE_PAYMENT',
  CANCEL_PAYMENT: 'order/CANCEL_PAYMENT',
  COMPLETE: 'order/COMPLETE',
  SET_STATE: 'order/SET_STATE',
  SET_DOCS: 'order/SET_DOCS',
  SET_DOC: 'order/SET_DOC',
  SET_CURRENT_PAGE: 'order/SET_CURRENT_PAGE',
  SET_LIMIT: 'order/SET_LIMIT',
  SET_SORTING: 'order/SET_SORTING',
  SET_SEARCH_TEXT: 'order/SET_SEARCH_TEXT',
}

export default actions

// import store from 'store'
import apiClient from '../axios'

export async function createAgent(body) {
  return apiClient
    .post(`/agent/create`, body)
    .then(response => (response && response.data ? response.data : false)) // eslint-disable-line
    .catch(error => console.log(error.message))
}

export async function getAgents({ page, pages, searchText, limit, sortOrder, sortCol }) {
  return apiClient
    .get(
      `/agent?page=${
        pages >= page ? page : 1
      }&search=${searchText.trim()}&limit=${limit}&sort_order=${sortOrder}&sort_col=${sortCol}`,
    )
    .then(response => (response && response.data ? response.data : false))
    .catch(error => console.log(error))
}

export async function updateAgent(body) {
  return apiClient
    .post(`/agent/update/${body.agentRef}`, body)
    .then(response => (response && response.data ? response.data : false)) // eslint-disable-line
    .catch(error => console.log(error))
}

export async function deleteAgent(agentRef) {
  return apiClient
    .post(`/agent/delete/${agentRef}`)
    .then(response => (response && response.data ? response.data : false)) // eslint-disable-line
    .catch(error => console.log(error))
}

import apiClient from '../axios'

export async function createExpenditure(body) {
  return apiClient
    .post(`/expenditure/create`, body)
    .then(response => (response && response.data ? response.data : false)) // eslint-disable-line
    .catch(error => console.log(error.message))
}

export async function getExpenditures({
  page,
  pages,
  searchText,
  limit,
  sortOrder,
  sortCol,
  filterOwner,
  filterOwnerModel,
  filterProject,
  filterBlock,
  filterCategory,
  filterSubCategory,
  filterStatus,
  filterInstallmentDue,
}) {
  return apiClient
    .get(
      `/expenditure?page=${
        pages >= page ? page : 1
      }&search=${searchText.trim()}&limit=${limit}&sort_order=${sortOrder}&sort_col=${sortCol}&filter_owner=${filterOwner}&filter_ownerModel=${filterOwnerModel}&filter_project=${filterProject}&filter_block=${filterBlock}&filter_category=${filterCategory}&filter_subCategory=${filterSubCategory}&filter_status=${filterStatus}&filter_installmentDue=${filterInstallmentDue}`,
    )
    .then(response => (response && response.data ? response.data : false))
    .catch(error => console.log(error))
}

export async function getExpendituresPaymentReport({ filterExpenditures }) {
  return apiClient
    .get(`/expenditure/payment-report?filter_expenditures=${filterExpenditures}`, {
      responseType: 'blob',
    })
    .then(({ data }) => {
      const blob = new Blob([data], { type: data.type })
      const url = window.URL.createObjectURL(blob)
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', 'payments.pdf')
      document.body.appendChild(link)
      link.click()
      link.remove()
      window.URL.revokeObjectURL(url)

      return { status: 'success' }
    })
    .catch(error => console.log(error))
}

export async function getExpenditure(expenditureRef) {
  return apiClient
    .get(`/expenditure/${expenditureRef}`)
    .then(response => (response && response.data ? response.data : false))
    .catch(error => console.log(error))
}

export async function updateExpenditure(body) {
  return apiClient
    .post(`/expenditure/update/${body.expenditureRef}`, body)
    .then(response => (response && response.data ? response.data : false)) // eslint-disable-line
    .catch(error => console.log(error))
}

export async function deleteExpenditure(expenditureRef) {
  return apiClient
    .post(`/expenditure/delete/${expenditureRef}`)
    .then(response => (response && response.data ? response.data : false)) // eslint-disable-line
    .catch(error => console.log(error))
}

export async function addExpenditurePayment(body) {
  return apiClient
    .post(`/expenditure/create/payment/${body.installmentRef}`, body)
    .then(response => (response && response.data ? response.data : false)) // eslint-disable-line
    .catch(error => console.log(error))
}

export async function completeExpenditure(expenditureRef) {
  return apiClient
    .post(`/expenditure/complete/${expenditureRef}`)
    .then(response => (response && response.data ? response.data : false)) // eslint-disable-line
    .catch(error => console.log(error))
}

export async function cancelExpenditure(expenditureRef) {
  return apiClient
    .post(`/expenditure/cancel/${expenditureRef}`)
    .then(response => (response && response.data ? response.data : false)) // eslint-disable-line
    .catch(error => console.log(error))
}

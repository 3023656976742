const actions = {
  CREATE: 'consultant/CREATE',
  UPDATE: 'consultant/UPDATE',
  DELETE: 'consultant/DELETE',
  SET_STATE: 'consultant/SET_STATE',
  SET_DOCS: 'consultant/SET_DOCS',
  SET_DOC: 'consultant/SET_DOC',
  SET_CURRENT_PAGE: 'consultant/SET_CURRENT_PAGE',
  SET_LIMIT: 'consultant/SET_LIMIT',
  SET_SORTING: 'consultant/SET_SORTING',
  SET_SEARCH_TEXT: 'consultant/SET_SEARCH_TEXT',
}

export default actions

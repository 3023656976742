import { all, takeEvery, put, call, select, debounce } from 'redux-saga/effects'
import { notification } from 'antd'
import {
  getStaffs,
  assignRole,
  createStaff,
  updateStaff,
  deleteStaff,
  creditBalance,
  debitBalance,
} from 'services/api/staff'
import actions from './actions'

export function* SET_DOCS() {
  yield put({
    type: 'staff/SET_STATE',
    payload: {
      loadingDocs: true,
    },
  })

  const {
    page,
    pages,
    searchText,
    limit,
    sortOrder,
    sortCol,
    // overwriteDocs,
    // docs: initialDocs,
  } = yield select(state => state.staff)

  const { docs, totalDocs, totalPages } = yield call(getStaffs, {
    page,
    pages,
    searchText,
    limit,
    sortOrder,
    sortCol,
  })

  yield put({
    type: 'staff/SET_STATE',
    payload: {
      docs,
      total: totalDocs,
      pages: totalPages,
    },
  })

  yield put({
    type: 'staff/SET_STATE',
    payload: {
      loadingDocs: false,
    },
  })
}

export function* SET_CURRENT_PAGE({ payload }) {
  const { page } = payload

  yield put({
    type: 'staff/SET_STATE',
    payload: {
      page,
    },
  })

  yield call(SET_DOCS)
}

export function* SET_LIMIT({ payload }) {
  const { limit } = payload

  yield put({
    type: 'staff/SET_STATE',
    payload: {
      limit,
    },
  })

  yield call(SET_DOCS)
}

export function* SET_SORTING({ payload }) {
  const { sortOrder, sortCol } = payload

  yield put({
    type: 'staff/SET_STATE',
    payload: {
      sortOrder,
      sortCol,
    },
  })

  yield call(SET_DOCS)
}

export function* SET_SEARCH_TEXT({ payload }) {
  const { searchText } = payload

  yield put({
    type: 'staff/SET_STATE',
    payload: {
      searchText,
      page: 1,
    },
  })
}

export function* THROTTLE_SET_SEARCH_TEXT() {
  yield call(SET_DOCS)
}

export function* CREATE({ payload }) {
  const { resetForm } = payload

  yield put({
    type: 'staff/SET_STATE',
    payload: {
      loadingAction: true,
    },
  })

  const success = yield call(createStaff, payload)
  yield call(SET_DOCS)

  if (success) {
    yield call(SET_DOCS)
    notification.success({
      message: 'Success',
      description: 'Staff Created!',
    })

    if (resetForm) resetForm()
  }

  yield put({
    type: 'staff/SET_STATE',
    payload: {
      loadingAction: false,
    },
  })
}

export function* UPDATE({ payload }) {
  const { resetForm, resetStates, dontShowNotification, dontFetchDoc } = payload

  yield put({
    type: 'staff/SET_STATE',
    payload: {
      loadingAction: true,
    },
  })

  const success = yield call(updateStaff, payload)

  if (success) {
    if (!dontFetchDoc) {
      yield call(SET_DOCS, {})
    }
    if (!dontShowNotification) {
      notification.success({
        message: 'Success',
        description: 'Staff has been updated',
      })
    }

    if (resetForm) resetForm()
    if (resetStates) resetStates()
  }

  yield put({
    type: 'staff/SET_STATE',
    payload: {
      loadingAction: false,
    },
  })
}

export function* CREDIT_BALANCE({ payload }) {
  const { resetForm, resetStates, dontShowNotification, dontFetchDoc } = payload

  yield put({
    type: 'staff/SET_STATE',
    payload: {
      loadingAction: true,
    },
  })

  const success = yield call(creditBalance, payload)

  if (success) {
    if (!dontFetchDoc) {
      yield call(SET_DOCS, {})
    }
    if (!dontShowNotification) {
      notification.success({
        message: 'Success',
        description: 'balance has been credited',
      })
    }

    if (resetForm) resetForm()
    if (resetStates) resetStates()
  }

  yield put({
    type: 'staff/SET_STATE',
    payload: {
      loadingAction: false,
    },
  })
}

export function* DEBIT_BALANCE({ payload }) {
  const { resetForm, resetStates, dontShowNotification, dontFetchDoc } = payload

  yield put({
    type: 'staff/SET_STATE',
    payload: {
      loadingAction: true,
    },
  })

  const success = yield call(debitBalance, payload)

  if (success) {
    if (!dontFetchDoc) {
      yield call(SET_DOCS, {})
    }
    if (!dontShowNotification) {
      notification.success({
        message: 'Success',
        description: 'balance has been debited',
      })
    }

    if (resetForm) resetForm()
    if (resetStates) resetStates()
  }

  yield put({
    type: 'staff/SET_STATE',
    payload: {
      loadingAction: false,
    },
  })
}

export function* DELETE({ payload }) {
  const { staffRef } = payload

  yield put({
    type: 'staff/SET_STATE',
    payload: {
      loadingAction: true,
    },
  })

  const success = yield call(deleteStaff, staffRef)

  if (success) {
    yield call(SET_DOCS, {})
    notification.success({
      message: 'Success',
      description: 'Staff has been deleted',
    })
  }

  yield put({
    type: 'staff/SET_STATE',
    payload: {
      loadingAction: false,
    },
  })
}

export function* ASSIGN_ROLE({ payload }) {
  const { resetForm } = payload

  yield put({
    type: 'staff/SET_STATE',
    payload: {
      loadingAction: true,
    },
  })

  const success = yield call(assignRole, payload)
  if (success) {
    yield call(SET_DOCS)
    notification.success({
      message: 'Success',
      description: 'Role assigned to staff!',
    })

    if (resetForm) resetForm()
  }

  yield put({
    type: 'staff/SET_STATE',
    payload: {
      loadingAction: false,
    },
  })
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.SET_DOCS, SET_DOCS),
    takeEvery(actions.SET_CURRENT_PAGE, SET_CURRENT_PAGE),
    takeEvery(actions.SET_LIMIT, SET_LIMIT),
    takeEvery(actions.SET_SORTING, SET_SORTING),
    takeEvery(actions.SET_SEARCH_TEXT, SET_SEARCH_TEXT),
    debounce(500, actions.SET_SEARCH_TEXT, THROTTLE_SET_SEARCH_TEXT),
    takeEvery(actions.ASSIGN_ROLE, ASSIGN_ROLE),
    takeEvery(actions.CREATE, CREATE),
    takeEvery(actions.UPDATE, UPDATE),
    takeEvery(actions.CREDIT_BALANCE, CREDIT_BALANCE),
    takeEvery(actions.DEBIT_BALANCE, DEBIT_BALANCE),
    takeEvery(actions.DELETE, DELETE),
  ])
}

const actions = {
  CREATE: 'asset/CREATE',
  UPDATE: 'asset/UPDATE',
  UPDATE_STATUS: 'asset/UPDATE_STATUS',
  DELETE: 'asset/DELETE',
  ASSIGN: 'asset/ASSIGN',
  RETURN: 'asset/RETURN',
  UPLOAD_IMAGES: 'asset/UPLOAD_IMAGES',
  UPLOAD_DOCUMENTS: 'asset/UPLOAD_DOCUMENTS',
  CREATE_ASSET_EXPENDITURE: 'asset/CREATE_ASSET_EXPENDITURE',
  DELETE_ASSET_EXPENDITURE: 'asset/DELETE_ASSET_EXPENDITURE',
  CREATE_EXPENDITURE_PAYMENT: 'asset/CREATE_EXPENDITURE_PAYMENT',
  COMPLETE_ASSET_EXPENDITURE: 'asset/COMPLETE_ASSET_EXPENDITURE',
  CANCEL_ASSET_EXPENDITURE: 'asset/CANCEL_ASSET_EXPENDITURE',
  SET_STATE: 'asset/SET_STATE',
  SET_DOCS: 'asset/SET_DOCS',
  SET_DOC: 'asset/SET_DOC',
  SET_CURRENT_PAGE: 'asset/SET_CURRENT_PAGE',
  SET_LIMIT: 'asset/SET_LIMIT',
  SET_SORTING: 'asset/SET_SORTING',
  SET_SEARCH_TEXT: 'asset/SET_SEARCH_TEXT',
  SET_FILTERS: 'asset/SET_FILTERS',
}

export default actions

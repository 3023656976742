import { all, put, call, takeEvery, select, debounce } from 'redux-saga/effects'
import { history } from 'index'
import {
  createWorkflow,
  updateWorkflow,
  addWorkflowStep,
  assignApproverToWorkflowStep,
  removeWorkflowStep,
  getWorkflows,
  getWorkflow,
  deleteWorkflow,
} from 'services/api/workflow'
import { notification } from 'antd'
import actions from './actions'

export function* CREATE({ payload }) {
  const { name, type, resetForm, resetStates } = payload

  yield put({
    type: 'workflow/SET_STATE',
    payload: {
      loadingAction: true,
    },
  })

  const success = yield call(createWorkflow, {
    name,
    type,
  })

  if (success) {
    yield call(SET_DOCS)
    notification.success({
      message: 'Success',
      description: 'New workflow added',
    })

    if (resetForm) resetForm()
    if (resetStates) resetStates()
  }

  yield put({
    type: 'workflow/SET_STATE',
    payload: {
      loadingAction: false,
    },
  })
}

export function* SET_DOCS() {
  yield put({
    type: 'workflow/SET_STATE',
    payload: {
      loadingDocs: true,
    },
  })

  // Get queries
  const { limit, searchText, page, pages, sortOrder, sortCol, filterType } = yield select(
    state => state.workflow,
  )

  const success = yield call(getWorkflows, {
    limit,
    searchText,
    page,
    pages,
    sortOrder,
    sortCol,
    filterType,
  })

  if (success) {
    const { docs, totalDocs, totalPages } = success

    yield put({
      type: 'workflow/SET_STATE',
      payload: {
        docs,
        total: totalDocs,
        pages: totalPages,
      },
    })
  }

  yield put({
    type: 'workflow/SET_STATE',
    payload: {
      loadingDocs: false,
    },
  })
}

export function* SET_DOC({ payload }) {
  const { workflowRef } = payload

  yield put({
    type: 'workflow/SET_STATE',
    payload: {
      loadingDoc: true,
    },
  })

  const doc = yield call(getWorkflow, workflowRef)

  yield put({
    type: 'workflow/SET_STATE',
    payload: {
      doc,
      loadingDoc: false,
    },
  })
}

export function* SET_CURRENT_PAGE({ payload }) {
  const { page } = payload

  yield put({
    type: 'workflow/SET_STATE',
    payload: {
      page,
    },
  })

  yield call(SET_DOCS)
}

export function* SET_LIMIT({ payload }) {
  const { limit } = payload

  yield put({
    type: 'workflow/SET_STATE',
    payload: {
      limit,
    },
  })

  yield call(SET_DOCS)
}

export function* SET_SORTING({ payload }) {
  const { sortOrder, sortCol } = payload

  yield put({
    type: 'workflow/SET_STATE',
    payload: {
      sortOrder,
      sortCol,
    },
  })

  yield call(SET_DOCS)
}

export function* SET_SEARCH_TEXT({ payload }) {
  const { searchText } = payload

  yield put({
    type: 'workflow/SET_STATE',
    payload: {
      searchText,
      page: 1,
    },
  })
}

export function* THROTTLE_SET_SEARCH_TEXT() {
  yield call(SET_DOCS)
}

export function* UPDATE({ payload }) {
  const { name, type, workflowRef, resetForm, resetStates } = payload

  yield put({
    type: 'workflow/SET_STATE',
    payload: {
      loadingAction: true,
    },
  })

  const success = yield call(updateWorkflow, {
    name,
    type,
    workflowRef,
  })

  if (success) {
    yield call(SET_DOC, { payload: { workflowRef } })
    notification.success({
      message: 'Success',
      description: 'Workflow has been updated',
    })

    if (resetForm) resetForm()
    if (resetStates) resetStates()
  }

  yield put({
    type: 'workflow/SET_STATE',
    payload: {
      loadingAction: false,
    },
  })
}

export function* CREATE_STEP({ payload }) {
  const { workflowRef, resetForm, resetStates } = payload

  yield put({
    type: 'workflow/SET_STATE',
    payload: {
      loadingAction: true,
    },
  })

  const success = yield call(addWorkflowStep, workflowRef)

  if (success) {
    yield call(SET_DOC, { payload: { workflowRef } })
    notification.success({
      message: 'Success',
      description: 'New step has been added to workflow',
    })

    if (resetForm) resetForm()
    if (resetStates) resetStates()
  }

  yield put({
    type: 'workflow/SET_STATE',
    payload: {
      loadingAction: false,
    },
  })
}

export function* ASSIGN_APPROVER_TO_STEP({ payload }) {
  const { workflowStepRef, approverRef, workflowRef, resetForm, resetStates } = payload

  yield put({
    type: 'workflow/SET_STATE',
    payload: {
      loadingAction: true,
    },
  })

  const success = yield call(assignApproverToWorkflowStep, {
    workflowStepRef,
    approverRef,
  })

  if (success) {
    yield call(SET_DOC, { payload: { workflowRef } })
    notification.success({
      message: 'Success',
      description: 'An approver has been assigned to workflow step',
    })

    if (resetForm) resetForm()
    if (resetStates) resetStates()
  }

  yield put({
    type: 'workflow/SET_STATE',
    payload: {
      loadingAction: false,
    },
  })
}

export function* DELETE_STEP({ payload }) {
  const { workflowStepRef, workflowRef, resetForm, resetStates } = payload

  yield put({
    type: 'workflow/SET_STATE',
    payload: {
      loadingAction: true,
    },
  })

  const success = yield call(removeWorkflowStep, workflowStepRef)

  if (success) {
    yield call(SET_DOC, { payload: { workflowRef } })
    notification.success({
      message: 'Success',
      description: 'Workflow step has been deleted',
    })

    if (resetForm) resetForm()
    if (resetStates) resetStates()
  }

  yield put({
    type: 'workflow/SET_STATE',
    payload: {
      loadingAction: false,
    },
  })
}

export function* DELETE({ payload }) {
  const { workflowRef } = payload

  yield put({
    type: 'workflow/SET_STATE',
    payload: {
      loadingAction: true,
    },
  })

  const success = yield call(deleteWorkflow, workflowRef)

  if (success) {
    yield history.push('/workflow')
    notification.success({
      message: 'Success',
      description: 'Workflow has been deleted',
    })
  }

  yield put({
    type: 'workflow/SET_STATE',
    payload: {
      loadingAction: false,
    },
  })
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.CREATE, CREATE),
    takeEvery(actions.UPDATE, UPDATE),
    takeEvery(actions.CREATE_STEP, CREATE_STEP),
    takeEvery(actions.ASSIGN_APPROVER_TO_STEP, ASSIGN_APPROVER_TO_STEP),
    takeEvery(actions.DELETE_STEP, DELETE_STEP),
    takeEvery(actions.DELETE, DELETE),
    takeEvery(actions.SET_DOCS, SET_DOCS),
    takeEvery(actions.SET_DOC, SET_DOC),
    takeEvery(actions.SET_CURRENT_PAGE, SET_CURRENT_PAGE),
    takeEvery(actions.SET_LIMIT, SET_LIMIT),
    takeEvery(actions.SET_SORTING, SET_SORTING),
    takeEvery(actions.SET_SEARCH_TEXT, SET_SEARCH_TEXT),
    debounce(500, actions.SET_SEARCH_TEXT, THROTTLE_SET_SEARCH_TEXT),
  ])
}

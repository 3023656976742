import apiClient from '../axios'

export async function createOrderRequest(body) {
  return apiClient
    .post(`/order-request/create`, body)
    .then(response => (response && response.data ? response.data : false)) // eslint-disable-line
    .catch(error => console.log(error.message))
}

export async function getOrderRequests({
  page,
  pages,
  searchText,
  limit,
  sortOrder,
  sortCol,
  filterPriority,
  filterLocation,
  filterDepartment,
  filterRequester,
  filterVendor,
  filterStatus,
  filterDateRangeStart,
  filterDateRangeEnd,
}) {
  return apiClient
    .get(
      `/order-request?page=${
        pages >= page ? page : 1
      }&search=${searchText.trim()}&limit=${limit}&sort_order=${sortOrder}&sort_col=${sortCol}&filter_priority=${filterPriority}&filter_location=${filterLocation}&filter_department=${filterDepartment}&filter_requester=${filterRequester}&filter_vendor=${filterVendor}&filter_status=${filterStatus}&filter_dateRangeStart=${filterDateRangeStart}&filter_dateRangeEnd=${filterDateRangeEnd}`,
    )
    .then(response => (response && response.data ? response.data : false))
    .catch(error => console.log(error))
}

export async function getOrderRequest(orderRequestRef) {
  return apiClient
    .get(`/order-request/${orderRequestRef}`)
    .then(response => (response && response.data ? response.data : false))
    .catch(error => console.log(error))
}

export async function updateOrderRequest(body) {
  return apiClient
    .post(`/order-request/update/${body.orderRequestRef}`, body)
    .then(response => (response && response.data ? response.data : false)) // eslint-disable-line
    .catch(error => console.log(error))
}

export async function submitOrderRequest(orderRequestRef) {
  return apiClient
    .post(`/order-request/submit/${orderRequestRef}`)
    .then(response => (response && response.data ? response.data : false)) // eslint-disable-line
    .catch(error => console.log(error))
}

export async function openOrderRequest(orderRequestRef) {
  return apiClient
    .post(`/order-request/open/${orderRequestRef}`)
    .then(response => (response && response.data ? response.data : false)) // eslint-disable-line
    .catch(error => console.log(error))
}

export async function reviseOrderRequest(orderRequestRef) {
  return apiClient
    .post(`/order-request/revise/${orderRequestRef}`)
    .then(response => (response && response.data ? response.data : false)) // eslint-disable-line
    .catch(error => console.log(error))
}

export async function approveOrderRequest(orderRequestRef) {
  return apiClient
    .post(`/order-request/approve/${orderRequestRef}`)
    .then(response => (response && response.data ? response.data : false)) // eslint-disable-line
    .catch(error => console.log(error))
}

export async function rejectOrderRequest(orderRequestRef) {
  return apiClient
    .post(`/order-request/reject/${orderRequestRef}`)
    .then(response => (response && response.data ? response.data : false)) // eslint-disable-line
    .catch(error => console.log(error))
}

export async function addOrderItem(body) {
  return apiClient
    .post(`/order-request/item/create/${body.orderRequestRef}`, body)
    .then(response => (response && response.data ? response.data : false)) // eslint-disable-line
    .catch(error => console.log(error))
}

export async function updateOrderItem(body) {
  return apiClient
    .post(`/order-request/item/update/${body.orderItemRef}`, body)
    .then(response => (response && response.data ? response.data : false)) // eslint-disable-line
    .catch(error => console.log(error))
}

export async function deleteOrderItem(body) {
  return apiClient
    .post(`/order-request/item/delete/${body.orderItemRef}`, body)
    .then(response => (response && response.data ? response.data : false)) // eslint-disable-line
    .catch(error => console.log(error))
}

export async function createComment(body) {
  return apiClient
    .post(`/order-request/comment/create`, body)
    .then(response => (response && response.data ? response.data : false)) // eslint-disable-line
    .catch(error => console.log(error.message))
}

export async function updateComment(body) {
  return apiClient
    .post(`/order-request/comment/update/${body.commentRef}`, body)
    .then(response => (response && response.data ? response.data : false)) // eslint-disable-line
    .catch(error => console.log(error))
}

export async function addCommentLike(commentRef) {
  return apiClient
    .post(`/order-request/comment/add-like/${commentRef}`)
    .then(response => (response && response.data ? response.data : false)) // eslint-disable-line
    .catch(error => console.log(error))
}

export async function removeCommentLike(commentRef) {
  return apiClient
    .post(`/order-request/comment/remove-like/${commentRef}`)
    .then(response => (response && response.data ? response.data : false)) // eslint-disable-line
    .catch(error => console.log(error))
}

export async function deleteComment(commentRef) {
  return apiClient
    .post(`/order-request/comment/delete/${commentRef}`)
    .then(response => (response && response.data ? response.data : false)) // eslint-disable-line
    .catch(error => console.log(error))
}

export async function deleteOrderRequest(body) {
  return apiClient
    .post(`/order-request/delete/${body.orderRequestRef}`, body)
    .then(response => (response && response.data ? response.data : false)) // eslint-disable-line
    .catch(error => console.log(error))
}

import apiClient from '../axios'

export async function createLead(body) {
  return apiClient
    .post(`/lead/create`, body)
    .then(response => (response && response.data ? response.data : false)) // eslint-disable-line
    .catch(error => console.log(error.message))
}

export async function getLeads({
  page,
  pages,
  searchText,
  limit,
  sortOrder,
  sortCol,
  filterStatus,
  filterPropertyType,
  filterStaff,
  filterAssignee,
}) {
  return apiClient
    .get(
      `/lead?page=${
        pages >= page ? page : 1
      }&search=${searchText.trim()}&limit=${limit}&sort_order=${sortOrder}&sort_col=${sortCol}&filter_status=${filterStatus}&filter_propertyType=${filterPropertyType}&filter_staff=${filterStaff}&filter_assignee=${filterAssignee}`,
    )
    .then(response => (response && response.data ? response.data : false))
    .catch(error => console.log(error))
}

export async function getLead(leadRef) {
  return apiClient
    .get(`/lead/${leadRef}`)
    .then(response => (response && response.data ? response.data : false))
    .catch(error => console.log(error))
}

export async function updateLead(body) {
  return apiClient
    .post(`/lead/update/${body.leadRef}`, body)
    .then(response => (response && response.data ? response.data : false)) // eslint-disable-line
    .catch(error => console.log(error))
}

export async function updateStatus(body) {
  return apiClient
    .post(`/lead/update-status/${body.leadRef}`, body)
    .then(response => (response && response.data ? response.data : false)) // eslint-disable-line
    .catch(error => console.log(error))
}

export async function assign(body) {
  return apiClient
    .post(`/lead/assign/${body.leadRef}`, body)
    .then(response => (response && response.data ? response.data : false)) // eslint-disable-line
    .catch(error => console.log(error))
}

export async function deleteLead(leadRef) {
  return apiClient
    .post(`/lead/delete/${leadRef}`)
    .then(response => (response && response.data ? response.data : false)) // eslint-disable-line
    .catch(error => console.log(error))
}

export async function addFollowUp(body) {
  return apiClient
    .post(`/lead/add-followup/${body.leadRef}`, body)
    .then(response => (response && response.data ? response.data : false)) // eslint-disable-line
    .catch(error => console.log(error))
}

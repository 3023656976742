const actions = {
  CREATE: 'expenseRequest/CREATE',
  UPDATE: 'expenseRequest/UPDATE',
  SET_FILTERS: 'expenseRequest/SET_FILTERS',
  OPEN: 'expenseRequest/OPEN',
  DELETE: 'expenseRequest/DELETE',
  CREATE_COMMENT: 'expenseRequest/CREATE_COMMENT',
  UPDATE_COMMENT: 'expenseRequest/UPDATE_COMMENT',
  LIKE_COMMENT: 'expenseRequest/LIKE_COMMENT',
  UNLIKE_COMMENT: 'expenseRequest/UNLIKE_COMMENT',
  DELETE_COMMENT: 'expenseRequest/DELETE_COMMENT',
  SUBMIT: 'expenseRequest/SUBMIT',
  REVISE: 'expenseRequest/REVISE',
  APPROVE: 'expenseRequest/APPROVE',
  REJECT: 'expenseRequest/REJECT',
  COMPLETE: 'expenseRequest/COMPLETE',
  SET_STATE: 'expenseRequest/SET_STATE',
  SET_DOCS: 'expenseRequest/SET_DOCS',
  SET_DOC: 'expenseRequest/SET_DOC',
  SET_CURRENT_PAGE: 'expenseRequest/SET_CURRENT_PAGE',
  SET_LIMIT: 'expenseRequest/SET_LIMIT',
  SET_SORTING: 'expenseRequest/SET_SORTING',
  SET_SEARCH_TEXT: 'expenseRequest/SET_SEARCH_TEXT',
}

export default actions

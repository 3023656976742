const actions = {
  CREATE: 'expenditure/CREATE',
  UPDATE: 'expenditure/UPDATE',
  DELETE: 'expenditure/DELETE',
  SET_STATE: 'expenditure/SET_STATE',
  SET_DOCS: 'expenditure/SET_DOCS',
  SET_PAYMENT_REPORT: 'expenditure/SET_PAYMENT_REPORT',
  SET_DOC: 'expenditure/SET_DOC',
  SET_CURRENT_PAGE: 'expenditure/SET_CURRENT_PAGE',
  SET_LIMIT: 'expenditure/SET_LIMIT',
  SET_SORTING: 'expenditure/SET_SORTING',
  SET_SEARCH_TEXT: 'expenditure/SET_SEARCH_TEXT',
  SET_FILTERS: 'expenditure/SET_FILTERS',
  CREATE_PAYMENT: 'expenditure/CREATE_PAYMENT',
  COMPLETE: 'expenditure/COMPLETE',
  CANCEL: 'expenditure/CANCEL',
}

export default actions

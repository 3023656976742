/* eslint-disable */
import React, { useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { useHistory } from 'react-router-dom'
import { connect } from 'react-redux'
// import { UserOutlined } from '@ant-design/icons'
import { Menu, Dropdown, Avatar, Badge } from 'antd'
import styles from './style.module.scss'

const mapStateToProps = ({ user }) => ({ user })

const ProfileMenu = ({ dispatch, user }) => {
  const history = useHistory()
  const [count, setCount] = useState(7)

  const logout = () => {
    dispatch({
      type: 'user/LOGOUT',
    })
  }

  const addCount = () => {
    setCount(count + 1)
  }

  const menu = (
    <Menu selectable={false}>
      <Menu.Item onClick={() => history.push('/profile')}>
        <i style={{ color: '#0E9E36' }} className="fe fe-user mr-2" />
        <span>View profile</span>
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item onClick={() => history.push('/profile')}>
        <i style={{ color: '#0E9E36' }} className="fe fe-download mr-2" />
        <span>Download brochure</span>
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item onClick={logout}>
        <i style={{ color: '#0E9E36' }} className="fe fe-log-out mr-2" />
        <FormattedMessage id="topBar.profileMenu.logout" />
      </Menu.Item>
    </Menu>
  )
  return (
    <Dropdown overlay={menu} trigger={['hover']} onVisibleChange={addCount}>
      <div className={styles.dropdown}>
        <Badge>
          <Avatar
            // size="default"
            // style={{ backgroundColor: '#0f2e4f', color: '#fff' }}
            size="large"
            className={styles.avatar}
            icon={
              <span>
                {user.firstname.split('')[0].toUpperCase()}
                {user.lastname.split('')[0].toUpperCase()}
              </span>
            }
            src={`${user.avatar && user.avatar._id ? user.avatar.preview : ''}`}
          />
          {/* <Avatar className={styles.avatar} shape="circle" size="large" icon={<UserOutlined />} /> */}
        </Badge>
      </div>
    </Dropdown>
  )
}

export default connect(mapStateToProps)(ProfileMenu)

import actions from './actions'

const initialState = {
  docs: [],
  doc: {},
  page: 1,
  pages: 1,
  searchText: '',
  limit: 10,
  total: 0,
  sortOrder: 'descend',
  sortCol: 'createdAt',
  filterReferrerType: '',
  filterReferrer: '',
  modalState: false,
  addressModalState: false,
  loadingDoc: false,
  loadingDocs: false,
  loadingAction: false,
}

export default function clientReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload }

    default:
      return state
  }
}

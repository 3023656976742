const actions = {
  SET_STATE: 'agent/SET_STATE',
  SET_DOCS: 'agent/SET_DOCS',
  SET_CURRENT_PAGE: 'agent/SET_CURRENT_PAGE',
  SET_LIMIT: 'agent/SET_LIMIT',
  SET_SORTING: 'agent/SET_SORTING',
  SET_SEARCH_TEXT: 'agent/SET_SEARCH_TEXT',
  THROTTLE_SET_SEARCH_TEXT: 'agent/THROTTLE_SET_SEARCH_TEXT',
  CREATE: 'agent/CREATE',
  UPDATE: 'agent/UPDATE',
  DELETE: 'agent/DELETE',
}

export default actions

import apiClient from '../axios'

export async function createVendor(body) {
  return apiClient
    .post(`/vendor/create`, body)
    .then(response => (response && response.data ? response.data : false)) // eslint-disable-line
    .catch(error => console.log(error.message))
}

export async function getVendors({ page, pages, searchText, limit, sortOrder, sortCol }) {
  return apiClient
    .get(
      `/vendor?page=${
        pages >= page ? page : 1
      }&search=${searchText.trim()}&limit=${limit}&sort_order=${sortOrder}&sort_col=${sortCol}`,
    )
    .then(response => (response && response.data ? response.data : false))
    .catch(error => console.log(error))
}

export async function getVendor(vendorRef) {
  return apiClient
    .get(`/vendor/${vendorRef}`)
    .then(response => (response && response.data ? response.data : false))
    .catch(error => console.log(error))
}

export async function updateVendor(body) {
  return apiClient
    .post(`/vendor/update/${body.vendorRef}`, body)
    .then(response => (response && response.data ? response.data : false)) // eslint-disable-line
    .catch(error => console.log(error))
}

export async function deleteVendor(vendorRef) {
  return apiClient
    .post(`/vendor/delete/${vendorRef}`)
    .then(response => (response && response.data ? response.data : false)) // eslint-disable-line
    .catch(error => console.log(error))
}

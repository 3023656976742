const actions = {
  SET_STATE: 'transaction/SET_STATE',
  SET_FILTERS: 'transaction/SET_FILTERS',
  SET_CURRENT_PAGE: 'transaction/SET_CURRENT_PAGE',
  SET_LIMIT: 'transaction/SET_LIMIT',
  SET_SORTING: 'transaction/SET_SORTING',
  SET_DOCS: 'transaction/SET_DOCS',
  CREDIT_BALANCE: 'transaction/CREDIT_BALANCE',
  DEBIT_BALANCE: 'transaction/DEBIT_BALANCE',
}

export default actions

const actions = {
  CREATE: 'location/CREATE',
  UPDATE: 'location/UPDATE',
  SET_FILTERS: 'location/SET_FILTERS',
  DELETE: 'location/DELETE',
  SET_STATE: 'location/SET_STATE',
  SET_DOCS: 'location/SET_DOCS',
  SET_DOC: 'location/SET_DOC',
  SET_CURRENT_PAGE: 'location/SET_CURRENT_PAGE',
  SET_LIMIT: 'location/SET_LIMIT',
  SET_SORTING: 'location/SET_SORTING',
  SET_SEARCH_TEXT: 'location/SET_SEARCH_TEXT',
}

export default actions

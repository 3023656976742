const actions = {
  CREATE: 'subCategory/CREATE',
  UPDATE: 'subCategory/UPDATE',
  SET_FILTERS: 'subCategory/SET_FILTERS',
  DELETE: 'subCategory/DELETE',
  SET_STATE: 'subCategory/SET_STATE',
  SET_DOCS: 'subCategory/SET_DOCS',
  SET_DOC: 'subCategory/SET_DOC',
  SET_CURRENT_PAGE: 'subCategory/SET_CURRENT_PAGE',
  SET_LIMIT: 'subCategory/SET_LIMIT',
  SET_SORTING: 'subCategory/SET_SORTING',
  SET_SEARCH_TEXT: 'subCategory/SET_SEARCH_TEXT',
}

export default actions

const actions = {
  CREATE: 'contractor/CREATE',
  UPDATE: 'contractor/UPDATE',
  DELETE: 'contractor/DELETE',
  SET_STATE: 'contractor/SET_STATE',
  SET_DOCS: 'contractor/SET_DOCS',
  SET_DOC: 'contractor/SET_DOC',
  SET_CURRENT_PAGE: 'contractor/SET_CURRENT_PAGE',
  SET_LIMIT: 'contractor/SET_LIMIT',
  SET_SORTING: 'contractor/SET_SORTING',
  SET_SEARCH_TEXT: 'contractor/SET_SEARCH_TEXT',
}

export default actions

import actions from './actions'

const initialState = {
  docs: [],
  stats: {},
  doc: {},
  page: 1,
  pages: 1,
  searchText: '',
  limit: 10,
  total: 0,
  sortOrder: 'descend',
  sortCol: 'createdAt',
  filterProperty: '',
  filterProject: '',
  filterClient: '',
  filterReferrer: '',
  filterReferrerType: '',
  filterStatus: '',
  filterType: '',
  filterInstallmentDue: false,
  filterDue: false,
  filterAllowAssign: false,
  filterDatePreset: '',
  filterDateRangeStart: 0,
  filterDateRangeEnd: 0,
  paymentsDrawerState: false,
  createPaymentModalState: false,
  modalState: false,
  assignModalState: false,
  loadingDoc: false,
  loadingDocs: false,
  loadingStats: false,
  loadingAction: false,
}

export default function saleReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload }

    default:
      return state
  }
}

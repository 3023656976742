import apiClient from '../axios'

export async function createClient(body) {
  return apiClient
    .post(`/client/create`, body.data, {
      headers: body.headers,
    })
    .then(response => (response && response.data ? response.data : false)) // eslint-disable-line
    .catch(error => console.log(error.message))
}

export async function getClients({
  page,
  pages,
  searchText,
  limit,
  sortOrder,
  sortCol,
  filterReferrerType,
  filterReferrer,
}) {
  return apiClient
    .get(
      `/client?page=${
        pages >= page ? page : 1
      }&search=${searchText.trim()}&limit=${limit}&sort_order=${sortOrder}&sort_col=${sortCol}&filter_referrerType=${filterReferrerType}&filter_referrer=${filterReferrer}`,
    )
    .then(response => (response && response.data ? response.data : false))
    .catch(error => console.log(error))
}

export async function getClientsAsCSV({
  searchText,
  sortOrder,
  sortCol,
  filterReferrerType,
  filterReferrer,
}) {
  return apiClient
    .get(
      `/client/csv?search=${searchText.trim()}&sort_order=${sortOrder}&sort_col=${sortCol}&filter_referrerType=${filterReferrerType}&filter_referrer=${filterReferrer}`,
      { responseType: 'blob' },
    )
    .then(({ data }) => {
      const blob = new Blob([data], { type: data.type })
      const url = window.URL.createObjectURL(blob)
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', 'document.csv')
      document.body.appendChild(link)
      link.click()
      link.remove()
      window.URL.revokeObjectURL(url)

      return { status: 'success' }
    })
    .catch(error => console.log(error))
}

export async function getClient(clientRef) {
  return apiClient
    .get(`/client/${clientRef}`)
    .then(response => (response && response.data ? response.data : false))
    .catch(error => console.log(error))
}

export async function updateClient(body) {
  return apiClient
    .post(`/client/update/${body.clientRef}`, body.data, {
      headers: body.headers,
    })
    .then(response => (response && response.data ? response.data : false)) // eslint-disable-line
    .catch(error => console.log(error))
}

export async function deleteClient(clientRef) {
  return apiClient
    .post(`/client/delete/${clientRef}`)
    .then(response => (response && response.data ? response.data : false)) // eslint-disable-line
    .catch(error => console.log(error))
}

export async function addClientAddress(body) {
  return apiClient
    .post(`/client/add-address/${body.clientRef}`, body)
    .then(response => (response && response.data ? response.data : false)) // eslint-disable-line
    .catch(error => console.log(error))
}

export async function removeClientAddress(clientAddRef) {
  return apiClient
    .post(`/client/remove-address/${clientAddRef}`)
    .then(response => (response && response.data ? response.data : false)) // eslint-disable-line
    .catch(error => console.log(error))
}

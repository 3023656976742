import React from 'react'
import { /* Button, */ Layout } from 'antd'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import classNames from 'classnames'
import TopBar from 'components/cleanui/layout/TopBar'
import Breadcrumbs from 'components/cleanui/layout/Breadcrumbs'
import Menu from 'components/cleanui/layout/Menu'
// import Footer from 'components/cleanui/layout/Footer'
/* import Sidebar from 'components/cleanui/layout/Sidebar'
import SupportChat from 'components/cleanui/layout/SupportChat' */

import style from './style.module.scss'

const mapStateToProps = ({ settings }) => ({
  isContentMaxWidth: settings.isContentMaxWidth,
  isAppMaxWidth: settings.isAppMaxWidth,
  isGrayBackground: settings.isGrayBackground,
  isSquaredBorders: settings.isSquaredBorders,
  isCardShadow: settings.isCardShadow,
  isBorderless: settings.isBorderless,
  isTopbarFixed: settings.isTopbarFixed,
  isGrayTopbar: settings.isGrayTopbar,
})

const MainLayout = ({
  children,
  isContentMaxWidth,
  isAppMaxWidth,
  isGrayBackground,
  isSquaredBorders,
  isCardShadow,
  isBorderless,
  isTopbarFixed,
  isGrayTopbar,
}) => {
  return (
    <div className={classNames({ cui__layout__grayBackground: isGrayBackground })}>
      <Layout
        className={classNames({
          cui__layout__contentMaxWidth: isContentMaxWidth,
          cui__layout__appMaxWidth: isAppMaxWidth,
          cui__layout__grayBackground: isGrayBackground,
          cui__layout__squaredBorders: isSquaredBorders,
          cui__layout__cardsShadow: isCardShadow,
          cui__layout__borderless: isBorderless,
        })}
      >
        {/* <Sidebar />
        <SupportChat /> */}
        <Menu />
        <Layout className={`${style.container}`}>
          <Layout.Header
            className={`${classNames('cui__layout__header', {
              cui__layout__fixedHeader: isTopbarFixed,
              cui__layout__headerGray: isGrayTopbar,
            })}`}
          >
            <TopBar />
          </Layout.Header>
          <Breadcrumbs />
          <Layout.Content style={{ position: 'relative' }}>
            <div style={{ height: '100%', padding: 0 }} className="border-left cui__utils__content">
              {children}
            </div>
          </Layout.Content>
        </Layout>
      </Layout>

      {/* <span style={{ position: 'absolute', bottom: 20, right: 10 }}>
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://web.whatsapp.com/send?phone=2349080009008&text=Tell%20me%20more%20about%20your%20projects"
        >
          <Button
            type="primary"
            icon={<img src="/resources/icons/whatsapp.svg" alt="whatsapp" height={18} />}
          >
            We are here
          </Button>
        </a>
      </span> */}
    </div>
  )
}

export default withRouter(connect(mapStateToProps)(MainLayout))

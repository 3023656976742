import actions from './actions'

const initialState = {
  doc: {},
  docs: [],
  participant: {},
  tasks: [],
  tasksFilter: 'IN_PROGRESS',
  task: {},
  page: 1,
  pages: 1,
  searchText: '',
  agendaSearchText: '',
  limit: 10,
  total: 0,
  sortOrder: 'descend',
  sortCol: 'createdAt',
  loadingInviteAction: false,
  loadingAction: false,
  loadingDoc: false,
  loadingDocs: false,
  loadingTasks: false,
  loadingTaskAction: false,
  loadingTask: false,
  isModalOpen: false,
  isTaskModalOpen: false,
  isInviteModalOpen: false,
  activeTaskAgenda: '',
  isMeetingInfoDrawerOpen: false,
  isMeetingTaskDrawerOpen: false,
  socket: null,
  isOnline: false,
  isMeetingsDrawerOpen: false,
  meetingsFilterType: 'today',
  meetingsDateStartFilter: 0,
  meetingsDateEndFilter: 0,
}

export default function meetingReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload }

    default:
      return state
  }
}

import { all, takeEvery, put, call, select } from 'redux-saga/effects'
import { notification } from 'antd'
import { getTransactions, creditBalance, debitBalance } from 'services/api/transaction'
import actions from './actions'

export function* SET_DOCS({ payload }) {
  yield put({
    type: 'transaction/SET_STATE',
    payload: {
      loadingDocs: true,
    },
  })

  const { accountRef } = payload

  const {
    page,
    pages,
    limit,
    sortOrder,
    sortCol,
    filterType,
    filterAccount,
    filterDateRangeStart,
    filterDateRangeEnd,
  } = yield select(state => state.transaction)

  const { docs, totalDocs, totalPages } = yield call(getTransactions, {
    page,
    pages,
    limit,
    sortOrder,
    sortCol,
    filterType,
    filterAccount: accountRef || filterAccount,
    filterDateRangeStart,
    filterDateRangeEnd,
  })

  yield put({
    type: 'transaction/SET_STATE',
    payload: {
      docs,
      total: totalDocs,
      pages: totalPages,
    },
  })

  yield put({
    type: 'transaction/SET_STATE',
    payload: {
      loadingDocs: false,
    },
  })
}

export function* SET_CURRENT_PAGE({ payload }) {
  const { page, accountRef } = payload

  yield put({
    type: 'transaction/SET_STATE',
    payload: {
      page,
    },
  })

  yield call(SET_DOCS, { payload: { accountRef } })
}

export function* SET_LIMIT({ payload }) {
  const { limit, accountRef } = payload

  yield put({
    type: 'transaction/SET_STATE',
    payload: {
      limit,
    },
  })

  yield call(SET_DOCS, { payload: { accountRef } })
}

export function* SET_SORTING({ payload }) {
  const { sortOrder, sortCol, accountRef } = payload

  yield put({
    type: 'transaction/SET_STATE',
    payload: {
      sortOrder,
      sortCol,
    },
  })

  yield call(SET_DOCS, { payload: { accountRef } })
}

export function* SET_FILTERS({ payload }) {
  const {
    filterType,
    filterAccount,
    filterDateRangeStart,
    filterDateRangeEnd,
    accountRef,
  } = payload

  const filterObj = {}

  if (filterType !== undefined) {
    filterObj.filterType = filterType
  } else {
    filterObj.filterType = ''
  }
  if (filterAccount !== undefined) {
    filterObj.filterAccount = filterAccount
  } else {
    filterObj.filterAccount = ''
  }
  if (filterDateRangeStart !== undefined) filterObj.filterDateRangeStart = filterDateRangeStart
  if (filterDateRangeEnd !== undefined) filterObj.filterDateRangeEnd = filterDateRangeEnd

  yield put({
    type: 'transaction/SET_STATE',
    payload: filterObj,
  })

  yield call(SET_DOCS, { payload: { accountRef } })
}

export function* CREDIT_BALANCE({ payload }) {
  const { resetForm, resetStates, dontShowNotification, dontFetchDoc } = payload

  yield put({
    type: 'transaction/SET_STATE',
    payload: {
      loadingAction: true,
    },
  })

  const success = yield call(creditBalance, payload)

  if (success) {
    if (!dontFetchDoc) {
      yield call(SET_DOCS, {})
    }
    if (!dontShowNotification) {
      notification.success({
        message: 'Success',
        description: 'balance has been credited',
      })
    }

    if (resetForm) resetForm()
    if (resetStates) resetStates()
  }

  yield put({
    type: 'transaction/SET_STATE',
    payload: {
      loadingAction: false,
    },
  })
}

export function* DEBIT_BALANCE({ payload }) {
  const { resetForm, resetStates, dontShowNotification, dontFetchDoc } = payload

  yield put({
    type: 'transaction/SET_STATE',
    payload: {
      loadingAction: true,
    },
  })

  const success = yield call(debitBalance, payload)

  if (success) {
    if (!dontFetchDoc) {
      yield call(SET_DOCS, {})
    }
    if (!dontShowNotification) {
      notification.success({
        message: 'Success',
        description: 'balance has been debited',
      })
    }

    if (resetForm) resetForm()
    if (resetStates) resetStates()
  }

  yield put({
    type: 'transaction/SET_STATE',
    payload: {
      loadingAction: false,
    },
  })
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.SET_DOCS, SET_DOCS),
    takeEvery(actions.SET_CURRENT_PAGE, SET_CURRENT_PAGE),
    takeEvery(actions.SET_LIMIT, SET_LIMIT),
    takeEvery(actions.SET_SORTING, SET_SORTING),
    takeEvery(actions.SET_FILTERS, SET_FILTERS),
    takeEvery(actions.CREDIT_BALANCE, CREDIT_BALANCE),
    takeEvery(actions.DEBIT_BALANCE, DEBIT_BALANCE),
  ])
}

import apiClient from '../axios'

export async function createDepartment(body) {
  return apiClient
    .post(`/department/create`, body)
    .then(response => (response && response.data ? response.data : false)) // eslint-disable-line
    .catch(error => console.log(error.message))
}

export async function getDepartments({ page, pages, searchText, limit, sortOrder, sortCol }) {
  return apiClient
    .get(
      `/department?page=${
        pages >= page ? page : 1
      }&search=${searchText.trim()}&limit=${limit}&sort_order=${sortOrder}&sort_col=${sortCol}`,
    )
    .then(response => (response && response.data ? response.data : false))
    .catch(error => console.log(error))
}

export async function getDepartment(departmentRef) {
  return apiClient
    .get(`/department/${departmentRef}`)
    .then(response => (response && response.data ? response.data : false))
    .catch(error => console.log(error))
}

export async function updateDepartment(body) {
  return apiClient
    .post(`/department/update/${body.departmentRef}`, body)
    .then(response => (response && response.data ? response.data : false)) // eslint-disable-line
    .catch(error => console.log(error))
}

export async function deleteDepartment(departmentRef) {
  return apiClient
    .post(`/department/delete/${departmentRef}`)
    .then(response => (response && response.data ? response.data : false)) // eslint-disable-line
    .catch(error => console.log(error))
}

const actions = {
  CREATE: 'project/CREATE',
  UPDATE: 'project/UPDATE',
  DELETE: 'project/DELETE',
  SET_STATE: 'project/SET_STATE',
  SET_DOCS: 'project/SET_DOCS',
  SET_DOC: 'project/SET_DOC',
  SET_CURRENT_PAGE: 'project/SET_CURRENT_PAGE',
  SET_LIMIT: 'project/SET_LIMIT',
  SET_SORTING: 'project/SET_SORTING',
  SET_SEARCH_TEXT: 'project/SET_SEARCH_TEXT',
  SET_FILTERS: 'project/SET_FILTERS',
}

export default actions

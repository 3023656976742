import apiClient from '../axios'

export async function getPaymentReports({
  limit,
  page,
  pages,
  sortOrder,
  sortCol,
  filterOwnerType,
  filterOwners,
  filterDirection,
  filterReceiverType,
  filterReceiver,
  filterStatus,
  filterDateRangeStart,
  filterDateRangeEnd,
  filterDatePreset,
}) {
  return apiClient
    .get(
      `/payment/report?page=${
        pages >= page ? page : 1
      }&limit=${limit}&sort_order=${sortOrder}&sort_col=${sortCol}&filter_ownerType=${filterOwnerType}&filter_owners=${filterOwners}&filter_direction=${filterDirection}&filter_receiverType=${filterReceiverType}&filter_receiver=${filterReceiver}&filter_status=${filterStatus}&filter_dateRangeStart=${filterDateRangeStart}&filter_dateRangeEnd=${filterDateRangeEnd}&filter_datePreset=${filterDatePreset}`,
    )
    .then(response => (response && response.data ? response.data : false))
    .catch(error => console.log(error))
}

/* export async function getExpenditureReport({
  filterDateRangeStart,
  filterDateRangeEnd,
  filterDatePreset,
  filterStatus,
}) {
  return apiClient
    .get(
      `/payment?filter_ownerType=Expenditure&filter_status=${filterStatus}&filter_dateRangeStart=${filterDateRangeStart}&filter_dateRangeEnd=${filterDateRangeEnd}&filter_datePreset=${filterDatePreset}`,
    )
    .then(response => (response && response.data ? response.data : false))
    .catch(error => console.log(error))
}

export async function getPurchaseReport({
  filterDateRangeStart,
  filterDateRangeEnd,
  filterDatePreset,
  filterStatus,
}) {
  return apiClient
    .get(
      `/payment?filter_ownerType=Order&filter_status=${filterStatus}&filter_dateRangeStart=${filterDateRangeStart}&filter_dateRangeEnd=${filterDateRangeEnd}&filter_datePreset=${filterDatePreset}`,
    )
    .then(response => (response && response.data ? response.data : false))
    .catch(error => console.log(error))
}

export async function getRecentPurchases({ filterStatus, limit = 5, sortOrder = 'ascend' }) {
  return apiClient
    .get(
      `/payment?limit=${limit}&sort_order=${sortOrder}&filter_ownerType=Order&filter_status=${filterStatus}`,
    )
    .then(response => (response && response.data ? response.data : false))
    .catch(error => console.log(error))
}

export async function getRecentExpenditures({ filterStatus, limit = 5, sortOrder = 'ascend' }) {
  return apiClient
    .get(
      `/payment?limit=${limit}&sort_order=${sortOrder}&filter_ownerType=Expenditure&filter_status=${filterStatus}`,
    )
    .then(response => (response && response.data ? response.data : false))
    .catch(error => console.log(error))
} */

/* export async function getTotalExpenditureCost({ filterStatus }) {
  return apiClient
    .get(`/payment/total?filter_ownerType=Expenditure&filter_status=${filterStatus}`)
    .then(response => (response && response.data ? response.data : false))
    .catch(error => console.log(error))
}

export async function getTotalPurchaseCost({ filterStatus }) {
  return apiClient
    .get(`/payment/total?filter_ownerType=Order&filter_status=${filterStatus}`)
    .then(response => (response && response.data ? response.data : false))
    .catch(error => console.log(error))
} */

export async function getTotal({
  limit,
  page,
  pages,
  sortOrder,
  sortCol,
  filterOwnerType,
  filterOwners,
  filterDirection,
  filterReceiverType,
  filterReceiver,
  filterStatus,
  filterDateRangeStart,
  filterDateRangeEnd,
  filterDatePreset,
}) {
  return apiClient
    .get(
      `/payment/total?page=${
        pages >= page ? page : 1
      }&limit=${limit}&sort_order=${sortOrder}&sort_col=${sortCol}&filter_ownerType=${filterOwnerType}&filter_owners=${filterOwners}&filter_direction=${filterDirection}&filter_receiverType=${filterReceiverType}&filter_receiver=${filterReceiver}&filter_status=${filterStatus}&filter_dateRangeStart=${filterDateRangeStart}&filter_dateRangeEnd=${filterDateRangeEnd}&filter_datePreset=${filterDatePreset}`,
    )
    .then(response => (response && response.data ? response.data : false))
    .catch(error => console.log(error))
}

export async function getPayments({
  limit,
  page,
  pages,
  sortOrder,
  sortCol,
  filterOwnerType,
  filterOwners,
  filterDirection,
  filterReceiverType,
  filterReceiver,
  filterStatus,
  filterDateRangeStart,
  filterDateRangeEnd,
  filterDatePreset,
}) {
  return apiClient
    .get(
      `/payment?page=${
        pages >= page ? page : 1
      }&limit=${limit}&sort_order=${sortOrder}&sort_col=${sortCol}&filter_ownerType=${filterOwnerType}&filter_owners=${filterOwners}&filter_direction=${filterDirection}&filter_receiverType=${filterReceiverType}&filter_receiver=${filterReceiver}&filter_status=${filterStatus}&filter_dateRangeStart=${filterDateRangeStart}&filter_dateRangeEnd=${filterDateRangeEnd}&filter_datePreset=${filterDatePreset}`,
    )
    .then(response => (response && response.data ? response.data : false))
    .catch(error => console.log(error))
}

export async function getPDF({
  limit,
  page,
  pages,
  sortOrder,
  sortCol,
  filterOwnerType,
  filterOwners,
  filterDirection,
  filterReceiverType,
  filterReceiver,
  filterStatus,
  filterDateRangeStart,
  filterDateRangeEnd,
  filterDatePreset,
}) {
  return apiClient
    .get(
      `/payment/pdf?page=${
        pages >= page ? page : 1
      }&limit=${limit}&sort_order=${sortOrder}&sort_col=${sortCol}&filter_ownerType=${filterOwnerType}&filter_owners=${filterOwners}&filter_direction=${filterDirection}&filter_receiverType=${filterReceiverType}&filter_receiver=${filterReceiver}&filter_status=${filterStatus}&filter_dateRangeStart=${filterDateRangeStart}&filter_dateRangeEnd=${filterDateRangeEnd}&filter_datePreset=${filterDatePreset}`,
      { responseType: 'blob' },
    )
    .then(({ data }) => {
      const blob = new Blob([data], { type: data.type })
      const url = window.URL.createObjectURL(blob)
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', 'payments.pdf')
      document.body.appendChild(link)
      link.click()
      link.remove()
      window.URL.revokeObjectURL(url)

      return { status: 'success' }
    })
    .catch(error => console.log(error))
}

export async function cancelPayment(paymentRef) {
  return apiClient
    .post(`/payment/cancel/${paymentRef}`)
    .then(response => (response && response.data ? response.data : false)) // eslint-disable-line
    .catch(error => console.log(error))
}

export async function deletePayment(paymentRef) {
  return apiClient
    .post(`/payment/delete/${paymentRef}`)
    .then(response => (response && response.data ? response.data : false)) // eslint-disable-line
    .catch(error => console.log(error))
}

export async function getReceipt(paymentRef) {
  return apiClient
    .get(`/payment/get-receipt/${paymentRef}`, { responseType: 'blob' })
    .then(({ data }) => {
      const blob = new Blob([data], { type: data.type })
      const url = window.URL.createObjectURL(blob)
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', 'receipt.pdf')
      document.body.appendChild(link)
      link.click()
      link.remove()
      window.URL.revokeObjectURL(url)

      return { status: 'success' }
    })
    .catch(error => console.log(error))
}

import { all, put, call, takeEvery, select /* , debounce  */ } from 'redux-saga/effects'
import {
  getPaymentReports,
  getTotal,
  getPDF,
  getPayments,
  deletePayment,
  cancelPayment,
  getReceipt,
} from 'services/api/payment'
import { notification } from 'antd'
import actions from './actions'

export function* SET_CHART_DOCS() {
  yield put({
    type: 'payment/SET_STATE',
    payload: {
      loadingChartDocs: true,
    },
  })

  // Get queries
  const { filterDateRangeStart, filterDateRangeEnd, filterDatePreset } = yield select(
    state => state.payment,
  )

  const payment = yield call(getPaymentReports, {
    filterDateRangeStart,
    filterDateRangeEnd,
    filterDatePreset,
  })

  if (payment) {
    yield put({
      type: 'payment/SET_STATE',
      payload: {
        chartDocs: payment,
      },
    })
  }

  yield put({
    type: 'payment/SET_STATE',
    payload: {
      loadingChartDocs: false,
    },
  })
}

export function* SET_DOCS() {
  yield put({
    type: 'payment/SET_STATE',
    payload: {
      loadingDocs: true,
    },
  })

  // Get queries
  const {
    limit,
    page,
    pages,
    sortOrder,
    sortCol,
    filterOwnerType,
    filterOwners,
    filterDirection,
    filterReceiverType,
    filterReceiver,
    filterStatus,
    filterDateRangeStart,
    filterDateRangeEnd,
    filterDatePreset,
  } = yield select(state => state.payment)

  const payment = yield call(getPayments, {
    limit,
    page,
    pages,
    sortOrder,
    sortCol,
    filterOwnerType,
    filterOwners,
    filterDirection,
    filterReceiverType,
    filterReceiver,
    filterStatus,
    filterDateRangeStart,
    filterDateRangeEnd,
    filterDatePreset,
  })

  if (payment) {
    const { docs, totalDocs, totalPages } = payment

    yield put({
      type: 'payment/SET_STATE',
      payload: {
        docs,
        total: totalDocs,
        pages: totalPages,
      },
    })
  }

  yield put({
    type: 'payment/SET_STATE',
    payload: {
      loadingDocs: false,
    },
  })
}

export function* SET_TOTAL() {
  yield put({
    type: 'payment/SET_STATE',
    payload: {
      loadingTotal: true,
    },
  })

  // Get queries
  const {
    limit,
    page,
    pages,
    sortOrder,
    sortCol,
    filterOwnerType,
    filterOwners,
    filterDirection,
    filterReceiverType,
    filterReceiver,
    filterStatus,
    filterDateRangeStart,
    filterDateRangeEnd,
    filterDatePreset,
  } = yield select(state => state.payment)

  const { totalExpenditureCost, totalOrderCost, totalSale, total } = yield call(getTotal, {
    limit,
    page,
    pages,
    sortOrder,
    sortCol,
    filterOwnerType,
    filterOwners,
    filterDirection,
    filterReceiverType,
    filterReceiver,
    filterStatus,
    filterDateRangeStart,
    filterDateRangeEnd,
    filterDatePreset,
  })

  yield put({
    type: 'payment/SET_STATE',
    payload: {
      totalExpenditureCost,
      totalPurchaseCost: totalOrderCost,
      totalSale,
      totalPayment: total,
    },
  })

  yield put({
    type: 'payment/SET_STATE',
    payload: {
      loadingTotal: false,
    },
  })
}

export function* SET_CURRENT_PAGE({ payload }) {
  const { page } = payload

  yield put({
    type: 'payment/SET_STATE',
    payload: {
      page,
    },
  })

  yield call(SET_DOCS)
}

export function* SET_LIMIT({ payload }) {
  const { limit } = payload

  yield put({
    type: 'payment/SET_STATE',
    payload: {
      limit,
    },
  })

  yield call(SET_DOCS)
}

export function* SET_SORTING({ payload }) {
  const { sortOrder, sortCol } = payload

  yield put({
    type: 'payment/SET_STATE',
    payload: {
      sortOrder,
      sortCol,
    },
  })

  yield call(SET_DOCS)
}

export function* SET_FILTERS({ payload }) {
  const {
    filterOwnerType,
    filterOwners,
    filterDirection,
    filterReceiverType,
    filterReceiver,
    filterStatus,
    filterDateRangeStart,
    filterDateRangeEnd,
    filterDatePreset,
  } = payload

  const filterObj = {}

  if (filterOwnerType !== undefined) {
    filterObj.filterOwnerType = filterOwnerType
  } else {
    filterObj.filterOwnerType = ''
  }
  if (filterOwners !== undefined) {
    filterObj.filterOwners = filterOwners
  } else {
    filterObj.filterOwners = []
  }
  if (filterDirection !== undefined) filterObj.filterDirection = filterDirection
  if (filterReceiverType !== undefined) {
    filterObj.filterReceiverType = filterReceiverType
  } else {
    filterObj.filterReceiverType = ''
  }
  if (filterReceiver !== undefined) {
    filterObj.filterReceiver = filterReceiver
  } else {
    filterObj.filterReceiver = ''
  }
  if (filterStatus !== undefined) filterObj.filterStatus = filterStatus
  if (filterDateRangeStart !== undefined) filterObj.filterDateRangeStart = filterDateRangeStart
  if (filterDateRangeEnd !== undefined) filterObj.filterDateRangeEnd = filterDateRangeEnd
  if (filterDatePreset !== undefined) filterObj.filterDatePreset = filterDatePreset

  yield put({
    type: 'payment/SET_STATE',
    payload: filterObj,
  })

  yield call(SET_DOCS)
  yield call(SET_CHART_DOCS)
  yield call(SET_TOTAL)
}

export function* GET_PDF() {
  yield put({
    type: 'payment/SET_STATE',
    payload: {
      loadingAction: true,
    },
  })

  const {
    limit,
    page,
    pages,
    sortOrder,
    sortCol,
    filterOwnerType,
    filterOwners,
    filterDirection,
    filterReceiverType,
    filterReceiver,
    filterStatus,
    filterDateRangeStart,
    filterDateRangeEnd,
    filterDatePreset,
  } = yield select(state => state.payment)

  const success = yield call(getPDF, {
    limit,
    page,
    pages,
    sortOrder,
    sortCol,
    filterOwnerType,
    filterOwners,
    filterDirection,
    filterReceiverType,
    filterReceiver,
    filterStatus,
    filterDateRangeStart,
    filterDateRangeEnd,
    filterDatePreset,
  })

  if (success) {
    /*  notification.success({
      message: 'Success',
      description: 'success',
    }) */
  }

  yield put({
    type: 'payment/SET_STATE',
    payload: {
      loadingAction: false,
    },
  })
}

export function* CANCEL({ payload }) {
  const { paymentRef, contextDispatch } = payload

  yield put({
    type: 'payment/SET_STATE',
    payload: {
      loadingAction: true,
    },
  })

  const success = yield call(cancelPayment, paymentRef)

  if (success) {
    notification.success({
      message: 'Success',
      description: 'Payment has been canceled',
    })

    yield call(SET_DOCS)
    yield call(SET_CHART_DOCS)
    yield call(SET_TOTAL)

    if (contextDispatch && contextDispatch.type) yield put(contextDispatch)
  }

  yield put({
    type: 'payment/SET_STATE',
    payload: {
      loadingAction: false,
    },
  })
}

export function* DELETE({ payload }) {
  const { paymentRef, contextDispatch } = payload

  yield put({
    type: 'payment/SET_STATE',
    payload: {
      loadingAction: true,
    },
  })

  const success = yield call(deletePayment, paymentRef)

  if (success) {
    notification.success({
      message: 'Success',
      description: 'Payment has been deleted',
    })

    yield call(SET_DOCS)
    yield call(SET_CHART_DOCS)
    yield call(SET_TOTAL)

    if (contextDispatch && contextDispatch.type) yield put(contextDispatch)
  }

  yield put({
    type: 'payment/SET_STATE',
    payload: {
      loadingAction: false,
    },
  })
}

export function* GET_RECEIPT({ payload }) {
  const { paymentRef } = payload

  yield put({
    type: 'payment/SET_STATE',
    payload: {
      loadingAction: true,
    },
  })

  const success = yield call(getReceipt, paymentRef)

  if (success) {
    /*  notification.success({
      message: 'Success',
      description: 'Receipt downloaded',
    })

    yield call(SET_DOC, { payload: { paymentRef } }) */
  }

  yield put({
    type: 'payment/SET_STATE',
    payload: {
      loadingAction: false,
    },
  })
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.SET_DOCS, SET_DOCS),
    takeEvery(actions.SET_CHART_DOCS, SET_CHART_DOCS),
    // takeEvery(actions.SET_EXPENDITURE_DOCS, SET_EXPENDITURE_DOCS),
    // takeEvery(actions.SET_PURCHASE_DOCS, SET_PURCHASE_DOCS),
    // takeEvery(actions.SET_RECENT_PURCHASES, SET_RECENT_PURCHASES),
    // takeEvery(actions.SET_RECENT_EXPENDITURES, SET_RECENT_EXPENDITURES),
    // takeEvery(actions.SET_TOTAL_PURCHASE, SET_TOTAL_PURCHASE),
    // takeEvery(actions.SET_TOTAL_EXPENDITURE, SET_TOTAL_EXPENDITURE),
    takeEvery(actions.SET_CURRENT_PAGE, SET_CURRENT_PAGE),
    takeEvery(actions.SET_LIMIT, SET_LIMIT),
    takeEvery(actions.SET_SORTING, SET_SORTING),
    takeEvery(actions.SET_TOTAL, SET_TOTAL),
    takeEvery(actions.GET_PDF, GET_PDF),
    takeEvery(actions.SET_FILTERS, SET_FILTERS),
    takeEvery(actions.CANCEL, CANCEL),
    takeEvery(actions.DELETE, DELETE),
    takeEvery(actions.GET_RECEIPT, GET_RECEIPT),
  ])
}

import actions from './actions'

const initialState = {
  page: 1,
  pages: 1,
  chartDocs: [],
  docs: [],
  recentPurchases: [],
  recentExpenditures: [],
  totalExpenditureCost: 0,
  totalPurchaseCost: 0,
  totalSale: 0,
  totalPayment: 0,
  totalBalance: 0,
  limit: 10,
  total: 0,
  sortOrder: 'descend',
  sortCol: 'paymentDate',
  filterOwnerType: '',
  filterOwners: [],
  filterDirection: '',
  filterReceiverType: '',
  filterReceiver: '',
  filterStatus: 'SUCCESS',
  filterDateRangeStart: 0,
  filterDateRangeEnd: 0,
  filterDatePreset: '',
  loadingDocs: false,
  loadingAction: false,
  loadingChartDocs: false,
  loadingRecExp: false,
  loadingRecPur: false,
  loadingTotal: false,
}

export default function paymentReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload }

    default:
      return state
  }
}

const actions = {
  CREATE: 'orderRequest/CREATE',
  UPDATE: 'orderRequest/UPDATE',
  SET_FILTERS: 'orderRequest/SET_FILTERS',
  OPEN: 'orderRequest/OPEN',
  DELETE: 'orderRequest/DELETE',
  CREATE_ITEM: 'orderRequest/CREATE_ITEM',
  UPDATE_ITEM: 'orderRequest/UPDATE_ITEM',
  DELETE_ITEM: 'orderRequest/DELETE_ITEM',
  CREATE_COMMENT: 'orderRequest/CREATE_COMMENT',
  UPDATE_COMMENT: 'orderRequest/UPDATE_COMMENT',
  LIKE_COMMENT: 'orderRequest/LIKE_COMMENT',
  UNLIKE_COMMENT: 'orderRequest/UNLIKE_COMMENT',
  DELETE_COMMENT: 'orderRequest/DELETE_COMMENT',
  SUBMIT: 'orderRequest/SUBMIT',
  REVISE: 'orderRequest/REVISE',
  APPROVE: 'orderRequest/APPROVE',
  REJECT: 'orderRequest/REJECT',
  COMPLETE: 'orderRequest/COMPLETE',
  SET_STATE: 'orderRequest/SET_STATE',
  SET_DOCS: 'orderRequest/SET_DOCS',
  SET_DOC: 'orderRequest/SET_DOC',
  SET_CURRENT_PAGE: 'orderRequest/SET_CURRENT_PAGE',
  SET_LIMIT: 'orderRequest/SET_LIMIT',
  SET_SORTING: 'orderRequest/SET_SORTING',
  SET_SEARCH_TEXT: 'orderRequest/SET_SEARCH_TEXT',
}

export default actions

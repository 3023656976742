import { all, put, call, takeEvery, select, debounce } from 'redux-saga/effects'
import { history } from 'index'
import {
  createLead,
  updateLead,
  updateStatus,
  assign,
  getLeads,
  getLead,
  deleteLead,
  addFollowUp,
} from 'services/api/lead'
import { notification } from 'antd'
import actions from './actions'

export function* CREATE({ payload }) {
  const { resetForm, resetStates } = payload

  yield put({
    type: 'lead/SET_STATE',
    payload: {
      loadingAction: true,
    },
  })

  const success = yield call(createLead, payload)

  if (success) {
    yield call(SET_DOCS, { payload: {} })
    notification.success({
      message: 'Success',
      description: 'New lead added',
    })

    if (resetForm) resetForm()
    if (resetStates) resetStates()
  }

  yield put({
    type: 'lead/SET_STATE',
    payload: {
      loadingAction: false,
    },
  })
}

export function* SET_DOCS() {
  yield put({
    type: 'lead/SET_STATE',
    payload: {
      loadingDocs: true,
    },
  })

  // Get queries
  const {
    limit,
    searchText,
    page,
    pages,
    sortOrder,
    sortCol,
    filterStatus,
    filterPropertyType,
    filterStaff,
    filterAssignee,
  } = yield select(state => state.lead)

  const success = yield call(getLeads, {
    limit,
    searchText,
    page,
    pages,
    sortOrder,
    sortCol,
    filterStatus,
    filterPropertyType,
    filterStaff,
    filterAssignee,
  })

  if (success) {
    const { docs, totalDocs, totalPages } = success

    yield put({
      type: 'lead/SET_STATE',
      payload: {
        docs,
        total: totalDocs,
        pages: totalPages,
      },
    })
  }

  yield put({
    type: 'lead/SET_STATE',
    payload: {
      loadingDocs: false,
    },
  })
}

export function* SET_DOC({ payload }) {
  const { leadRef } = payload

  yield put({
    type: 'lead/SET_STATE',
    payload: {
      loadingDoc: true,
    },
  })

  const doc = yield call(getLead, leadRef)

  yield put({
    type: 'lead/SET_STATE',
    payload: {
      doc,
      loadingDoc: false,
    },
  })
}

export function* SET_CURRENT_PAGE({ payload }) {
  const { page } = payload

  yield put({
    type: 'lead/SET_STATE',
    payload: {
      page,
    },
  })

  yield call(SET_DOCS)
}

export function* SET_LIMIT({ payload }) {
  const { limit } = payload

  yield put({
    type: 'lead/SET_STATE',
    payload: {
      limit,
    },
  })

  yield call(SET_DOCS)
}

export function* SET_SORTING({ payload }) {
  const { sortOrder, sortCol } = payload

  yield put({
    type: 'lead/SET_STATE',
    payload: {
      sortOrder,
      sortCol,
    },
  })

  yield call(SET_DOCS)
}

export function* SET_SEARCH_TEXT({ payload }) {
  const { searchText } = payload

  yield put({
    type: 'lead/SET_STATE',
    payload: {
      searchText,
      page: 1,
    },
  })
}

export function* THROTTLE_SET_SEARCH_TEXT() {
  yield call(SET_DOCS)
}

export function* SET_FILTERS({ payload }) {
  const { filterStatus, filterStaff, filterAssignee } = payload

  yield put({
    type: 'lead/SET_STATE',
    payload: {
      filterStaff,
      filterStatus,
      filterAssignee,
    },
  })

  yield call(SET_DOCS)
}

export function* UPDATE({ payload }) {
  const { resetForm, resetStates } = payload

  yield put({
    type: 'lead/SET_STATE',
    payload: {
      loadingAction: true,
    },
  })

  const success = yield call(updateLead, payload)

  if (success) {
    notification.success({
      message: 'Success',
      description: 'Lead has been updated',
    })

    if (resetForm) resetForm()
    if (resetStates) resetStates()
  }

  yield put({
    type: 'lead/SET_STATE',
    payload: {
      loadingAction: false,
    },
  })
}

export function* UPDATE_STATUS({ payload }) {
  const { resetForm, resetStates, leadRef } = payload

  yield put({
    type: 'lead/SET_STATE',
    payload: {
      loadingAction: true,
    },
  })

  const success = yield call(updateStatus, payload)

  if (success) {
    yield put({ type: actions.SET_DOC, payload: { leadRef } })

    notification.success({
      message: 'Success',
      description: 'Lead status has been updated',
    })

    if (resetForm) resetForm()
    if (resetStates) resetStates()
  }

  yield put({
    type: 'lead/SET_STATE',
    payload: {
      loadingAction: false,
    },
  })
}

export function* ASSIGN({ payload }) {
  const { resetForm, resetStates, leadRef } = payload

  yield put({
    type: 'lead/SET_STATE',
    payload: {
      loadingAction: true,
    },
  })

  const success = yield call(assign, payload)

  if (success) {
    yield put({ type: actions.SET_DOC, payload: { leadRef } })

    notification.success({
      message: 'Success',
      description: 'Lead has been assigned',
    })

    if (resetForm) resetForm()
    if (resetStates) resetStates()
  }

  yield put({
    type: 'lead/SET_STATE',
    payload: {
      loadingAction: false,
    },
  })
}

export function* DELETE({ payload }) {
  const { leadRef } = payload

  yield put({
    type: 'lead/SET_STATE',
    payload: {
      loadingAction: true,
    },
  })

  const success = yield call(deleteLead, leadRef)

  if (success) {
    yield history.push('/lead')
    notification.success({
      message: 'Success',
      description: 'Lead has been deleted',
    })
  }

  yield put({
    type: 'lead/SET_STATE',
    payload: {
      loadingAction: false,
    },
  })
}

export function* ADD_FOLLOW_UP({ payload }) {
  const { resetForm, resetStates } = payload

  yield put({
    type: 'lead/SET_STATE',
    payload: {
      loadingAction: true,
    },
  })

  const success = yield call(addFollowUp, payload)

  if (success) {
    yield call(SET_DOC, { payload: { leadRef: payload.leadRef } })

    notification.success({
      message: 'Success',
      description: "Lead's follow up has been updated",
    })

    if (resetForm) resetForm()
    if (resetStates) resetStates()
  }

  yield put({
    type: 'lead/SET_STATE',
    payload: {
      loadingAction: false,
    },
  })
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.CREATE, CREATE),
    takeEvery(actions.UPDATE, UPDATE),
    takeEvery(actions.UPDATE_STATUS, UPDATE_STATUS),
    takeEvery(actions.ASSIGN, ASSIGN),
    takeEvery(actions.ADD_FOLLOW_UP, ADD_FOLLOW_UP),
    takeEvery(actions.DELETE, DELETE),
    takeEvery(actions.SET_DOCS, SET_DOCS),
    takeEvery(actions.SET_DOC, SET_DOC),
    takeEvery(actions.SET_CURRENT_PAGE, SET_CURRENT_PAGE),
    takeEvery(actions.SET_LIMIT, SET_LIMIT),
    takeEvery(actions.SET_SORTING, SET_SORTING),
    takeEvery(actions.SET_SEARCH_TEXT, SET_SEARCH_TEXT),
    debounce(500, actions.SET_SEARCH_TEXT, THROTTLE_SET_SEARCH_TEXT),
    takeEvery(actions.SET_FILTERS, SET_FILTERS),
  ])
}

// import store from 'store'
import apiClient from '../axios'

export async function getTransactions({
  limit,
  page,
  pages,
  sortOrder,
  sortCol,
  filterAccount,
  filterType,
  filterDateRangeStart,
  filterDateRangeEnd,
}) {
  return apiClient
    .get(
      `/transaction?page=${
        pages >= page ? page : 1
      }&limit=${limit}&sort_order=${sortOrder}&sort_col=${sortCol}&filter_type=${filterType}&filter_account=${filterAccount}&filter_dateRangeStart=${filterDateRangeStart}&filter_dateRangeEnd=${filterDateRangeEnd}`,
    )
    .then(response => (response && response.data ? response.data : false))
    .catch(error => console.log(error))
}

export async function creditBalance(body) {
  return apiClient
    .post(`/transaction/credit-balance`, body)
    .then(response => (response && response.data ? response.data : false)) // eslint-disable-line
    .catch(error => console.log(error))
}

export async function debitBalance(body) {
  return apiClient
    .post(`/transaction/debit-balance`, body)
    .then(response => (response && response.data ? response.data : false)) // eslint-disable-line
    .catch(error => console.log(error))
}

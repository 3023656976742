import { all, put, call, takeEvery, select, debounce } from 'redux-saga/effects'
import {
  createProperty,
  updateProperty,
  updateProperties,
  getProperties,
  getProperty,
  deleteProperty,
} from 'services/api/property'
import { notification } from 'antd'
import actions from './actions'

export function* CREATE({ payload }) {
  const { resetForm, resetStates } = payload

  yield put({
    type: 'property/SET_STATE',
    payload: {
      loadingAction: true,
    },
  })

  const success = yield call(createProperty, payload)

  if (success) {
    yield call(SET_DOCS)
    notification.success({
      message: 'Success',
      description: 'New property added',
    })

    if (resetForm) resetForm()
    if (resetStates) resetStates()
  }

  yield put({
    type: 'property/SET_STATE',
    payload: {
      loadingAction: false,
    },
  })
}

export function* SET_DOCS() {
  yield put({
    type: 'property/SET_STATE',
    payload: { loadingDocs: true },
  })

  // Get queries
  const {
    limit,
    searchText,
    page,
    pages,
    sortOrder,
    sortCol,
    filterProject,
    filterLocation,
    filterType,
    filterStatus,
    filterBlock,
    filterClient,
  } = yield select(state => state.property)

  const success = yield call(getProperties, {
    limit,
    searchText,
    page,
    pages,
    sortOrder,
    sortCol,
    filterProject,
    filterLocation,
    filterType,
    filterStatus,
    filterBlock,
    filterClient,
  })

  if (success) {
    const { docs, totalDocs, totalPages } = success

    yield put({
      type: 'property/SET_STATE',
      payload: {
        docs,
        total: totalDocs,
        pages: totalPages,
      },
    })
  }

  yield put({
    type: 'property/SET_STATE',
    payload: {
      loadingDocs: false,
    },
  })
}

export function* SET_DOC({ payload }) {
  const { propertyRef } = payload

  yield put({
    type: 'property/SET_STATE',
    payload: {
      loadingDoc: true,
    },
  })

  const doc = yield call(getProperty, propertyRef)

  yield put({
    type: 'property/SET_STATE',
    payload: {
      doc,
      loadingDoc: false,
    },
  })
}

export function* SET_CURRENT_PAGE({ payload }) {
  const { page } = payload

  yield put({
    type: 'property/SET_STATE',
    payload: {
      page,
    },
  })

  yield call(SET_DOCS)
}

export function* SET_LIMIT({ payload }) {
  const { limit } = payload

  yield put({
    type: 'property/SET_STATE',
    payload: {
      limit,
    },
  })

  yield call(SET_DOCS)
}

export function* SET_SORTING({ payload }) {
  const { sortOrder, sortCol } = payload

  yield put({
    type: 'property/SET_STATE',
    payload: {
      sortOrder,
      sortCol,
    },
  })

  yield call(SET_DOCS)
}

export function* SET_SEARCH_TEXT({ payload }) {
  const { searchText } = payload

  yield put({
    type: 'property/SET_STATE',
    payload: {
      searchText,
      page: 1,
      sortOrder: 'ascend',
      sortCol: 'name',
    },
  })
}

export function* THROTTLE_SET_SEARCH_TEXT() {
  yield call(SET_DOCS)
}

export function* SET_FILTERS({ payload }) {
  const {
    filterProject,
    filterLocation,
    filterType,
    filterStatus,
    filterBlock,
    filterClient,
  } = payload
  yield put({
    type: 'property/SET_STATE',
    payload: {
      filterProject,
      filterLocation,
      filterType,
      filterStatus,
      filterBlock,
      filterClient,
    },
  })

  yield call(SET_DOCS)
}

export function* UPDATE({ payload }) {
  const { resetForm, resetStates } = payload

  yield put({
    type: 'property/SET_STATE',
    payload: {
      loadingAction: true,
    },
  })

  const success = yield call(updateProperty, payload)

  if (success) {
    notification.success({
      message: 'Success',
      description: 'Property has been updated',
    })

    if (resetForm) resetForm()
    if (resetStates) resetStates()
  }

  yield put({
    type: 'property/SET_STATE',
    payload: {
      loadingAction: false,
    },
  })
}

export function* UPDATE_BULK({ payload }) {
  const { resetForm, resetStates } = payload

  yield put({
    type: 'property/SET_STATE',
    payload: {
      loadingAction: true,
    },
  })

  const success = yield call(updateProperties, payload)

  if (success) {
    notification.success({
      message: 'Success',
      description: 'Properties have been updated',
    })

    yield call(SET_DOCS)
    if (resetForm) resetForm()
    if (resetStates) resetStates()
  }

  yield put({
    type: 'property/SET_STATE',
    payload: {
      loadingAction: false,
    },
  })
}

export function* DELETE({ payload }) {
  const { propertyRef } = payload

  yield put({
    type: 'property/SET_STATE',
    payload: {
      loadingAction: true,
    },
  })

  const success = yield call(deleteProperty, propertyRef)

  if (success) {
    yield call(SET_DOCS, {})
    notification.success({
      message: 'Success',
      description: 'Property has been deleted',
    })
  }

  yield put({
    type: 'property/SET_STATE',
    payload: {
      loadingAction: false,
    },
  })
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.CREATE, CREATE),
    takeEvery(actions.UPDATE, UPDATE),
    takeEvery(actions.UPDATE_BULK, UPDATE_BULK),
    takeEvery(actions.DELETE, DELETE),
    takeEvery(actions.SET_DOCS, SET_DOCS),
    takeEvery(actions.SET_DOC, SET_DOC),
    takeEvery(actions.SET_CURRENT_PAGE, SET_CURRENT_PAGE),
    takeEvery(actions.SET_LIMIT, SET_LIMIT),
    takeEvery(actions.SET_SORTING, SET_SORTING),
    takeEvery(actions.SET_SEARCH_TEXT, SET_SEARCH_TEXT),
    debounce(500, actions.SET_SEARCH_TEXT, THROTTLE_SET_SEARCH_TEXT),
    takeEvery(actions.SET_FILTERS, SET_FILTERS),
  ])
}

import apiClient from '../axios'

export async function createPlan(body) {
  return apiClient
    .post(`/plan/create`, body)
    .then(response => (response && response.data ? response.data : false)) // eslint-disable-line
    .catch(error => console.log(error.message))
}

export async function getPlans({ page, pages, searchText, limit, sortOrder, sortCol }) {
  return apiClient
    .get(
      `/plan?page=${
        pages >= page ? page : 1
      }&search=${searchText.trim()}&limit=${limit}&sort_order=${sortOrder}&sort_col=${sortCol}`,
    )
    .then(response => (response && response.data ? response.data : false))
    .catch(error => console.log(error))
}

export async function getPlan(planRef) {
  return apiClient
    .get(`/plan/${planRef}`)
    .then(response => (response && response.data ? response.data : false))
    .catch(error => console.log(error))
}

export async function updatePlan(body) {
  return apiClient
    .post(`/plan/update/${body.planRef}`, body)
    .then(response => (response && response.data ? response.data : false)) // eslint-disable-line
    .catch(error => console.log(error))
}

export async function deletePlan(planRef) {
  return apiClient
    .post(`/plan/delete/${planRef}`)
    .then(response => (response && response.data ? response.data : false)) // eslint-disable-line
    .catch(error => console.log(error))
}

import apiClient from '../axios'

export async function createSale(body) {
  return apiClient
    .post(`/sale/create`, body.data, {
      headers: body.headers,
    })
    .then(response => (response && response.data ? response.data : false)) // eslint-disable-line
    .catch(error => console.log(error.message))
}

export async function getSales({
  page,
  pages,
  searchText,
  limit,
  sortOrder,
  sortCol,
  filterProperty,
  filterProject,
  filterClient,
  filterReferrer,
  filterReferrerType,
  filterStatus,
  filterType,
  filterInstallmentDue,
  filterDue,
  filterAllowAssign,
}) {
  return apiClient
    .get(
      `/sale?page=${
        pages >= page ? page : 1
      }&search=${searchText.trim()}&limit=${limit}&sort_order=${sortOrder}&sort_col=${sortCol}&filter_property=${filterProperty}&filter_project=${filterProject}&filter_client=${filterClient}&filter_referrer=${filterReferrer}&filter_referrerType=${filterReferrerType}&filter_status=${filterStatus}&filter_type=${filterType}&filter_installmentDue=${filterInstallmentDue}&filter_due=${filterDue}&filter_allowAssign=${filterAllowAssign}`,
    )
    .then(response => (response && response.data ? response.data : false))
    .catch(error => console.log(error))
}

export async function getSalesAsCSV({
  searchText,
  sortOrder,
  sortCol,
  filterProperty,
  filterProject,
  filterClient,
  filterReferrer,
  filterReferrerType,
  filterStatus,
  filterType,
  filterInstallmentDue,
  filterDue,
  filterAllowAssign,
}) {
  return apiClient
    .get(
      `/sale/csv?search=${searchText.trim()}&sort_order=${sortOrder}&sort_col=${sortCol}&filter_property=${filterProperty}&filter_project=${filterProject}&filter_client=${filterClient}&filter_referrer=${filterReferrer}&filter_referrerType=${filterReferrerType}&filter_status=${filterStatus}&filter_type=${filterType}&filter_installmentDue=${filterInstallmentDue}&filter_due=${filterDue}&filter_allowAssign=${filterAllowAssign}`,
      { responseType: 'blob' },
    )
    .then(({ data }) => {
      const blob = new Blob([data], { type: data.type })
      const url = window.URL.createObjectURL(blob)
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', 'document.csv')
      document.body.appendChild(link)
      link.click()
      link.remove()
      window.URL.revokeObjectURL(url)

      return { status: 'success' }
    })
    .catch(error => console.log(error))
}

export async function getStatistics({
  filterDatePreset,
  filterDateRangeStart,
  filterDateRangeEnd,
  searchText,
  filterProperty,
  filterProject,
  filterClient,
  filterReferrer,
  filterReferrerType,
  filterStatus,
  filterType,
  filterInstallmentDue,
  filterDue,
  filterAllowAssign,
}) {
  return apiClient
    .get(
      `/sale/statistics?search=${searchText.trim()}&filterDatePreset=${filterDatePreset}&filterDateRangeStart=${filterDateRangeStart}&filterDateRangeEnd=${filterDateRangeEnd}&filter_property=${filterProperty}&filter_project=${filterProject}&filter_client=${filterClient}&filter_referrer=${filterReferrer}&filter_referrerType=${filterReferrerType}&filter_status=${filterStatus}&filter_type=${filterType}&filter_installmentDue=${filterInstallmentDue}&filter_due=${filterDue}&filter_allowAssign=${filterAllowAssign}`,
    )
    .then(response => (response && response.data ? response.data : false))
    .catch(error => console.log(error))
}

export async function getSale(saleRef) {
  return apiClient
    .get(`/sale/${saleRef}`)
    .then(response => (response && response.data ? response.data : false))
    .catch(error => console.log(error))
}

export async function assignProperty(body) {
  return apiClient
    .post(`/sale/assign-property/${body.saleRef}`, body)
    .then(response => (response && response.data ? response.data : false)) // eslint-disable-line
    .catch(error => console.log(error))
}

export async function deleteSale(saleRef) {
  return apiClient
    .post(`/sale/delete/${saleRef}`)
    .then(response => (response && response.data ? response.data : false)) // eslint-disable-line
    .catch(error => console.log(error))
}

export async function addSalePayment(body) {
  return apiClient
    .post(`/sale/create/payment/${body.installmentRef}`, body.data, {
      headers: body.headers,
    })
    .then(response => (response && response.data ? response.data : false)) // eslint-disable-line
    .catch(error => console.log(error))
}

export async function completeSale(saleRef) {
  return apiClient
    .post(`/sale/complete/${saleRef}`)
    .then(response => (response && response.data ? response.data : false)) // eslint-disable-line
    .catch(error => console.log(error))
}

export async function cancelSale(saleRef) {
  return apiClient
    .post(`/sale/cancel/${saleRef}`)
    .then(response => (response && response.data ? response.data : false)) // eslint-disable-line
    .catch(error => console.log(error))
}

export async function getInstallmentInvoicePdf(installmentRef) {
  return apiClient
    .get(`/sale/installment-invoice/${installmentRef}`, { responseType: 'blob' })
    .then(({ data }) => {
      const blob = new Blob([data], { type: data.type })
      const url = window.URL.createObjectURL(blob)
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', 'invoice.pdf')
      document.body.appendChild(link)
      link.click()
      link.remove()
      window.URL.revokeObjectURL(url)

      return { status: 'success' }
    })
    .catch(error => console.log(error))
}

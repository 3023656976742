import apiClient from 'services/axios'
import store from 'store'

export async function login(email, password) {
  return apiClient
    .post('/auth/authorize', {
      email,
      password,
    })
    .then(response => {
      if (response) {
        const { accessToken, refreshToken } = response.data
        if (accessToken) {
          store.set('accessToken', accessToken)
        }
        if (refreshToken) {
          store.set('refreshToken', refreshToken)
        }
        return response.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function register(email, password, firstname, lastname) {
  return apiClient
    .post('/auth/signup', {
      email,
      password,
      firstname,
      lastname,
    })
    .then(response => {
      if (response) {
        /* const { accessToken } = response.data
        if (accessToken) {
          store.set('accessToken', accessToken)
        } */
        return response.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function forgotPassword(email) {
  return apiClient
    .post('/auth/forgot', {
      email,
    })
    .then(response => {
      if (response) {
        /* const { accessToken } = response.data
        if (accessToken) {
          store.set('accessToken', accessToken)
        } */
        return response.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function resetPassword(password, cPassword) {
  return apiClient
    .post('/auth/reset', {
      password,
      cPassword,
    })
    .then(response => {
      if (response) {
        /* const { accessToken } = response.data
        if (accessToken) {
          store.set('accessToken', accessToken)
        } */
        return response.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function activateAccount(password, firstname, lastname) {
  return apiClient
    .post('/auth/activate-account', {
      password,
      firstname,
      lastname,
    })
    .then(response => {
      if (response) {
        /* const { accessToken } = response.data
        if (accessToken) {
          store.set('accessToken', accessToken)
        } */
        return response.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function activateStaffAccount(password) {
  return apiClient
    .post('/auth/activate-staff-account', {
      password,
    })
    .then(response => {
      if (response) {
        /* const { accessToken } = response.data
        if (accessToken) {
          store.set('accessToken', accessToken)
        } */
        return response.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function currentAccount() {
  return apiClient
    .get('/user/account')
    .then(response => {
      if (response) {
        const { accessToken } = response.data
        if (accessToken) {
          store.set('accessToken', accessToken)
        }
        store.set('role', response.data.role)
        return response.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function logout(userRef) {
  return apiClient
    .post(`/auth/signout/${userRef}`)
    .then(() => {
      store.remove('accessToken')
      store.remove('refreshToken')
      store.remove('accessControl')
      store.remove('role')
      return true
    })
    .catch(err => console.log(err))
}

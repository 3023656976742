import store from 'store'
import apiClient from '../axios'

export async function createUser(body) {
  return apiClient
    .post(`/user/create-staff`, body)
    .then(response => (response && response.data ? response.data : false)) // eslint-disable-line
    .catch(error => console.log(error.message))
}

export async function getUsers({ page, pages, searchText, limit, sortOrder, sortCol }) {
  return apiClient
    .get(
      `/user?page=${
        pages >= page ? page : 1
      }&search=${searchText.trim()}&limit=${limit}&sort_order=${sortOrder}&sort_col=${sortCol}`,
    )
    .then(response => (response && response.data ? response.data : false))
    .catch(error => console.log(error))
}

export async function updateUser(body) {
  return apiClient
    .post(`/user/update/${body.userRef}`, body.data, {
      headers: body.headers,
    })
    .then(response => (response && response.data ? response.data : false)) // eslint-disable-line
    .catch(error => console.log(error))
}

export async function changePassword(body) {
  return apiClient
    .post(`/user/update-password`, body)
    .then(response => (response && response.data ? response.data : false)) // eslint-disable-line
    .catch(error => console.log(error))
}

export async function activateUser(userRef) {
  return apiClient
    .post(`/user/activate/${userRef}`)
    .then(response => (response && response.data ? response.data : false)) // eslint-disable-line
    .catch(error => console.log(error))
}

export async function deactivateUser(userRef) {
  return apiClient
    .post(`/user/deactivate/${userRef}`)
    .then(response => (response && response.data ? response.data : false)) // eslint-disable-line
    .catch(error => console.log(error))
}

export async function assignRole(body) {
  return apiClient
    .post(`/user/assign-role/${body.staffRef}`, body)
    .then(response => (response && response.data ? response.data : false)) // eslint-disable-line
    .catch(error => console.log(error))
}

export async function sendActivationLink(body) {
  return apiClient
    .post('/user/send-activation-link', body)
    .then(response => (response && response.data ? response.data : false)) // eslint-disable-line
    .catch(error => console.log(error))
}

export async function getAC() {
  return apiClient
    .get(`/user/ac`)
    .then(async response => {
      if (response && response.data) {
        await new Promise(resolve => resolve(store.set('accessControl', response.data)))
        return response.data
      }
      return false
    })
    .catch(error => console.log(error))
}

import apiClient from '../axios'
/* eslint-disable */

export async function createRole(body) {
  return apiClient
    .post(`/role/create`, body)
    .then(response => (response && response.data ? response.data : false)) // eslint-disable-line
    .catch(error => console.log(error.message))
}

export async function getRoles({ page, pages, searchText, limit, sortOrder, sortCol, filterType }) {
  return apiClient
    .get(
      `/role?page=${
        pages >= page ? page : 1
      }&search=${searchText.trim()}&limit=${limit}&sort_order=${sortOrder}&sort_col=${sortCol}&filter_type=${filterType}`,
    )
    .then(response => (response && response.data ? response.data : false))
    .catch(error => console.log(error))
}

export async function getRole(roleRef) {
  return apiClient
    .get(`/role/${roleRef}`)
    .then(response => response.data)
    .catch(error => console.log(error))
}

export async function updateRole(body) {
  return apiClient
    .post(`/role/update/${body.roleRef}`, body)
    .then(response => (response && response.data ? response.data : false)) // eslint-disable-line
    .catch(error => console.log(error))
}

export async function editRolePermissions(body) {
  return apiClient
    .post(`/role/edit-permissions/${body.roleRef}`, body)
    .then(response => (response && response.data ? response.data : false)) // eslint-disable-line
    .catch(error => console.log(error))
}

export async function deleteRole(roleRef) {
  return apiClient
    .post(`/role/delete/${roleRef}`)
    .then(response => (response && response.data ? response.data : false)) // eslint-disable-line
    .catch(error => console.log(error))
}

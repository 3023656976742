import apiClient from '../axios'

export async function createBlock(body) {
  return apiClient
    .post(`/block/create`, body)
    .then(response => (response && response.data ? response.data : false)) // eslint-disable-line
    .catch(error => console.log(error.message))
}

export async function createBulkBlock(body) {
  return apiClient
    .post(`/block/create-bulk`, body)
    .then(response => (response && response.data ? response.data : false)) // eslint-disable-line
    .catch(error => console.log(error.message))
}

export async function createBulkBlockWithProperties(body) {
  return apiClient
    .post(`/block/create-bulk-with-properties`, body)
    .then(response => (response && response.data ? response.data : false)) // eslint-disable-line
    .catch(error => console.log(error.message))
}

export async function getBlocks({
  page,
  pages,
  searchText,
  limit,
  sortOrder,
  sortCol,
  filterProject,
  filterLocation,
  filterType,
}) {
  return apiClient
    .get(
      `/block?page=${
        pages >= page ? page : 1
      }&search=${searchText.trim()}&limit=${limit}&sort_order=${sortOrder}&sort_col=${sortCol}&filter_project=${filterProject}&filter_location=${filterLocation}&filter_type=${filterType}`,
    )
    .then(response => (response && response.data ? response.data : false))
    .catch(error => console.log(error))
}

export async function getBlock(blockRef) {
  return apiClient
    .get(`/block/${blockRef}`)
    .then(response => (response && response.data ? response.data : false))
    .catch(error => console.log(error))
}

export async function updateBlock(body) {
  return apiClient
    .post(`/block/update/${body.blockRef}`, body)
    .then(response => (response && response.data ? response.data : false)) // eslint-disable-line
    .catch(error => console.log(error))
}

export async function deleteBlock(blockRef) {
  return apiClient
    .post(`/block/delete/${blockRef}`)
    .then(response => (response && response.data ? response.data : false)) // eslint-disable-line
    .catch(error => console.log(error))
}

import actions from './actions'

const initialState = {
  docs: [],
  doc: {},
  page: 1,
  pages: 1,
  searchText: '',
  limit: 10,
  total: 0,
  sortOrder: 'descend',
  sortCol: 'createdAt',
  filterOwner: '',
  filterOwnerModel: '',
  filterProject: '',
  filterBlock: '',
  filterCategory: '',
  filterSubCategory: '',
  filterStatus: '',
  filterInstallmentDue: false,
  // filterExpenditures: [],
  totalPayment: 0,
  totalAmount: 0,
  totalBalance: 0,
  paymntDocs: [],
  paymentsDrawerState: false,
  createPaymentModalState: false,
  modalState: false,
  loadingDoc: false,
  loadingDocs: false,
  loadingAction: false,
}

export default function expenditureReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload }

    default:
      return state
  }
}

import { eventChannel /* channel */ /* delay */ } from 'redux-saga'
import {
  all,
  put,
  call,
  takeEvery,
  debounce,
  select,
  take,
  fork /* , race */,
  cancel,
} from 'redux-saga/effects'
import store from 'store'
import socketIOClient from 'socket.io-client'
import { notification } from 'antd'
import {
  // getMeetings,
  addMeetingAgenda,
  updateMeetingAgenda,
  deleteMeetingAgenda,
  addMeetingAgendaAttachment,
  deleteMeetingAgendaAttachment,
  addMeetingAgendaTask,
  createMeeting,
  getParticipant,
  inviteParticipants,
} from 'services/api/meeting'
import actions from './actions'

let socketConnection

export function* SET_DOCS() {
  /* yield put({
    type: 'meeting/SET_STATE',
    payload: {
      loadingDocs: true,
    },
  })

  const {
    page,
    pages: initalPages,
    searchText,
    limit,
    sortOrder,
    sortCol,
    // overwriteDocs,
    // docs: initialDocs,
  } = yield select(state => state.meeting)

  const { docs, totalDocs: total, totalPages: pages } = yield call(getMeetings, {
    page,
    pages: initalPages,
    searchText,
    limit,
    sortOrder,
    sortCol,
  })

  yield put({
    type: 'meeting/SET_STATE',
    payload: {
      docs,
      total,
      pages,
    },
  })

  yield put({
    type: 'meeting/SET_STATE',
    payload: {
      loadingDocs: false,
    },
  }) */
}

// wrapping function for socket.on
const connect = () => {
  socketConnection = socketIOClient('/meeting', {
    auth: {
      refreshToken: store.get('refreshToken'),
      accessToken: store.get('accessToken'),
    },
  })
  return new Promise(resolve => {
    socketConnection.on('connect', () => {
      return resolve(socketConnection)
    })
  })
}

/* const reconnect = () => {
  socketConnection = socketIOClient('', {
    auth: {
      refreshToken: store.get('refreshToken'),
      accessToken: store.get('accessToken'),
    },
  })
  return new Promise(resolve => {
    socketConnection.on('reconnect', () => {
      resolve(socketConnection)
    })
  })
} */

// This is how a channel is created
export function subscribeToMeeting({ socket, props }) {
  const { id } = props
  socket.emit('get_meeting', { id })
  return eventChannel(emit => {
    const handler = data => {
      // console.log(data)
      if (data) {
        emit(data)
      } else {
        emit({})
      }
    }

    socket.on('meeting', handler)

    return () => {
      socket.off('meeting', handler)
    }
  })
}

export function subscribeToMeetingWithFilter({ socket, props }) {
  const { id, agendaSearchText } = props
  socket.emit('get_meeting_with_filter', { id, agendaSearchText })
  return eventChannel(emit => {
    const handler = data => {
      // console.log(data)
      if (data) {
        emit(data)
      } else {
        emit({})
      }
    }

    socket.on('meeting_with_filter', handler)

    return () => {
      socket.off('meeting_with_filter', handler)
    }
  })
}

function* read({ socket, id }) {
  const channel = yield call(subscribeToMeeting, { socket, props: { id } })

  while (true) {
    const data = yield take(channel)
    const { agendaSearchText } = yield select(state => state.meeting)
    // console.log(agendaSearchText)
    if (!agendaSearchText) {
      yield put({
        type: 'meeting/SET_STATE',
        payload: {
          doc: data,
          loadingDoc: false,
        },
      })
    }
  }
}

function* read2({ socket, id }) {
  const { agendaSearchText } = yield select(state => state.meeting)
  const channelWithFilter = yield call(subscribeToMeetingWithFilter, {
    socket,
    props: { id, agendaSearchText },
  })

  while (true) {
    const data = yield take(channelWithFilter)
    const { agendaSearchText: agendaSearchTextt } = yield select(state => state.meeting)
    if (agendaSearchTextt) {
      yield put({
        type: 'meeting/SET_STATE',
        payload: {
          doc: data,
          loadingDoc: false,
        },
      })
    }
  }
}

function* readFilteredMeeting({ socket }) {
  while (true) {
    yield take('meeting/READ_FILTERED_MEETING')
    const { doc } = yield select(state => state.meeting)

    // eslint-disable-next-line
    yield fork(read2, { socket, id: doc._id })
    // eslint-disable-next-line
    yield fork(read, { socket, id: doc._id })
  }
}

function* callReadFilteredMeeting() {
  yield put({
    type: 'meeting/READ_FILTERED_MEETING',
    payload: {},
  })
}

export function subscribeToMeetings({ socket, dataQuery }) {
  socket.emit('get_meetings', dataQuery)
  return eventChannel(emit => {
    const handler = data => {
      emit(data)
    }

    socket.on('meetings', handler)
    // socket.on('error', handler)

    return () => {
      socket.off('meetings', handler)
    }
  })
}

function* readMeetings({ socket }) {
  const {
    page,
    pages: initalPages,
    searchText,
    limit,
    sortOrder,
    sortCol,
    meetingsFilterType,
    meetingsDateStartFilter,
    meetingsDateEndFilter,
    // docs: initialDocs,
  } = yield select(state => state.meeting)

  const channel = yield call(subscribeToMeetings, {
    socket,
    dataQuery: {
      page,
      pages: initalPages,
      searchText,
      limit,
      sortOrder,
      sortCol,
      meetingsDateStartFilter,
      meetingsDateEndFilter,
      meetingsFilterType,
    },
  })

  /* yield put({
    type: 'meeting/SET_STATE',
    payload: {
      loadingDocs: true,
      docs: [],
    },
  }) */

  while (true) {
    const data = yield take(channel)
    const { docs, totalDocs: total, totalPages: pages } = data

    yield put({
      type: 'meeting/SET_STATE',
      payload: {
        docs,
        total,
        pages,
        loadingDocs: false,
      },
    })
  }
}

function* reFetchMeetingsio({ socket }) {
  while (true) {
    yield take('meeting/RE_FETCH_MEETINGS_IO')

    const task = yield fork(readMeetings, { socket })
    yield cancel(task)
  }
}

function* SET_MEETINGS_FILTER({ payload }) {
  yield put({
    type: 'meeting/SET_STATE',
    payload,
  })

  if (payload.meetingsFilterType === 'today') {
    yield put({
      type: 'meeting/RE_FETCH_MEETINGS_IO',
      payload: {},
    })
  }
}

export function subscribeToTasks({ socket, dataQuery }) {
  socket.emit('get_tasks', dataQuery)
  return eventChannel(emit => {
    const handler = data => {
      emit(data)
    }

    socket.on('tasks', handler)

    return () => {
      socket.off('tasks', handler)
    }
  })
}

function* readTasks({ socket }) {
  const { tasksFilter, participant, doc } = yield select(state => state.meeting)

  const channel = yield call(subscribeToTasks, {
    socket,
    dataQuery: {
      filter_status: tasksFilter,
      // eslint-disable-next-line
      filter_assignedTo: participant._id,
      // eslint-disable-next-line
      meetingRef: doc._id,
    },
  })

  while (true) {
    const data = yield take(channel)

    const { docs } = data

    // console.log(data)

    yield put({
      type: 'meeting/SET_STATE',
      payload: {
        tasks: docs,
      },
    })
  }
}

/* const listenConnectSaga = function*() {
  while (true) {
    yield call(reconnect)
    // yield put({type: SERVER_ON});
  }
} */

/* eslint-disable */
function* createNewMeeting({ socket }) {
  let wrapPayload, response
  while (true) {
    wrapPayload = yield take('meeting/CREATE_IO')

    yield put({
      type: 'meeting/SET_STATE',
      payload: { loadingAction: true },
    })

    socket.emit('create_meeting', wrapPayload.payload)

    try {
      response = yield new Promise((resolve, reject) => {
        socket.on('refresh_meetings', () => resolve(true))
        socket.on('error', error => reject(error))
      })
    } catch (error) {
      console.log(error)
      notification.error({ message: 'Error', description: error.message })
    }

    if (response) {
      wrapPayload.payload.resetForm()
      notification.success({ message: 'Success', description: 'Meeting created' })
      yield put({
        type: 'meeting/SET_STATE',
        payload: { isModalOpen: false },
      })
      const task = yield fork(readMeetings, { socket })
      yield cancel(task)
    }

    yield put({
      type: 'meeting/SET_STATE',
      payload: { loadingAction: false },
    })
  }
}

function* addNewMeetingAgenda({ socket }) {
  let wrapPayload
  while (true) {
    wrapPayload = yield take('meeting/ADD_AGENDA_IO')
    socket.emit('add_meeting_agenda', wrapPayload.payload)

    const { doc } = yield select(state => state.meeting)
    // console.log(doc)

    yield new Promise(resolve => {
      socket.on('refresh_meeting', () => resolve(true))
    })

    const task = yield fork(read, { socket, id: doc._id })
    yield cancel(task)
    const task2 = yield fork(read2, { socket, id: doc._id })
    yield cancel(task2)
  }
}

function* updateMeetingAgendasio({ socket }) {
  let wrapPayload
  while (true) {
    wrapPayload = yield take('meeting/UPDATE_AGENDAS_IO')
    socket.emit('update_many_meeting_agenda', wrapPayload.payload)

    const { doc } = yield select(state => state.meeting)
    // console.log(doc)

    yield new Promise(resolve => {
      socket.on('refresh_meeting', () => resolve(true))
    })

    const task = yield fork(read, { socket, id: doc._id })
    yield cancel(task)
    const task2 = yield fork(read2, { socket, id: doc._id })
    yield cancel(task2)
  }
}

function* updateMeetingAgendaio({ socket }) {
  let wrapPayload
  while (true) {
    wrapPayload = yield take('meeting/UPDATE_AGENDA_IO')
    socket.emit('update_meeting_agenda', wrapPayload.payload)

    const { doc } = yield select(state => state.meeting)
    // console.log(doc)

    yield new Promise(resolve => {
      socket.on('refresh_meeting', () => resolve(true))
    })

    const task = yield fork(read, { socket, id: doc._id })
    yield cancel(task)
    const task2 = yield fork(read2, { socket, id: doc._id })
    yield cancel(task2)
  }
}

function* updateMeetingParticipantio({ socket }) {
  let wrapPayload
  while (true) {
    wrapPayload = yield take('meeting/UPDATE_PARTICIPANT_IO')
    socket.emit('update_meeting_participant', wrapPayload.payload)

    const { doc } = yield select(state => state.meeting)

    yield new Promise(resolve => {
      socket.on('refresh_meeting', () => resolve(true))
    })

    const task = yield fork(read, { socket, id: doc._id })
    yield cancel(task)
    const task2 = yield fork(read2, { socket, id: doc._id })
    yield cancel(task2)
  }
}

function* updateMeetingAgendaTaskio({ socket }) {
  let wrapPayload, response
  while (true) {
    wrapPayload = yield take('meeting/UPDATE_AGENDA_TASK_IO')
    socket.emit('update_meeting_agenda_task', wrapPayload.payload)

    try {
      response = yield new Promise((resolve, reject) => {
        socket.on('refresh_meeting', () => resolve(true))
        socket.on('error', error => reject(error))
      })
    } catch (error) {
      console.log(error)
      notification.error({
        message: 'Error',
        description: error.message ? error.message : error.toString(),
      })
    }

    const taskAlpha = yield fork(readTasks, { socket })
    yield cancel(taskAlpha)
    const task2 = yield fork(read2, { socket, id: wrapPayload.payload.meetingRef })
    yield cancel(task2)
    const task = yield fork(read, { socket, id: wrapPayload.payload.meetingRef })
    yield cancel(task)
  }
}

function* completeMeetingAgendaTaskio({ socket }) {
  let wrapPayload, response
  while (true) {
    wrapPayload = yield take('meeting/COMPLETE_AGENDA_TASK_IO')

    yield put({
      type: 'meeting/SET_STATE',
      payload: { loadingTaskAction: true },
    })

    socket.emit('complete_meeting_agenda_task', wrapPayload.payload)

    try {
      response = yield new Promise((resolve, reject) => {
        socket.on('refresh_meeting', () => resolve(true))
        socket.on('error', error => reject(error))
      })
    } catch (error) {
      console.log(error)
      notification.error({ message: 'Error', description: error.message })
    }

    if (response) {
      notification.success({ message: 'Success', description: 'Meeting created' })
      if (wrapPayload.payload.resetForm) wrapPayload.payload.resetForm()
      if (wrapPayload.payload.setModalState) wrapPayload.payload.setModalState(false)

      const taskAlpha = yield fork(readTasks, { socket })
      yield cancel(taskAlpha)
      const task2 = yield fork(read2, { socket, id: wrapPayload.payload.meetingRef })
      yield cancel(task2)
      const task = yield fork(read, { socket, id: wrapPayload.payload.meetingRef })
      yield cancel(task)
    }

    yield put({
      type: 'meeting/SET_STATE',
      payload: { loadingTaskAction: false },
    })
  }
}

function* deleteMeetingAgendaio({ socket }) {
  let wrapPayload
  while (true) {
    wrapPayload = yield take('meeting/DELETE_AGENDA_IO')
    socket.emit('delete_meeting_agenda', wrapPayload.payload)

    const { doc } = yield select(state => state.meeting)
    // console.log(doc)

    yield new Promise(resolve => {
      socket.on('refresh_meeting', () => resolve(true))
    })

    const task = yield fork(read, { socket, id: doc._id })
    yield cancel(task)
    const task2 = yield fork(read2, { socket, id: doc._id })
    yield cancel(task2)
  }
}

function* uploadMeetingAgendaAttachmentio({ socket }) {
  let wrapPayload
  while (true) {
    wrapPayload = yield take('meeting/UPLOAD_AGENDA_ATTACHMENT_IO')
    socket.emit('add_meeting_agenda_attachment', wrapPayload.payload)

    const { doc } = yield select(state => state.meeting)
    // console.log(doc)
    yield new Promise(resolve => {
      socket.on('refresh_meeting', () => resolve(true))
    })
    // call onSuccess
    wrapPayload.payload.onSuccess('ok')
    wrapPayload.payload.finishUpload()

    const task = yield fork(read, { socket, id: doc._id })
    yield cancel(task)
    const task2 = yield fork(read2, { socket, id: doc._id })
    yield cancel(task2)
  }
}

function* deleteMeetingAgendaAttachmentio({ socket }) {
  let wrapPayload
  while (true) {
    wrapPayload = yield take('meeting/DELETE_AGENDA_ATTACHMENT_IO')
    socket.emit('delete_meeting_agenda_attachment', wrapPayload.payload)

    const { doc } = yield select(state => state.meeting)
    // console.log(doc)
    yield new Promise(resolve => {
      socket.on('refresh_meeting', () => resolve(true))
    })
    const task = yield fork(read, { socket, id: doc._id })
    yield cancel(task)
    const task2 = yield fork(read2, { socket, id: doc._id })
    yield cancel(task2)
  }
}

function* addMeetingAgendaTaskio({ socket }) {
  let wrapPayload, response
  while (true) {
    wrapPayload = yield take('meeting/ADD_AGENDA_TASK_IO')
    socket.emit('add_meeting_agenda_task', wrapPayload.payload)
    // Set loadingAction state to true
    yield put({
      type: 'meeting/SET_STATE',
      payload: { loadingAction: true },
    })
    const { doc } = yield select(state => state.meeting)

    try {
      response = yield new Promise((resolve, reject) => {
        socket.on('refresh_meeting', () => resolve(true))
        socket.on('error', error => reject(error))
      })
    } catch (error) {
      console.log(error)
      notification.error({ message: 'Error', description: error.message })
    }

    if (response) {
      wrapPayload.payload.resetForm()
      notification.success({ message: 'Success', description: 'Task created' })
      yield put({
        type: 'meeting/SET_STATE',
        payload: { isTaskModalOpen: false, activeTaskAgenda: '' },
      })
      const task = yield fork(read, { socket, id: doc._id })
      yield cancel(task)
      const task2 = yield fork(read2, { socket, id: doc._id })
      yield cancel(task2)
    }

    yield put({
      type: 'meeting/SET_STATE',
      payload: { loadingAction: false },
    })
  }
}

export function* flow({ payload }) {
  // yield take('SET_DOC_IO')
  const { location } = yield select(state => state.router)
  // const { agendaSearchText } = yield select(state => state.meeting)
  const id = location.pathname.split('/')[2] || ''
  const socket = /* socketConnection ? socketConnection : */ yield call(connect)
  // yield fork(listenConnectSaga)
  yield fork(readMeetings, { socket })
  yield fork(read2, { socket, id })
  yield fork(read, { socket, id })
  yield fork(SET_TASKS, { socket })
  yield fork(createNewMeeting, { socket })
  yield fork(addNewMeetingAgenda, { socket })
  yield fork(deleteMeetingAgendaio, { socket })
  yield fork(updateMeetingAgendasio, { socket })
  yield fork(updateMeetingAgendaio, { socket })
  yield fork(updateMeetingParticipantio, { socket })
  yield fork(updateMeetingAgendaTaskio, { socket })
  yield fork(completeMeetingAgendaTaskio, { socket })
  yield fork(uploadMeetingAgendaAttachmentio, { socket })
  yield fork(deleteMeetingAgendaAttachmentio, { socket })
  yield fork(addMeetingAgendaTaskio, { socket })
  yield fork(readFilteredMeeting, { socket })
  yield fork(reFetchMeetingsio, { socket })
}
/* eslint-disable */

export function DISCONNECT_DOC() {
  /* const { isOnline, doc } = yield select(state => state.meeting)
  const { _id } = yield select(state => state.user)
  if (isOnline === true) {
    yield put({
      type: 'meeting/SET_STATE',
      payload: { isOnline: false },
    })
    yield put({
      type: 'meeting/UPDATE_PARTICIPANT_IO',
      payload: { online: 'false', meetingRef: doc._id, userRef: _id },
    })
    yield new Promise(resolve => {
      socketConnection.on('refresh_meeting', () => resolve(true))
    })
    const task = yield fork(read, { socket: socketConnection, id: doc._id })
    yield cancel(task)
  } */

  if (socketConnection) {
    socketConnection.close()
    // return (socketConnection = undefined)
  }
}

export function* OPEN_MEETING_SOCKET_CONNECTION({ payload }) {
  yield fork(flow, { payload })
}

export function* SET_DOC({ payload }) {
  yield fork(flow, { payload })

  /* yield put({
    type: 'meeting/SET_STATE',
    payload: {
      loadingDoc: true,
    },
  }) */

  // connect to the server
  // const socket = yield call(connect)

  // then create a socket channel
  // const socketChannel = yield call(createSocketChannel, socket)

  // then put the new data into the reducer
  /* while (true) {
    const data = yield take(socketChannel)
    yield put({
      type: 'meeting/SET_STATE',
      payload: {
        doc: data,
      },
    })
  } */

  // const doc = yield call(getMeeting, id)
  // yield call(socket.emit, 'get_meeting', { id })

  /* if (doc) {
    yield put({
      type: 'meeting/SET_STATE',
      payload: {
        doc,
      },
    })
  } */

  // socket.emit('get_meeting', { id })

  /* socket.on('meeting', data =>
    put({
      type: 'meeting/SET_STATE',
      payload: {
        doc: data,
      },
    }),
  ) */

  /* yield put({
    type: 'meeting/SET_STATE',
    payload: {
      loadingDoc: false,
    },
  }) */
}

export function* SET_TASKS({ socket }) {
  let wrapPayload
  while (true) {
    wrapPayload = yield take('meeting/SET_MEETING_TASKS_IO')
    // socket.emit('add_meeting_agenda', wrapPayload.payload)

    // const { doc } = yield select(state => state.meeting)
    // console.log(doc)

    /* yield new Promise(resolve => {
      socket.on('refresh_meeting', () => resolve(true))
    }) */

    const task = yield fork(readTasks, { socket })
    // yield cancel(task)
  }
}

export function* SET_TASKS_FILTER({ payload }) {
  const { filter } = payload

  yield put({
    type: 'meeting/SET_STATE',
    payload: {
      tasksFilter: filter,
    },
  })

  yield put({
    type: 'meeting/SET_MEETING_TASKS_IO',
    payload: {},
  })

  // yield fork(SET_TASKS, { socket: socketConnection })
}

export function* SET_PARTICIPANT({ payload }) {
  /* eslint-disable */

  /* yield put({
    type: 'participant/SET_STATE',
    payload: {
      loadingDoc: true,
    },
  }) */

  const doc = yield call(getParticipant, payload.meetingRef)

  if (doc) {
    yield put({
      type: 'meeting/SET_STATE',
      payload: {
        participant: doc,
      },
    })
  }
  /* eslint-disable */

  /* yield put({
    type: 'participant/SET_STATE',
    payload: {
      loadingDoc: false,
    },
  }) */
}

export function* SET_CURRENT_PAGE({ payload }) {
  const { page } = payload

  yield put({
    type: 'meeting/SET_STATE',
    payload: {
      page,
    },
  })

  yield call(SET_DOCS)
}

export function* SET_LIMIT({ payload }) {
  const { limit } = payload

  yield put({
    type: 'meeting/SET_STATE',
    payload: {
      limit,
    },
  })

  yield call(SET_DOCS)
}

export function* SET_SORTING({ payload }) {
  const { sortOrder, sortCol } = payload

  yield put({
    type: 'meeting/SET_STATE',
    payload: {
      sortOrder,
      sortCol,
    },
  })

  yield call(SET_DOCS)
}

export function* SET_SEARCH_TEXT({ payload }) {
  const { searchText } = payload

  yield put({
    type: 'meeting/SET_STATE',
    payload: {
      searchText,
      page: 1,
    },
  })
}

export function* SET_AGENDAS_SEARCH_TEXT({ payload }) {
  const { agendaSearchText } = payload

  yield put({
    type: 'meeting/SET_STATE',
    payload: {
      agendaSearchText,
    },
  })
}

export function* INVITE_PARTICIPANTS({ payload }) {
  const { meetingRef, participants, resetForm } = payload

  yield put({
    type: 'meeting/SET_STATE',
    payload: {
      loadingInviteAction: true,
    },
  })

  const success = yield call(inviteParticipants, {
    meetingRef,
    participants,
  })

  if (success) {
    notification.success({
      message: 'Success',
      description: 'Success',
    })

    resetForm()
  }

  yield put({
    type: 'meeting/SET_STATE',
    payload: {
      loadingInviteAction: false,
    },
  })
}

export function* CREATE({ payload }) {
  const {
    title,
    description,
    // startDate,
    startTime,
    endTime,
    venue,
    participants,
    // duration,
    resetForm,
  } = payload

  // console.log(payload)

  yield put({
    type: 'meeting/SET_STATE',
    payload: {
      loadingAction: true,
    },
  })

  const success = yield call(createMeeting, {
    title,
    description,
    // startDate,
    startTime,
    endTime,
    venue,
    participants,
  })

  if (success) {
    notification.success({
      message: 'Success',
      description: 'Success',
    })

    if (resetForm) resetForm()
    if (resetStates) resetStates()
  }

  yield put({
    type: 'meeting/SET_STATE',
    payload: {
      loadingAction: false,
    },
  })
}

export function* ADD_AGENDA({ payload }) {
  const { meetingRef, title } = payload

  yield put({
    type: 'meeting/SET_STATE',
    payload: {
      loadingAction: true,
    },
  })

  const success = yield call(addMeetingAgenda, {
    title,
    meetingRef,
  })

  if (success) {
    notification.success({
      message: 'Success',
      description: 'Success',
    })
  }

  yield put({
    type: 'meeting/SET_STATE',
    payload: {
      loadingAction: false,
    },
  })
}

export function* ADD_AGENDA_ATTACHMENT({ payload }) {
  const { formData } = payload

  yield put({
    type: 'meeting/SET_STATE',
    payload: {
      loadingAction: true,
    },
  })

  const success = yield call(addMeetingAgendaAttachment, {
    formData,
  })

  if (success) {
    notification.success({
      message: 'Success',
      description: 'Success',
    })
  }

  yield put({
    type: 'meeting/SET_STATE',
    payload: {
      loadingAction: false,
    },
  })
}

export function* ADD_AGENDA_TASK({ payload }) {
  const { formData } = payload

  yield put({
    type: 'meeting/SET_STATE',
    payload: {
      loadingAction: true,
    },
  })

  const success = yield call(addMeetingAgendaTask, {
    formData,
  })

  if (success) {
    notification.success({
      message: 'Success',
      description: 'Success',
    })
  }

  yield put({
    type: 'meeting/SET_STATE',
    payload: {
      loadingAction: false,
    },
  })
}

export function* UPDATE_AGENDA({ payload }) {
  const { agendaRef, title, description, minutes, item, recurring, closed } = payload

  yield put({
    type: 'meeting/SET_STATE',
    payload: {
      loadingAction: true,
    },
  })

  const success = yield call(updateMeetingAgenda, {
    title,
    agendaRef,
    description,
    minutes,
    item,
    recurring,
    closed,
  })

  if (success) {
    notification.success({
      message: 'Success',
      description: 'Success',
    })
  }

  yield put({
    type: 'meeting/SET_STATE',
    payload: {
      loadingAction: false,
    },
  })
}

export function* DELETE_AGENDA({ payload }) {
  const { agendaRef } = payload

  yield put({
    type: 'meeting/SET_STATE',
    payload: {
      loadingAction: true,
    },
  })

  const success = yield call(deleteMeetingAgenda, {
    agendaRef,
  })

  if (success) {
    notification.success({
      message: 'Success',
      description: 'Success',
    })
  }

  yield put({
    type: 'meeting/SET_STATE',
    payload: {
      loadingAction: false,
    },
  })
}

export function* DELETE_AGENDA_ATTACHMENT({ payload }) {
  const { attachmentRef } = payload

  yield put({
    type: 'meeting/SET_STATE',
    payload: {
      loadingAction: true,
    },
  })

  const success = yield call(deleteMeetingAgendaAttachment, {
    attachmentRef,
  })

  if (success) {
    notification.success({
      message: 'Success',
      description: 'Success',
    })
  }

  yield put({
    type: 'meeting/SET_STATE',
    payload: {
      loadingAction: false,
    },
  })
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.SET_DOCS, SET_DOCS),
    // takeEvery('SET_MEETING_TASKS_IO', SET_TASKS),

    takeEvery(actions.SET_DOC, SET_DOC),
    takeEvery(actions.OPEN_MEETING_SOCKET_CONNECTION, OPEN_MEETING_SOCKET_CONNECTION),
    takeEvery('meeting/SET_PARTICIPANT', SET_PARTICIPANT),
    takeEvery('meeting/INVITE_PARTICIPANTS', INVITE_PARTICIPANTS),
    takeEvery(actions.DISCONNECT_DOC, DISCONNECT_DOC),
    takeEvery(actions.SET_CURRENT_PAGE, SET_CURRENT_PAGE),
    takeEvery(actions.SET_LIMIT, SET_LIMIT),
    takeEvery(actions.SET_SORTING, SET_SORTING),
    takeEvery(actions.SET_AGENDAS_SEARCH_TEXT, SET_AGENDAS_SEARCH_TEXT),
    debounce(500, actions.SET_AGENDAS_SEARCH_TEXT, callReadFilteredMeeting),
    takeEvery(actions.SET_SEARCH_TEXT, SET_SEARCH_TEXT),
    debounce(500, actions.SET_SEARCH_TEXT, SET_DOCS),
    takeEvery('meeting/SET_MEETINGS_FILTER', SET_MEETINGS_FILTER),

    takeEvery('meeting/SET_TASKS_FILTER', SET_TASKS_FILTER),

    takeEvery(actions.CREATE, CREATE),
    takeEvery(actions.ADD_AGENDA, ADD_AGENDA),
    takeEvery(actions.ADD_AGENDA_ATTACHMENT, ADD_AGENDA_ATTACHMENT),
    takeEvery(actions.ADD_AGENDA_TASK, ADD_AGENDA_TASK),
    takeEvery(actions.UPDATE_AGENDA, UPDATE_AGENDA),
    takeEvery(actions.DELETE_AGENDA, DELETE_AGENDA),
    takeEvery(actions.DELETE_AGENDA_ATTACHMENT, DELETE_AGENDA_ATTACHMENT),

    // GET_ACTIVE(), // run once on app load to fetch registration active meeting
    // SET_CURRENT_PAGE({ payload: { page: 2 } }),
  ])
}

import apiClient from '../axios'

export async function createCategory(body) {
  return apiClient
    .post(`/category/create`, body)
    .then(response => (response && response.data ? response.data : false)) // eslint-disable-line
    .catch(error => console.log(error.message))
}

export async function getCategories({
  page,
  pages,
  searchText,
  limit,
  sortOrder,
  sortCol,
  filterParentCategory,
  filterType,
}) {
  return apiClient
    .get(
      `/category?page=${
        pages >= page ? page : 1
      }&search=${searchText.trim()}&limit=${limit}&sort_order=${sortOrder}&sort_col=${sortCol}&filter_parentCategory=${filterParentCategory}&filter_type=${filterType}`,
    )
    .then(response => (response && response.data ? response.data : false))
    .catch(error => console.log(error))
}

export async function getCategory(categoryRef) {
  return apiClient
    .get(`/category/${categoryRef}`)
    .then(response => (response && response.data ? response.data : false))
    .catch(error => console.log(error))
}

export async function updateCategory(body) {
  return apiClient
    .post(`/category/update/${body.categoryRef}`, body)
    .then(response => (response && response.data ? response.data : false)) // eslint-disable-line
    .catch(error => console.log(error))
}

export async function deleteCategory(categoryRef) {
  return apiClient
    .post(`/category/delete/${categoryRef}`)
    .then(response => (response && response.data ? response.data : false)) // eslint-disable-line
    .catch(error => console.log(error))
}

import apiClient from '../axios'

export async function createProject(body) {
  return apiClient
    .post(`/project/create`, body)
    .then(response => (response && response.data ? response.data : false)) // eslint-disable-line
    .catch(error => console.log(error.message))
}

export async function getProjects({
  page,
  pages,
  searchText,
  limit,
  sortOrder,
  sortCol,
  filterSite,
  filterFacilities,
}) {
  return apiClient
    .get(
      `/project?page=${
        pages >= page ? page : 1
      }&search=${searchText.trim()}&limit=${limit}&sort_order=${sortOrder}&sort_col=${sortCol}&filter_site=${filterSite}&filter_facilities=${filterFacilities}`,
    )
    .then(response => (response && response.data ? response.data : false))
    .catch(error => console.log(error))
}

export async function getProject(projectRef) {
  return apiClient
    .get(`/project/${projectRef}`)
    .then(response => (response && response.data ? response.data : false))
    .catch(error => console.log(error))
}

export async function updateProject(body) {
  return apiClient
    .post(`/project/update/${body.projectRef}`, body)
    .then(response => (response && response.data ? response.data : false)) // eslint-disable-line
    .catch(error => console.log(error))
}

export async function deleteProject(projectRef) {
  return apiClient
    .post(`/project/delete/${projectRef}`)
    .then(response => (response && response.data ? response.data : false)) // eslint-disable-line
    .catch(error => console.log(error))
}

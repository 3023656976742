const actions = {
  CREATE: 'role/CREATE',
  UPDATE: 'role/UPDATE',
  EDIT_PERMISSION: 'role/EDIT_PERMISSION',
  DELETE: 'role/DELETE',
  SET_STATE: 'role/SET_STATE',
  SET_DOCS: 'role/SET_DOCS',
  SET_DOC: 'role/SET_DOC',
  SET_CURRENT_PAGE: 'role/SET_CURRENT_PAGE',
  SET_LIMIT: 'role/SET_LIMIT',
  SET_SORTING: 'role/SET_SORTING',
  SET_SEARCH_TEXT: 'role/SET_SEARCH_TEXT',
  SET_FILTERS: 'role/SET_FILTERS',
}

export default actions

import { all, put, call, takeEvery, select, debounce } from 'redux-saga/effects'
import { history } from 'index'
import {
  createAsset,
  getAsset,
  getAssets,
  updateAsset,
  updateStatus,
  assignAssetToStaff,
  returnAssignedAsset,
  createAssetExpenditure,
  deleteAssetExpenditure,
  deleteAsset,
  addExpenditurePayment,
  completeAssetExpenditure,
  cancelAssetExpenditure,
  uploadAssetImages,
  uploadAssetDocuments,
} from 'services/api/asset'
import { notification } from 'antd'
import actions from './actions'

export function* CREATE({ payload }) {
  const {
    /* description,
    category,
    subCategory,
    department,
    purchasedAt,
    vendor,
    brand,
    model,
    serial,
    location,
    site,
    cost,
    residualValue,
    usefulLifeSpan,
    devaluationInterval,
    devaluationType, */
    resetForm,
    resetStates,
  } = payload

  yield put({
    type: 'asset/SET_STATE',
    payload: {
      loadingAction: true,
    },
  })

  const success = yield call(createAsset, payload)

  if (success) {
    notification.success({
      message: 'Success',
      description: 'Asset has been added',
    })

    if (resetForm) resetForm()
    if (resetStates) resetStates()
  }

  yield put({
    type: 'asset/SET_STATE',
    payload: {
      loadingAction: false,
    },
  })
}

export function* SET_DOCS() {
  yield put({
    type: 'asset/SET_STATE',
    payload: {
      loadingDocs: true,
    },
  })

  // Get queries
  const {
    limit,
    searchText,
    page,
    pages,
    sortOrder,
    sortCol,
    filterSite,
    filterLocation,
    filterCategory,
    filterSubCategory,
    filterDepartment,
    filterVendor,
    filterStatus,
    filterAssignedTo,
    filterDateRangeType,
    filterDateRangeStart,
    filterDateRangeEnd,
    filterExpenditureDue,
  } = yield select(state => state.asset)

  const success = yield call(getAssets, {
    limit,
    searchText,
    page,
    pages,
    sortOrder,
    sortCol,
    filterSite,
    filterLocation,
    filterCategory,
    filterSubCategory,
    filterDepartment,
    filterVendor,
    filterStatus,
    filterAssignedTo,
    filterDateRangeType,
    filterDateRangeStart,
    filterDateRangeEnd,
    filterExpenditureDue,
  })

  if (success) {
    const { docs, totalDocs, totalPages } = success

    yield put({
      type: 'asset/SET_STATE',
      payload: {
        docs,
        total: totalDocs,
        pages: totalPages,
      },
    })
  }

  yield put({
    type: 'asset/SET_STATE',
    payload: {
      loadingDocs: false,
    },
  })
}

export function* SET_DOC({ payload }) {
  const { assetRef } = payload

  yield put({
    type: 'asset/SET_STATE',
    payload: {
      loadingDoc: true,
    },
  })

  const doc = yield call(getAsset, assetRef)

  yield put({
    type: 'asset/SET_STATE',
    payload: {
      doc,
      loadingDoc: false,
    },
  })
}

export function* SET_CURRENT_PAGE({ payload }) {
  const { page } = payload

  yield put({
    type: 'asset/SET_STATE',
    payload: {
      page,
    },
  })

  yield call(SET_DOCS)
}

export function* SET_LIMIT({ payload }) {
  const { limit } = payload

  yield put({
    type: 'asset/SET_STATE',
    payload: {
      limit,
    },
  })

  yield call(SET_DOCS)
}

export function* SET_SORTING({ payload }) {
  const { sortOrder, sortCol } = payload

  yield put({
    type: 'asset/SET_STATE',
    payload: {
      sortOrder,
      sortCol,
    },
  })

  yield call(SET_DOCS)
}

export function* SET_SEARCH_TEXT({ payload }) {
  const { searchText } = payload

  yield put({
    type: 'asset/SET_STATE',
    payload: {
      searchText,
      page: 1,
    },
  })
}

export function* THROTTLE_SET_SEARCH_TEXT() {
  yield call(SET_DOCS)
}

export function* SET_FILTERS({ payload }) {
  const {
    filterSite,
    filterLocation,
    filterCategory,
    filterSubCategory,
    filterDepartment,
    filterVendor,
    filterStatus,
    filterAssignedTo,
    filterDateRangeType,
    filterDateRangeStart,
    filterDateRangeEnd,
    filterExpenditureDue,
  } = payload

  yield put({
    type: 'asset/SET_STATE',
    payload: {
      filterSite,
      filterLocation,
      filterCategory,
      filterSubCategory,
      filterDepartment,
      filterVendor,
      filterStatus,
      filterAssignedTo,
      filterDateRangeType,
      filterDateRangeStart,
      filterDateRangeEnd,
      filterExpenditureDue,
    },
  })

  yield call(SET_DOCS)
}

export function* UPDATE({ payload }) {
  const {
    /* description,
    category,
    subCategory,
    department,
    purchasedAt,
    vendor,
    brand,
    model,
    serial,
    location,
    site,
    assetRef, */
    /* cost,
    residualValue,
    usefulLifeSpan,
    devaluationInterval,
    devaluationType, */
    resetForm,
    resetStates,
  } = payload

  yield put({
    type: 'asset/SET_STATE',
    payload: {
      loadingAction: true,
    },
  })

  const success = yield call(updateAsset, payload)

  if (success) {
    notification.success({
      message: 'Success',
      description: 'Asset has been updated',
    })

    if (resetForm) resetForm()
    if (resetStates) resetStates()
  }

  yield put({
    type: 'asset/SET_STATE',
    payload: {
      loadingAction: false,
    },
  })
}

export function* UPDATE_STATUS({ payload }) {
  const { assetRef, status, resetForm, resetStates } = payload

  yield put({
    type: 'asset/SET_STATE',
    payload: {
      loadingAction: true,
    },
  })

  const success = yield call(updateStatus, {
    status,
    assetRef,
  })

  if (success) {
    yield call(SET_DOC, { payload: { assetRef } })
    notification.success({
      message: 'Success',
      description: 'Asset Status has been updated',
    })

    if (resetForm) resetForm()
    if (resetStates) resetStates()
  }

  yield put({
    type: 'asset/SET_STATE',
    payload: {
      loadingAction: false,
    },
  })
}

export function* UPLOAD_IMAGES({ payload }) {
  const { assetRef, headers, data, resetFileList } = payload

  yield put({
    type: 'asset/SET_STATE',
    payload: { loadingAction: true },
  })

  const success = yield call(uploadAssetImages, { headers, data, assetRef })

  if (success) {
    yield call(SET_DOC, { payload: { assetRef } })

    notification.success({
      message: 'Success',
      description: 'Asset Images have been uploaded',
    })

    if (resetFileList) resetFileList()
  }

  yield put({
    type: 'asset/SET_STATE',
    payload: {
      loadingAction: false,
    },
  })
}

export function* UPLOAD_DOCUMENTS({ payload }) {
  const { assetRef, headers, data, resetFileList } = payload

  yield put({
    type: 'asset/SET_STATE',
    payload: { loadingAction: true },
  })

  const success = yield call(uploadAssetDocuments, { headers, data, assetRef })

  if (success) {
    yield call(SET_DOC, { payload: { assetRef } })

    notification.success({
      message: 'Success',
      description: 'Asset Images have been uploaded',
    })

    if (resetFileList) resetFileList()
  }

  yield put({
    type: 'asset/SET_STATE',
    payload: {
      loadingAction: false,
    },
  })
}

export function* DELETE({ payload }) {
  const { assetRef } = payload

  yield put({
    type: 'asset/SET_STATE',
    payload: {
      loadingAction: true,
    },
  })

  const success = yield call(deleteAsset, assetRef)

  if (success) {
    yield history.push('/asset')

    notification.success({
      message: 'Success',
      description: 'Asset has been deleted',
    })
  }

  yield put({
    type: 'asset/SET_STATE',
    payload: {
      loadingAction: false,
    },
  })
}

export function* ASSIGN({ payload }) {
  const { assetRef, assignedTo, resetForm, resetStates } = payload

  yield put({
    type: 'asset/SET_STATE',
    payload: {
      loadingAction: true,
    },
  })

  const success = yield call(assignAssetToStaff, {
    assetRef,
    assignedTo,
  })

  if (success) {
    yield call(SET_DOC, { payload: { assetRef } })
    notification.success({
      message: 'Success',
      description: 'Asset has been updated',
    })

    if (resetForm) resetForm()
    if (resetStates) resetStates()
  }

  yield put({
    type: 'asset/SET_STATE',
    payload: {
      loadingAction: false,
    },
  })
}

export function* RETURN({ payload }) {
  const { assetRef, resetForm, resetStates } = payload

  yield put({
    type: 'asset/SET_STATE',
    payload: {
      loadingAction: true,
    },
  })

  const success = yield call(returnAssignedAsset, assetRef)

  if (success) {
    yield call(SET_DOC, { payload: { assetRef } })
    notification.success({
      message: 'Success',
      description: 'Asset has been updated',
    })

    if (resetForm) resetForm()
    if (resetStates) resetStates()
  }

  yield put({
    type: 'asset/SET_STATE',
    payload: {
      loadingAction: false,
    },
  })
}

export function* CREATE_ASSET_EXPENDITURE({ payload }) {
  const {
    description,
    cost,
    isRecurrent,
    interval,
    dueAt,
    startDate,
    endRecurrenceAt,
    assetRef,
    resetForm,
    resetStates,
  } = payload

  yield put({
    type: 'asset/SET_STATE',
    payload: {
      loadingAction: true,
    },
  })

  const success = yield call(createAssetExpenditure, {
    description,
    cost,
    isRecurrent,
    interval,
    dueAt,
    startDate,
    endRecurrenceAt,
    assetRef,
  })

  if (success) {
    yield call(SET_DOC, { payload: { assetRef } })

    notification.success({
      message: 'Success',
      description: 'Asset has been updated',
    })

    if (resetForm) resetForm()
    if (resetStates) resetStates()
  }

  yield put({
    type: 'asset/SET_STATE',
    payload: {
      loadingAction: false,
    },
  })
}

export function* DELETE_ASSET_EXPENDITURE({ payload }) {
  const { assetExpenditureRef } = payload

  yield put({
    type: 'asset/SET_STATE',
    payload: {
      loadingAction: true,
    },
  })

  const success = yield call(deleteAssetExpenditure, assetExpenditureRef)

  if (success) {
    notification.success({
      message: 'Success',
      description: 'Expenditure has been deleted',
    })
  }

  yield put({
    type: 'asset/SET_STATE',
    payload: {
      loadingAction: false,
    },
  })
}

export function* CREATE_EXPENDITURE_PAYMENT({ payload }) {
  const { amount, paymentDate, expenditureRef, assetRef, resetForm, resetStates } = payload

  yield put({
    type: 'asset/SET_STATE',
    payload: {
      loadingAction: true,
    },
  })

  const success = yield call(addExpenditurePayment, {
    amount,
    paymentDate,
    expenditureRef,
  })

  if (success) {
    yield call(SET_DOC, { payload: { assetRef } })
    yield put({
      type: 'asset/SET_STATE',
      payload: {
        expenditurePaymentModalState: false,
      },
    })
    notification.success({
      message: 'Success',
      description: 'Payment has been created for expenditure',
    })

    if (resetForm) resetForm()
    if (resetStates) resetStates()
  }

  yield put({
    type: 'asset/SET_STATE',
    payload: {
      loadingAction: false,
    },
  })
}

export function* COMPLETE_ASSET_EXPENDITURE({ payload }) {
  const { assetRef, assetExpenditureRef, resetForm, resetStates } = payload

  yield put({
    type: 'asset/SET_STATE',
    payload: {
      loadingAction: true,
    },
  })

  const success = yield call(completeAssetExpenditure, {
    assetRef,
    assetExpenditureRef,
  })

  if (success) {
    yield call(SET_DOC, { payload: { assetRef } })
    notification.success({
      message: 'Success',
      description: 'Expenditure status has been updated to complete',
    })

    if (resetForm) resetForm()
    if (resetStates) resetStates()
  }

  yield put({
    type: 'asset/SET_STATE',
    payload: {
      loadingAction: false,
    },
  })
}

export function* CANCEL_ASSET_EXPENDITURE({ payload }) {
  const { assetRef, assetExpenditureRef, resetForm, resetStates } = payload

  yield put({
    type: 'asset/SET_STATE',
    payload: {
      loadingAction: true,
    },
  })

  const success = yield call(cancelAssetExpenditure, {
    assetRef,
    assetExpenditureRef,
  })

  if (success) {
    yield call(SET_DOC, { payload: { assetRef } })
    notification.success({
      message: 'Success',
      description: 'Expenditure status has been updated to cancel',
    })

    if (resetForm) resetForm()
    if (resetStates) resetStates()
  }

  yield put({
    type: 'asset/SET_STATE',
    payload: {
      loadingAction: false,
    },
  })
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.CREATE, CREATE),
    takeEvery(actions.SET_DOCS, SET_DOCS),
    takeEvery(actions.SET_DOC, SET_DOC),
    takeEvery(actions.SET_CURRENT_PAGE, SET_CURRENT_PAGE),
    takeEvery(actions.SET_LIMIT, SET_LIMIT),
    takeEvery(actions.SET_SORTING, SET_SORTING),
    takeEvery(actions.SET_SEARCH_TEXT, SET_SEARCH_TEXT),
    debounce(500, actions.SET_SEARCH_TEXT, THROTTLE_SET_SEARCH_TEXT),
    takeEvery(actions.SET_FILTERS, SET_FILTERS),
    takeEvery(actions.UPDATE, UPDATE),
    takeEvery(actions.UPDATE_STATUS, UPDATE_STATUS),
    takeEvery(actions.DELETE, DELETE),
    takeEvery(actions.ASSIGN, ASSIGN),
    takeEvery(actions.RETURN, RETURN),
    takeEvery(actions.CREATE_ASSET_EXPENDITURE, CREATE_ASSET_EXPENDITURE),
    takeEvery(actions.DELETE_ASSET_EXPENDITURE, DELETE_ASSET_EXPENDITURE),
    takeEvery(actions.CREATE_EXPENDITURE_PAYMENT, CREATE_EXPENDITURE_PAYMENT),
    takeEvery(actions.COMPLETE_ASSET_EXPENDITURE, COMPLETE_ASSET_EXPENDITURE),
    takeEvery(actions.CANCEL_ASSET_EXPENDITURE, CANCEL_ASSET_EXPENDITURE),
    takeEvery(actions.UPLOAD_IMAGES, UPLOAD_IMAGES),
    takeEvery(actions.UPLOAD_DOCUMENTS, UPLOAD_DOCUMENTS),
  ])
}

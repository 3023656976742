const actions = {
  SET_STATE: 'staff/SET_STATE',
  SET_DOCS: 'staff/SET_DOCS',
  SET_CURRENT_PAGE: 'staff/SET_CURRENT_PAGE',
  SET_LIMIT: 'staff/SET_LIMIT',
  SET_SORTING: 'staff/SET_SORTING',
  SET_SEARCH_TEXT: 'staff/SET_SEARCH_TEXT',
  THROTTLE_SET_SEARCH_TEXT: 'staff/THROTTLE_SET_SEARCH_TEXT',
  ASSIGN_ROLE: 'staff/ASSIGN_ROLE',
  CREDIT_BALANCE: 'staff/CREDIT_BALANCE',
  DEBIT_BALANCE: 'staff/DEBIT_BALANCE',
  CREATE: 'staff/CREATE',
  UPDATE: 'staff/UPDATE',
  DELETE: 'staff/DELETE',
}

export default actions

import axios from 'axios'
import store from 'store'
import { notification } from 'antd'
import { history } from 'index'

const apiClient = axios.create({
  baseURL: '/api',
  // timeout: 1000,
  // headers: { 'X-Custom-Header': 'foobar' }
})

apiClient.interceptors.request.use(request => {
  const accessToken = store.get('accessToken')
  const refreshToken = store.get('refreshToken')
  if (accessToken) {
    request.headers.Authorization = `Bearer ${accessToken}`
    request.headers.accessToken = accessToken
    request.headers.refreshToken = refreshToken
  }
  return request
})

apiClient.interceptors.response.use(undefined, error => {
  // Errors handling
  const { response } = error
  const { data } = response
  if (data && !window.location.pathname.startsWith('/auth')) {
    notification.warning({
      message: data.message ? data.message : JSON.stringify(data),
    })
  }
  if (response.status === 401 && !window.location.pathname.startsWith('/auth')) {
    history.push({ pathname: '/auth/login', state: { page: window.location.pathname } })
  }
})

export default apiClient

import actions from './actions'

const initialState = {
  docs: [],
  page: 1,
  pages: 1,
  searchText: '',
  limit: 50,
  total: 0,
  sortOrder: 'descend',
  sortCol: 'createdAt',
  loadingDocs: false,
  loadingAction: false,
  modalState: false,
  updateModalState: false,
  updateUserModalState: false,
  assignRoleModalState: false,
  creditModalState: false,
  debitModalState: false,
}

export default function staffReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload }
    default:
      return state
  }
}

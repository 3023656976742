const actions = {
  CREATE: 'task/CREATE',
  UPDATE: 'task/UPDATE',
  DUPLICATE: 'task/DUPLICATE',
  UPDATE_STATUS_TO_TODO: 'task/UPDATE_STATUS_TO_TODO',
  UPDATE_STATUS_TO_IN_PROGRESS: 'task/UPDATE_STATUS_TO_IN_PROGRESS',
  UPDATE_STATUS_TO_COMPLETED: 'task/UPDATE_STATUS_TO_COMPLETED',
  UPDATE_STATUS_TO_CLOSED: 'task/UPDATE_STATUS_TO_CLOSED',
  CREATE_CHECKLIST: 'task/CREATE_CHECKLIST',
  UPDATE_CHECKLIST: 'task/UPDATE_CHECKLIST',
  RESOLVE_CHECKLIST: 'task/RESOLVE_CHECKLIST',
  UNRESOLVE_CHECKLIST: 'task/UNRESOLVE_CHECKLIST',
  DELETE_CHECKLIST: 'task/DELETE_CHECKLIST',
  CREATE_COMMENT: 'task/CREATE_COMMENT',
  UPDATE_COMMENT: 'task/UPDATE_COMMENT',
  LIKE_COMMENT: 'task/LIKE_COMMENT',
  UNLIKE_COMMENT: 'task/UNLIKE_COMMENT',
  DELETE_COMMENT: 'task/DELETE_COMMENT',
  DELETE: 'task/DELETE',
  SET_STATE: 'task/SET_STATE',
  SET_DOCS: 'task/SET_DOCS',
  SET_DOC: 'task/SET_DOC',
  SET_CURRENT_PAGE: 'task/SET_CURRENT_PAGE',
  SET_LIMIT: 'task/SET_LIMIT',
  SET_SORTING: 'task/SET_SORTING',
  SET_SEARCH_TEXT: 'task/SET_SEARCH_TEXT',
  SET_SEARCH_TEXT_IO: 'task/SET_SEARCH_TEXT_IO',
  SET_FILTERS: 'task/SET_FILTERS',
  SET_FILTERS_IO: 'task/SET_FILTERS_IO',
  SET_SOCKET: 'task/SET_SOCKET',
  OPEN_SOCKET_CONNECTION: 'task/OPEN_SOCKET_CONNECTION',
  CLOSE_SOCKET_CONNECTION: 'task/CLOSE_SOCKET_CONNECTION',
}

export default actions

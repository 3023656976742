const actions = {
  CREATE: 'lead/CREATE',
  UPDATE: 'lead/UPDATE',
  UPDATE_STATUS: 'lead/UPDATE_STATUS',
  ASSIGN: 'lead/ASSIGN',
  ADD_FOLLOW_UP: 'lead/ADD_FOLLOW_UP',
  DELETE: 'lead/DELETE',
  SET_STATE: 'lead/SET_STATE',
  SET_DOCS: 'lead/SET_DOCS',
  SET_DOC: 'lead/SET_DOC',
  SET_CURRENT_PAGE: 'lead/SET_CURRENT_PAGE',
  SET_LIMIT: 'lead/SET_LIMIT',
  SET_SORTING: 'lead/SET_SORTING',
  SET_SEARCH_TEXT: 'lead/SET_SEARCH_TEXT',
  SET_FILTERS: 'lead/SET_FILTERS',
}

export default actions

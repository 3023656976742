import apiClient from '../axios'

export async function createLocation(body) {
  return apiClient
    .post(`/location/create`, body)
    .then(response => (response && response.data ? response.data : false)) // eslint-disable-line
    .catch(error => console.log(error.message))
}

export async function getLocations({
  page,
  pages,
  searchText,
  limit,
  sortOrder,
  sortCol,
  filterSite,
}) {
  return apiClient
    .get(
      `/location?page=${
        pages >= page ? page : 1
      }&search=${searchText.trim()}&limit=${limit}&sort_order=${sortOrder}&sort_col=${sortCol}&filter_site=${filterSite}`,
    )
    .then(response => (response && response.data ? response.data : false))
    .catch(error => console.log(error))
}

export async function getLocation(locationRef) {
  return apiClient
    .get(`/location/${locationRef}`)
    .then(response => (response && response.data ? response.data : false))
    .catch(error => console.log(error))
}

export async function updateLocation(body) {
  return apiClient
    .post(`/location/update/${body.locationRef}`, body)
    .then(response => (response && response.data ? response.data : false)) // eslint-disable-line
    .catch(error => console.log(error))
}

export async function deleteLocation(locationRef) {
  return apiClient
    .post(`/location/delete/${locationRef}`)
    .then(response => (response && response.data ? response.data : false)) // eslint-disable-line
    .catch(error => console.log(error))
}

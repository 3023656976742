const actions = {
  CREATE: 'workflow/CREATE',
  UPDATE: 'workflow/UPDATE',
  CREATE_STEP: 'workflow/CREATE_STEP',
  ASSIGN_APPROVER_TO_STEP: 'workflow/ASSIGN_APPROVER_TO_STEP',
  DELETE_STEP: 'workflow/DELETE_STEP',
  DELETE: 'workflow/DELETE',
  SET_STATE: 'workflow/SET_STATE',
  SET_DOCS: 'workflow/SET_DOCS',
  SET_DOC: 'workflow/SET_DOC',
  SET_CURRENT_PAGE: 'workflow/SET_CURRENT_PAGE',
  SET_LIMIT: 'workflow/SET_LIMIT',
  SET_SORTING: 'workflow/SET_SORTING',
  SET_SEARCH_TEXT: 'workflow/SET_SEARCH_TEXT',
}

export default actions

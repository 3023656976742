const actions = {
  SET_STATE: 'meeting/SET_STATE',
  SET_DOCS: 'meeting/SET_DOCS',
  SET_DOC: 'meeting/SET_DOC',
  OPEN_MEETING_SOCKET_CONNECTION: 'meeting/OPEN_MEETING_SOCKET_CONNECTION',
  DISCONNECT_DOC: 'meeting/DISCONNECT_DOC',
  SET_ACTIVE_DOC: 'meeting/SET_ACTIVE_DOC',
  SET_CURRENT_PAGE: 'meeting/SET_CURRENT_PAGE',
  SET_LIMIT: 'meeting/SET_LIMIT',
  SET_SORTING: 'meeting/SET_SORTING',
  SET_AGENDAS_SEARCH_TEXT: 'meeting/SET_AGENDAS_SEARCH_TEXT',
  SET_SEARCH_TEXT: 'meeting/SET_SEARCH_TEXT',
  THROTTLE_SET_SEARCH_TEXT: 'meeting/THROTTLE_SET_SEARCH_TEXT',
  CREATE: 'meeting/CREATE',
  ADD_AGENDA: 'meeting/ADD_AGENDA',
  ADD_AGENDA_ATTACHMENT: 'meeting/ADD_AGENDA_ATTACHMENT',
  ADD_AGENDA_TASK: 'meeting/ADD_AGENDA_TASK',
  UPDATE_AGENDA_TASK: 'meeting/UPDATE_AGENDA_TASK',
  DELETE_AGENDA_TASK: 'meeting/DELETE_AGENDA_TASK',
  UPDATE_AGENDA: 'meeting/UPDATE_AGENDA',
  DELETE_AGENDA: 'meeting/DELETE_AGENDA',
  DELETE_AGENDA_ATTACHMENT: 'meeting/DELETE_AGENDA_ATTACHMENT',
}

export default actions

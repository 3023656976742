import store from 'store'
import actions from './actions'

const initialState = {
  docs: [],
  page: 1,
  pages: 1,
  searchText: '',
  limit: 10,
  total: 0,
  sortOrder: 'descend',
  sortCol: 'createdAt',
  _id: '',
  lastSeen: '',
  staffRef: '',
  agentRef: '',
  clientRef: '',
  guestRef: '',
  firstname: '',
  lastname: '',
  telephone: '',
  type: '',
  role: '',
  email: '',
  avatar: '',
  balance: 0,
  // Client specifics start
  referrer: null,
  referrerType: '',
  nextOfKin: null,
  reference: '',
  addresses: null,
  photo: null,
  identityProof: null,
  addressProof: null,
  totalSuccessfulPayment: 0,
  totalProperties: 0,
  totalOutstandingBalance: 0,
  // Client specifics end
  recentTransactions: [],
  recentExpenseRequests: [],
  authorized: process.env.REACT_APP_AUTHENTICATED || false, // false is default value
  loading: false,
  loadingDocs: false,
  loadingAction: false,
  ac: store.get('accessControl'),
  modalState: false,
  updateModalState: false,
  withdrawalModalState: false,
  updateState: false,
  updatePasswordState: false,
  hasToured: false,
}

export default function userReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload }
    default:
      return state
  }
}

const actions = {
  CREATE: 'plan/CREATE',
  UPDATE: 'plan/UPDATE',
  DELETE: 'plan/DELETE',
  SET_STATE: 'plan/SET_STATE',
  SET_DOCS: 'plan/SET_DOCS',
  SET_DOC: 'plan/SET_DOC',
  SET_CURRENT_PAGE: 'plan/SET_CURRENT_PAGE',
  SET_LIMIT: 'plan/SET_LIMIT',
  SET_SORTING: 'plan/SET_SORTING',
  SET_SEARCH_TEXT: 'plan/SET_SEARCH_TEXT',
}

export default actions

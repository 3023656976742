import { all, put, call, takeEvery, select, debounce } from 'redux-saga/effects'
import { history } from 'index'
import {
  createExpenseRequest,
  updateExpenseRequest,
  submitExpenseRequest,
  reviseExpenseRequest,
  approveExpenseRequest,
  rejectExpenseRequest,
  createComment,
  updateComment,
  addCommentLike,
  removeCommentLike,
  deleteComment,
  getExpenseRequests,
  getExpenseRequest,
  deleteExpenseRequest,
  openExpenseRequest,
  completeExpenseRequest,
} from 'services/api/expenseRequest'
import { notification } from 'antd'
import actions from './actions'

export function* CREATE({ payload }) {
  const { resetForm, resetStates } = payload

  yield put({
    type: 'expenseRequest/SET_STATE',
    payload: {
      loadingAction: true,
    },
  })

  const success = yield call(createExpenseRequest, payload)

  if (success) {
    notification.success({
      message: 'Success',
      description: 'New expenseRequest added',
    })

    if (resetForm) resetForm()
    if (resetStates) resetStates()

    yield put({
      type: 'user/LOAD_CURRENT_ACCOUNT',
      payload: {},
    })
  }

  yield put({
    type: 'expenseRequest/SET_STATE',
    payload: {
      loadingAction: false,
    },
  })
}

export function* SET_DOCS() {
  yield put({
    type: 'expenseRequest/SET_STATE',
    payload: {
      loadingDocs: true,
    },
  })

  // Get queries
  const {
    limit,
    searchText,
    page,
    pages,
    sortOrder,
    sortCol,
    filterType,
    filterCreatedBy,
    filterCreatedByType,
    filterStatus,
    filterDateRangeStart,
    filterDateRangeEnd,
  } = yield select(state => state.expenseRequest)

  const success = yield call(getExpenseRequests, {
    limit,
    searchText,
    page,
    pages,
    sortOrder,
    sortCol,
    filterType,
    filterCreatedBy,
    filterCreatedByType,
    filterStatus,
    filterDateRangeStart,
    filterDateRangeEnd,
  })

  if (success) {
    const { docs, totalDocs, totalPages } = success

    yield put({
      type: 'expenseRequest/SET_STATE',
      payload: {
        docs,
        total: totalDocs,
        pages: totalPages,
      },
    })
  }

  yield put({
    type: 'expenseRequest/SET_STATE',
    payload: {
      loadingDocs: false,
    },
  })
}

export function* SET_DOC({ payload }) {
  const { expenseRequestRef } = payload

  yield put({
    type: 'expenseRequest/SET_STATE',
    payload: {
      loadingDoc: true,
    },
  })

  const doc = yield call(getExpenseRequest, expenseRequestRef)

  yield put({
    type: 'expenseRequest/SET_STATE',
    payload: {
      doc,
      loadingDoc: false,
    },
  })
}

export function* SET_CURRENT_PAGE({ payload }) {
  const { page } = payload

  yield put({
    type: 'expenseRequest/SET_STATE',
    payload: {
      page,
    },
  })

  yield call(SET_DOCS)
}

export function* SET_LIMIT({ payload }) {
  const { limit } = payload

  yield put({
    type: 'expenseRequest/SET_STATE',
    payload: {
      limit,
    },
  })

  yield call(SET_DOCS)
}

export function* SET_SORTING({ payload }) {
  const { sortOrder, sortCol } = payload

  yield put({
    type: 'expenseRequest/SET_STATE',
    payload: {
      sortOrder,
      sortCol,
    },
  })

  yield call(SET_DOCS)
}

export function* SET_SEARCH_TEXT({ payload }) {
  const { searchText } = payload

  yield put({
    type: 'expenseRequest/SET_STATE',
    payload: {
      searchText,
      page: 1,
    },
  })
}

export function* THROTTLE_SET_SEARCH_TEXT() {
  yield call(SET_DOCS)
}

export function* SET_FILTERS({ payload }) {
  const {
    filterType,
    filterCreatedBy,
    filterCreatedByType,
    filterStatus,
    filterDateRangeStart,
    filterDateRangeEnd,
  } = payload

  yield put({
    type: 'expenseRequest/SET_STATE',
    payload: {
      filterType,
      filterCreatedBy,
      filterCreatedByType,
      filterStatus,
      filterDateRangeStart,
      filterDateRangeEnd,
    },
  })

  yield call(SET_DOCS)
}

export function* UPDATE({ payload }) {
  const { resetForm, resetStates } = payload

  yield put({
    type: 'expenseRequest/SET_STATE',
    payload: {
      loadingAction: true,
    },
  })

  const success = yield call(updateExpenseRequest, payload)

  if (success) {
    notification.success({
      message: 'Success',
      description: 'ExpenseRequest has been updated',
    })

    if (resetForm) resetForm()
    if (resetStates) resetStates()
  }

  yield put({
    type: 'expenseRequest/SET_STATE',
    payload: {
      loadingAction: false,
    },
  })
}

export function* SUBMIT({ payload }) {
  const { expenseRequestRef, resetForm, resetStates } = payload

  yield put({
    type: 'expenseRequest/SET_STATE',
    payload: {
      loadingAction: true,
    },
  })

  const success = yield call(submitExpenseRequest, expenseRequestRef)

  if (success) {
    yield call(SET_DOC, { payload: { expenseRequestRef } })
    notification.success({
      message: 'Success',
      description: 'ExpenseRequest has been submitted',
    })

    if (resetForm) resetForm()
    if (resetStates) resetStates()
  }

  yield put({
    type: 'expenseRequest/SET_STATE',
    payload: {
      loadingAction: false,
    },
  })
}

export function* OPEN({ payload }) {
  const { expenseRequestRef, resetForm, resetStates } = payload

  yield put({
    type: 'expenseRequest/SET_STATE',
    payload: {
      loadingAction: true,
    },
  })

  const success = yield call(openExpenseRequest, expenseRequestRef)

  if (success) {
    yield call(SET_DOC, { payload: { expenseRequestRef } })
    notification.success({
      message: 'Success',
      description: 'Expense request is opened',
    })

    if (resetForm) resetForm()
    if (resetStates) resetStates()
  }

  yield put({
    type: 'expenseRequest/SET_STATE',
    payload: {
      loadingAction: false,
    },
  })
}

export function* REVISE({ payload }) {
  const { expenseRequestRef, resetForm, resetStates } = payload

  yield put({
    type: 'expenseRequest/SET_STATE',
    payload: {
      loadingAction: true,
    },
  })

  const success = yield call(reviseExpenseRequest, expenseRequestRef)

  if (success) {
    yield call(SET_DOC, { payload: { expenseRequestRef } })
    notification.success({
      message: 'Success',
      description: 'ExpenseRequest has been set to revise',
    })

    if (resetForm) resetForm()
    if (resetStates) resetStates()
  }

  yield put({
    type: 'expenseRequest/SET_STATE',
    payload: {
      loadingAction: false,
    },
  })
}

export function* APPROVE({ payload }) {
  const { expenseRequestRef, resetForm, resetStates } = payload

  yield put({
    type: 'expenseRequest/SET_STATE',
    payload: {
      loadingAction: true,
    },
  })

  const success = yield call(approveExpenseRequest, expenseRequestRef)

  if (success) {
    yield call(SET_DOC, { payload: { expenseRequestRef } })
    notification.success({
      message: 'Success',
      description: 'ExpenseRequest has been approved',
    })

    if (resetForm) resetForm()
    if (resetStates) resetStates()
  }

  yield put({
    type: 'expenseRequest/SET_STATE',
    payload: {
      loadingAction: false,
    },
  })
}

export function* REJECT({ payload }) {
  const { expenseRequestRef, resetForm, resetStates } = payload

  yield put({
    type: 'expenseRequest/SET_STATE',
    payload: {
      loadingAction: true,
    },
  })

  const success = yield call(rejectExpenseRequest, expenseRequestRef)

  if (success) {
    yield call(SET_DOC, { payload: { expenseRequestRef } })
    notification.success({
      message: 'Success',
      description: 'ExpenseRequest has been rejected',
    })

    if (resetForm) resetForm()
    if (resetStates) resetStates()
  }

  yield put({
    type: 'expenseRequest/SET_STATE',
    payload: {
      loadingAction: false,
    },
  })
}

export function* COMPLETE({ payload }) {
  const { expenseRequestRef, resetForm, resetStates } = payload

  yield put({
    type: 'expenseRequest/SET_STATE',
    payload: {
      loadingAction: true,
    },
  })

  const success = yield call(completeExpenseRequest, expenseRequestRef)

  if (success) {
    yield call(SET_DOC, { payload: { expenseRequestRef } })
    notification.success({
      message: 'Success',
      description: 'ExpenseRequest has been rejected',
    })

    if (resetForm) resetForm()
    if (resetStates) resetStates()
  }

  yield put({
    type: 'expenseRequest/SET_STATE',
    payload: {
      loadingAction: false,
    },
  })
}

export function* DELETE({ payload }) {
  const { expenseRequestRef } = payload

  yield put({
    type: 'expenseRequest/SET_STATE',
    payload: {
      loadingAction: true,
    },
  })

  const success = yield call(deleteExpenseRequest, expenseRequestRef)

  if (success) {
    yield history.push('/purchase-requisition')
    notification.success({
      message: 'Success',
      description: 'ExpenseRequest has been deleted',
    })
  }

  yield put({
    type: 'expenseRequest/SET_STATE',
    payload: {
      loadingAction: false,
    },
  })
}

export function* CREATE_COMMENT({ payload }) {
  const { resetForm, resetStates, expenseRequestRef } = payload

  yield put({
    type: 'expenseRequest/SET_STATE',
    payload: {
      loadingAction: true,
    },
  })

  const success = yield call(createComment, payload)

  if (success) {
    notification.success({
      key: 'success',
      message: 'Success',
      description: 'Comment has been added',
    })

    if (resetForm) resetForm()
    if (resetStates) resetStates()

    yield call(SET_DOC, { payload: { expenseRequestRef } })
  }

  yield put({
    type: 'expenseRequest/SET_STATE',
    payload: {
      loadingAction: false,
    },
  })
}

export function* UPDATE_COMMENT({ payload }) {
  const { resetForm, resetStates, expenseRequestRef } = payload

  yield put({
    type: 'expenseRequest/SET_STATE',
    payload: {
      loadingAction: true,
    },
  })

  const success = yield call(updateComment, payload)

  if (success) {
    notification.success({
      key: 'success',
      message: 'Success',
      description: 'Comment has been updated',
    })

    if (resetForm) resetForm()
    if (resetStates) resetStates()

    yield call(SET_DOC, { payload: { expenseRequestRef } })
  }

  yield put({
    type: 'expenseRequest/SET_STATE',
    payload: {
      loadingAction: false,
    },
  })
}

export function* LIKE_COMMENT({ payload }) {
  const { resetForm, resetStates, commentRef, expenseRequestRef } = payload

  yield put({
    type: 'expenseRequest/SET_STATE',
    payload: {
      loadingAction: true,
    },
  })

  const success = yield call(addCommentLike, commentRef)

  if (success) {
    notification.success({
      key: 'success',
      message: 'Success',
      description: 'Comment was liked by you',
    })

    if (resetForm) resetForm()
    if (resetStates) resetStates()

    yield call(SET_DOC, { payload: { expenseRequestRef } })
  }

  yield put({
    type: 'expenseRequest/SET_STATE',
    payload: {
      loadingAction: false,
    },
  })
}

export function* UNLIKE_COMMENT({ payload }) {
  const { resetForm, resetStates, commentRef, expenseRequestRef } = payload

  yield put({
    type: 'expenseRequest/SET_STATE',
    payload: {
      loadingAction: true,
    },
  })

  const success = yield call(removeCommentLike, commentRef)

  if (success) {
    notification.success({
      key: 'success',
      message: 'Success',
      description: 'Comment was unliked by you',
    })

    if (resetForm) resetForm()
    if (resetStates) resetStates()

    yield call(SET_DOC, { payload: { expenseRequestRef } })
  }

  yield put({
    type: 'expenseRequest/SET_STATE',
    payload: {
      loadingAction: false,
    },
  })
}

export function* DELETE_COMMENT({ payload }) {
  const { expenseRequestRef, commentRef } = payload

  yield put({
    type: 'expenseRequest/SET_STATE',
    payload: {
      loadingAction: true,
    },
  })

  const success = yield call(deleteComment, commentRef)

  if (success) {
    notification.success({
      key: 'success',
      message: 'Success',
      description: 'Comment has been deleted',
    })

    yield call(SET_DOC, { payload: { expenseRequestRef } })
  }

  yield put({
    type: 'expenseRequest/SET_STATE',
    payload: {
      loadingAction: false,
    },
  })
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.CREATE, CREATE),
    takeEvery(actions.UPDATE, UPDATE),
    takeEvery(actions.SET_FILTERS, SET_FILTERS),
    takeEvery(actions.DELETE, DELETE),
    takeEvery(actions.CREATE_COMMENT, CREATE_COMMENT),
    takeEvery(actions.UPDATE_COMMENT, UPDATE_COMMENT),
    takeEvery(actions.LIKE_COMMENT, LIKE_COMMENT),
    takeEvery(actions.UNLIKE_COMMENT, UNLIKE_COMMENT),
    takeEvery(actions.DELETE_COMMENT, DELETE_COMMENT),
    takeEvery(actions.SUBMIT, SUBMIT),
    takeEvery(actions.OPEN, OPEN),
    takeEvery(actions.REVISE, REVISE),
    takeEvery(actions.APPROVE, APPROVE),
    takeEvery(actions.REJECT, REJECT),
    takeEvery(actions.COMPLETE, COMPLETE),
    takeEvery(actions.SET_DOCS, SET_DOCS),
    takeEvery(actions.SET_DOC, SET_DOC),
    takeEvery(actions.SET_CURRENT_PAGE, SET_CURRENT_PAGE),
    takeEvery(actions.SET_LIMIT, SET_LIMIT),
    takeEvery(actions.SET_SORTING, SET_SORTING),
    takeEvery(actions.SET_SEARCH_TEXT, SET_SEARCH_TEXT),
    debounce(500, actions.SET_SEARCH_TEXT, THROTTLE_SET_SEARCH_TEXT),
  ])
}

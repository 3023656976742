import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'
import user from './user/reducers'
import menu from './menu/reducers'
import settings from './settings/reducers'
import meeting from './meeting/reducers'
import asset from './asset/reducers'
import category from './category/reducers'
import subCategory from './subCategory/reducers'
import department from './department/reducers'
import location from './location/reducers'
import order from './order/reducers'
import orderRequest from './orderRequest/reducers'
import role from './role/reducers'
import site from './site/reducers'
import vendor from './vendor/reducers'
import workflow from './workflow/reducers'
import payment from './payment/reducers'
import staff from './staff/reducers'
import block from './block/reducers'
import property from './property/reducers'
import project from './project/reducers'
import lead from './lead/reducers'
import plan from './plan/reducers'
import client from './client/reducers'
import sale from './sale/reducers'
import agent from './agent/reducers'
import media from './media/reducers'
import task from './task/reducers'
import contractor from './contractor/reducers'
import consultant from './consultant/reducers'
import expenditure from './expenditure/reducers'
import expenseRequest from './expenseRequest/reducers'
import transaction from './transaction/reducers'

export default history =>
  combineReducers({
    router: connectRouter(history),
    payment,
    user,
    menu,
    settings,
    meeting,
    asset,
    category,
    subCategory,
    department,
    location,
    order,
    orderRequest,
    role,
    site,
    vendor,
    workflow,
    staff,
    block,
    project,
    property,
    lead,
    client,
    plan,
    sale,
    agent,
    media,
    task,
    contractor,
    consultant,
    expenditure,
    expenseRequest,
    transaction,
  })

import apiClient from '../axios'

export async function getMeetings({ page, pages, searchText, limit, sortOrder, sortCol }) {
  return apiClient
    .get(
      `/meeting?page=${
        pages >= page ? page : 1
      }&search=${searchText.trim()}&limit=${limit}&sort_order=${sortOrder}&sort_col=${sortCol}`,
    )
    .then(response => (response && response.data ? response.data : false))
    .catch(error => console.log(error))
}

export async function getMeeting(id) {
  return apiClient
    .get(`/meeting/${id}`)
    .then(response => (response && response.data ? response.data : false))
    .catch(error => console.log(error))
}

export async function getParticipant(meetingRef) {
  return apiClient
    .get(`/meeting/participant?meetingRef=${meetingRef}`)
    .then(response => (response && response.data ? response.data : false))
    .catch(error => console.log(error))
}

export async function inviteParticipants(body) {
  return apiClient
    .post(`/meeting/invite`, body)
    .then(response => (response && response.data ? response.data : false)) // eslint-disable-line
    .catch(error => console.log(error.message))
}

export async function createMeeting(body) {
  return apiClient
    .post(`/meeting/create`, body)
    .then(response => (response && response.data ? response.data : false)) // eslint-disable-line
    .catch(error => console.log(error))
}

export async function updateMeeting(body) {
  return apiClient
    .post(`/meeting/update/${body.id}`, body)
    .then(response => (response && response.data ? response.data : false)) // eslint-disable-line
    .catch(error => console.log(error))
}

export async function addMeetingAgenda(body) {
  return apiClient
    .post(`/meeting/agenda/create`, body)
    .then(response => (response && response.data ? response.data : false)) // eslint-disable-line
    .catch(error => console.log(error))
}

export async function addMeetingAgendaAttachment(body) {
  return apiClient
    .post(`/meeting/agenda/attachment/create`, body)
    .then(response => (response && response.data ? response.data : false)) // eslint-disable-line
    .catch(error => console.log(error))
}

export async function addMeetingAgendaTask(body) {
  return apiClient
    .post(`/meeting/agenda/task/create`, body)
    .then(response => (response && response.data ? response.data : false)) // eslint-disable-line
    .catch(error => console.log(error))
}

export async function updateMeetingAgenda(body) {
  return apiClient
    .post(`/meeting/agenda/update/${body.agendaRef}`, body)
    .then(response => (response && response.data ? response.data : false)) // eslint-disable-line
    .catch(error => console.log(error))
}

export async function deleteMeetingAgenda(body) {
  return apiClient
    .post(`/meeting/agenda/delete/${body.agendaRef}`, body)
    .then(response => (response && response.data ? response.data : false)) // eslint-disable-line
    .catch(error => console.log(error))
}

export async function deleteMeetingAgendaAttachment(body) {
  return apiClient
    .post(`/meeting/agenda/attachment/delete/${body.attachmentRef}`, body)
    .then(response => (response && response.data ? response.data : false)) // eslint-disable-line
    .catch(error => console.log(error))
}

export async function deleteMeetingAgendaTask(body) {
  return apiClient
    .post(`/meeting/agenda/task/delete/${body.taskRef}`, body)
    .then(response => (response && response.data ? response.data : false)) // eslint-disable-line
    .catch(error => console.log(error))
}

const actions = {
  SET_STATE: 'payment/SET_STATE',
  SET_FILTERS: 'payment/SET_FILTERS',
  // SET_EXPENDITURE_DOCS: 'payment/SET_EXPENDITURE_DOCS',
  // SET_PURCHASE_DOCS: 'payment/SET_PURCHASE_DOCS',
  SET_RECENT_PURCHASES: 'payment/SET_RECENT_PURCHASES',
  SET_RECENT_EXPENDITURES: 'payment/SET_RECENT_EXPENDITURES',
  // SET_TOTAL_PURCHASE: 'payment/SET_TOTAL_PURCHASE',
  // SET_TOTAL_EXPENDITURE: 'payment/SET_TOTAL_EXPENDITURE',
  SET_TOTAL: 'payment/SET_TOTAL',
  GET_PDF: 'payment/GET_PDF',
  SET_CURRENT_PAGE: 'payment/SET_CURRENT_PAGE',
  SET_LIMIT: 'payment/SET_LIMIT',
  SET_SORTING: 'payment/SET_SORTING',
  SET_DOCS: 'payment/SET_DOCS',
  SET_CHART_DOCS: 'payment/SET_CHART_DOCS',
  CANCEL: 'payment/CANCEL',
  DELETE: 'payment/DELETE',
  GET_RECEIPT: 'payment/GET_RECEIPT',
}

export default actions

const actions = {
  UPLOAD: 'media/UPLOAD',
  UPDATE: 'media/UPDATE',
  DELETE: 'media/DELETE',
  SET_STATE: 'media/SET_STATE',
  SET_DOCS: 'media/SET_DOCS',
  SET_DOC: 'media/SET_DOC',
  SET_CURRENT_PAGE: 'media/SET_CURRENT_PAGE',
  SET_LIMIT: 'media/SET_LIMIT',
  SET_SORTING: 'media/SET_SORTING',
  SET_SEARCH_TEXT: 'media/SET_SEARCH_TEXT',
}

export default actions

import apiClient from '../axios'

export async function createProperty(body) {
  return apiClient
    .post(`/property/create`, body)
    .then(response => (response && response.data ? response.data : false)) // eslint-disable-line
    .catch(error => console.log(error.message))
}

export async function getProperties({
  page,
  pages,
  searchText,
  limit,
  sortOrder,
  sortCol,
  filterProject,
  filterLocation,
  filterType,
  filterStatus,
  filterBlock,
  filterClient,
}) {
  return apiClient
    .get(
      `/property?page=${
        pages >= page ? page : 1
      }&search=${searchText.trim()}&limit=${limit}&sort_order=${sortOrder}&sort_col=${sortCol}&filter_project=${filterProject}&filter_location=${filterLocation}&filter_type=${filterType}&filter_status=${filterStatus}&filter_block=${filterBlock}&filter_client=${filterClient}`,
    )
    .then(response => (response && response.data ? response.data : false))
    .catch(error => console.log(error))
}

export async function getProperty(propertyRef) {
  return apiClient
    .get(`/property/${propertyRef}`)
    .then(response => (response && response.data ? response.data : false))
    .catch(error => console.log(error))
}

export async function updateProperty(body) {
  return apiClient
    .post(`/property/update/${body.propertyRef}`, body)
    .then(response => (response && response.data ? response.data : false)) // eslint-disable-line
    .catch(error => console.log(error))
}

export async function updateProperties(body) {
  return apiClient
    .post(`/property/update-bulk`, body)
    .then(response => (response && response.data ? response.data : false)) // eslint-disable-line
    .catch(error => console.log(error))
}

export async function deleteProperty(propertyRef) {
  return apiClient
    .post(`/property/delete/${propertyRef}`)
    .then(response => (response && response.data ? response.data : false)) // eslint-disable-line
    .catch(error => console.log(error))
}

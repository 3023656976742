const actions = {
  CREATE: 'sale/CREATE',
  ASSIGN_PROPERTY: 'sale/ASSIGN_PROPERTY',
  DELETE: 'sale/DELETE',
  SET_STATE: 'sale/SET_STATE',
  SET_DOCS: 'sale/SET_DOCS',
  SET_STATS: 'sale/SET_STATS',
  SET_DOC: 'sale/SET_DOC',
  SET_CURRENT_PAGE: 'sale/SET_CURRENT_PAGE',
  SET_LIMIT: 'sale/SET_LIMIT',
  SET_SORTING: 'sale/SET_SORTING',
  SET_SEARCH_TEXT: 'sale/SET_SEARCH_TEXT',
  SET_FILTERS: 'sale/SET_FILTERS',
  CREATE_PAYMENT: 'sale/CREATE_PAYMENT',
  COMPLETE: 'sale/COMPLETE',
  CANCEL: 'sale/CANCEL',
  INVOICE_PDF: 'sale/INVOICE_PDF',
  GET_CSV: 'sale/GET_CSV',
}

export default actions

import { all, put, call, takeEvery, select, debounce } from 'redux-saga/effects'
import { history } from 'index'
import {
  createOrder,
  createOrderWithOrderRequest,
  updateOrder,
  submitOrder,
  openOrder,
  reviseOrder,
  approveOrder,
  rejectOrder,
  completeOrder,
  addOrderItem,
  updateOrderItem,
  deleteOrderItem,
  createComment,
  updateComment,
  addCommentLike,
  removeCommentLike,
  deleteComment,
  addOrderPayment,
  cancelOrderPayment,
  getOrders,
  getOrder,
  deleteOrder,
} from 'services/api/order'
import { notification } from 'antd'
import actions from './actions'

export function* CREATE({ payload }) {
  const { priority, deliveryLocation, vendor, tax, deliveryDate, resetForm, resetStates } = payload

  yield put({
    type: 'order/SET_STATE',
    payload: {
      loadingAction: true,
    },
  })

  const success = yield call(createOrder, {
    priority,
    deliveryLocation,
    vendor,
    tax,
    deliveryDate,
  })

  if (success) {
    notification.success({
      message: 'Success',
      description: 'New order added',
    })

    if (resetForm) resetForm()
    if (resetStates) resetStates()

    yield history.push(`/purchase-order/${success.orderRef}`)
  }

  yield put({
    type: 'order/SET_STATE',
    payload: {
      loadingAction: false,
    },
  })
}

export function* CREATE_WITH_REQUEST({ payload }) {
  const { orderRequestRef } = payload

  yield put({
    type: 'order/SET_STATE',
    payload: {
      loadingAction: true,
    },
  })

  const success = yield call(createOrderWithOrderRequest, {
    orderRequestRef,
  })

  if (success) {
    notification.success({
      message: 'Success',
      description: 'New order added',
    })
  }

  yield put({
    type: 'order/SET_STATE',
    payload: {
      loadingAction: false,
    },
  })
}

export function* SET_DOCS() {
  yield put({
    type: 'order/SET_STATE',
    payload: {
      loadingDocs: true,
    },
  })

  // Get queries
  const {
    limit,
    searchText,
    page,
    pages,
    sortOrder,
    sortCol,
    filterPriority,
    filterLocation,
    filterDepartment,
    filterCreatedBy,
    filterVendor,
    filterStatus,
    filterPaymentStatus,
    filterDateRangeStart,
    filterDateRangeEnd,
  } = yield select(state => state.order)

  const response = yield call(getOrders, {
    limit,
    searchText,
    page,
    pages,
    sortOrder,
    sortCol,
    filterPriority,
    filterLocation,
    filterDepartment,
    filterCreatedBy,
    filterVendor,
    filterStatus,
    filterPaymentStatus,
    filterDateRangeStart,
    filterDateRangeEnd,
  })

  if (response && response.docs) {
    yield put({
      type: 'order/SET_STATE',
      payload: {
        docs: response.docs,
        total: response.totalDocs,
        pages: response.totalPages,
      },
    })
  }

  yield put({
    type: 'order/SET_STATE',
    payload: {
      loadingDocs: false,
    },
  })
}

export function* SET_DOC({ payload }) {
  const { orderRef } = payload

  yield put({
    type: 'order/SET_STATE',
    payload: {
      loadingDoc: true,
    },
  })

  const doc = yield call(getOrder, orderRef)

  yield put({
    type: 'order/SET_STATE',
    payload: {
      doc,
      loadingDoc: false,
    },
  })
}

export function* SET_CURRENT_PAGE({ payload }) {
  const { page } = payload

  yield put({
    type: 'order/SET_STATE',
    payload: {
      page,
    },
  })

  yield call(SET_DOCS)
}

export function* SET_LIMIT({ payload }) {
  const { limit } = payload

  yield put({
    type: 'order/SET_STATE',
    payload: {
      limit,
    },
  })

  yield call(SET_DOCS)
}

export function* SET_SORTING({ payload }) {
  const { sortOrder, sortCol } = payload

  yield put({
    type: 'order/SET_STATE',
    payload: {
      sortOrder,
      sortCol,
    },
  })

  yield call(SET_DOCS)
}

export function* SET_SEARCH_TEXT({ payload }) {
  const { searchText } = payload

  yield put({
    type: 'order/SET_STATE',
    payload: {
      searchText,
      page: 1,
    },
  })
}

export function* THROTTLE_SET_SEARCH_TEXT() {
  yield call(SET_DOCS)
}

export function* SET_FILTERS({ payload }) {
  const {
    filterStatus,
    filterPaymentStatus,
    filterPriority,
    filterLocation,
    filterDepartment,
    filterCreatedBy,
    filterVendor,
    filterDateRangeStart,
    filterDateRangeEnd,
  } = payload

  yield put({
    type: 'order/SET_STATE',
    payload: {
      filterStatus,
      filterPaymentStatus,
      filterPriority,
      filterLocation,
      filterDepartment,
      filterCreatedBy,
      filterVendor,
      filterDateRangeStart,
      filterDateRangeEnd,
    },
  })

  yield call(SET_DOCS)
}

export function* UPDATE({ payload }) {
  const {
    priority,
    deliveryLocation,
    vendor,
    tax,
    deliveryDate,
    orderRef,
    resetForm,
    resetStates,
  } = payload

  yield put({
    type: 'order/SET_STATE',
    payload: {
      loadingAction: true,
    },
  })

  const success = yield call(updateOrder, {
    priority,
    deliveryLocation,
    vendor,
    tax,
    deliveryDate,
    orderRef,
  })

  if (success) {
    notification.success({
      message: 'Success',
      description: 'Order has been updated',
    })

    if (resetForm) resetForm()
    if (resetStates) resetStates()
  }

  yield put({
    type: 'order/SET_STATE',
    payload: {
      loadingAction: false,
    },
  })
}

export function* SUBMIT({ payload }) {
  const { orderRef, resetForm, resetStates } = payload

  yield put({
    type: 'order/SET_STATE',
    payload: {
      loadingAction: true,
    },
  })

  const success = yield call(submitOrder, orderRef)

  if (success) {
    yield call(SET_DOC, { payload: { orderRef } })
    notification.success({
      message: 'Success',
      description: 'Order has been submitted',
    })

    if (resetForm) resetForm()
    if (resetStates) resetStates()
  }

  yield put({
    type: 'order/SET_STATE',
    payload: {
      loadingAction: false,
    },
  })
}

export function* OPEN({ payload }) {
  const { orderRef, resetForm, resetStates } = payload

  yield put({
    type: 'order/SET_STATE',
    payload: {
      loadingAction: true,
    },
  })

  const success = yield call(openOrder, orderRef)

  if (success) {
    yield call(SET_DOC, { payload: { orderRef } })
    notification.success({
      message: 'Success',
      description: 'purchase order is opened',
    })

    if (resetForm) resetForm()
    if (resetStates) resetStates()
  }

  yield put({
    type: 'order/SET_STATE',
    payload: {
      loadingAction: false,
    },
  })
}

export function* REVISE({ payload }) {
  const { orderRef, resetForm, resetStates } = payload

  yield put({
    type: 'order/SET_STATE',
    payload: {
      loadingAction: true,
    },
  })

  const success = yield call(reviseOrder, orderRef)

  if (success) {
    yield call(SET_DOC, { payload: { orderRef } })
    notification.success({
      message: 'Success',
      description: 'Order has been set to revise',
    })

    if (resetForm) resetForm()
    if (resetStates) resetStates()
  }

  yield put({
    type: 'order/SET_STATE',
    payload: {
      loadingAction: false,
    },
  })
}

export function* APPROVE({ payload }) {
  const { orderRef, resetForm, resetStates } = payload

  yield put({
    type: 'order/SET_STATE',
    payload: {
      loadingAction: true,
    },
  })

  const success = yield call(approveOrder, orderRef)

  if (success) {
    yield call(SET_DOC, { payload: { orderRef } })
    notification.success({
      message: 'Success',
      description: 'Order has been approved',
    })

    if (resetForm) resetForm()
    if (resetStates) resetStates()
  }

  yield put({
    type: 'order/SET_STATE',
    payload: {
      loadingAction: false,
    },
  })
}

export function* REJECT({ payload }) {
  const { orderRef, resetForm, resetStates } = payload

  yield put({
    type: 'order/SET_STATE',
    payload: {
      loadingAction: true,
    },
  })

  const success = yield call(rejectOrder, orderRef)

  if (success) {
    yield call(SET_DOC, { payload: { orderRef } })
    notification.success({
      message: 'Success',
      description: 'Order has been rejected',
    })

    if (resetForm) resetForm()
    if (resetStates) resetStates()
  }

  yield put({
    type: 'order/SET_STATE',
    payload: {
      loadingAction: false,
    },
  })
}

export function* COMPLETE({ payload }) {
  const { orderRef, resetForm, resetStates } = payload

  yield put({
    type: 'order/SET_STATE',
    payload: {
      loadingAction: true,
    },
  })

  const success = yield call(completeOrder, orderRef)

  if (success) {
    yield call(SET_DOC, { payload: { orderRef } })
    notification.success({
      message: 'Success',
      description: 'Order has been rejected',
    })

    if (resetForm) resetForm()
    if (resetStates) resetStates()
  }

  yield put({
    type: 'order/SET_STATE',
    payload: {
      loadingAction: false,
    },
  })
}

export function* DELETE({ payload }) {
  const { orderRef } = payload

  yield put({
    type: 'order/SET_STATE',
    payload: {
      loadingAction: true,
    },
  })

  const success = yield call(deleteOrder, orderRef)

  if (success) {
    yield history.push('/purchase-order')

    notification.success({
      message: 'Success',
      description: 'Order has been deleted',
    })
  }

  yield put({
    type: 'order/SET_STATE',
    payload: {
      loadingAction: false,
    },
  })
}

export function* CREATE_ITEM({ payload }) {
  const { description, orderRef, resetForm, resetStates } = payload
  console.log(orderRef)

  yield put({
    type: 'order/SET_STATE',
    payload: {
      loadingAction: true,
    },
  })

  const success = yield call(addOrderItem, {
    description,
    orderRef,
  })

  if (success) {
    yield call(SET_DOC, { payload: { orderRef } })

    notification.success({
      message: 'Success',
      description: 'Order Item has been added',
    })

    if (resetForm) resetForm()
    if (resetStates) resetStates()
  }

  yield put({
    type: 'order/SET_STATE',
    payload: {
      loadingAction: false,
    },
  })
}

export function* UPDATE_ITEM({ payload }) {
  const {
    description,
    quantity,
    unit,
    unitPrice,
    category,
    subCategory,
    orderItemRef,
    orderRef,
    resetForm,
    resetStates,
  } = payload

  yield put({
    type: 'order/SET_STATE',
    payload: {
      loadingAction: true,
    },
  })

  const success = yield call(updateOrderItem, {
    description,
    quantity,
    unit,
    unitPrice,
    category,
    subCategory,
    orderItemRef,
    orderRef,
  })

  if (success) {
    yield call(SET_DOC, { payload: { orderRef } })

    yield put({ type: 'order/SET_STATE', payload: { updateItemModalState: false } })

    notification.success({
      message: 'Success',
      description: 'Order Item has been updated',
    })

    if (resetForm) resetForm()
    if (resetStates) resetStates()
  }

  yield put({
    type: 'order/SET_STATE',
    payload: {
      loadingAction: false,
    },
  })
}

export function* DELETE_ITEM({ payload }) {
  const { orderItemRef, orderRef, resetForm, resetStates } = payload

  yield put({
    type: 'order/SET_STATE',
    payload: {
      loadingAction: true,
    },
  })

  const success = yield call(deleteOrderItem, {
    orderItemRef,
    orderRef,
  })

  if (success) {
    yield call(SET_DOC, { payload: { orderRef } })

    notification.success({
      message: 'Success',
      description: 'Order Item has been deleted',
    })

    if (resetForm) resetForm()
    if (resetStates) resetStates()
  }

  yield put({
    type: 'order/SET_STATE',
    payload: {
      loadingAction: false,
    },
  })
}

export function* CREATE_COMMENT({ payload }) {
  const { resetForm, resetStates, orderRef } = payload

  yield put({
    type: 'order/SET_STATE',
    payload: {
      loadingAction: true,
    },
  })

  const success = yield call(createComment, payload)

  if (success) {
    notification.success({
      key: 'success',
      message: 'Success',
      description: 'Comment has been added',
    })

    if (resetForm) resetForm()
    if (resetStates) resetStates()

    yield call(SET_DOC, { payload: { orderRef } })
  }

  yield put({
    type: 'order/SET_STATE',
    payload: {
      loadingAction: false,
    },
  })
}

export function* UPDATE_COMMENT({ payload }) {
  const { resetForm, resetStates, orderRef } = payload

  yield put({
    type: 'order/SET_STATE',
    payload: {
      loadingAction: true,
    },
  })

  const success = yield call(updateComment, payload)

  if (success) {
    notification.success({
      key: 'success',
      message: 'Success',
      description: 'Comment has been updated',
    })

    if (resetForm) resetForm()
    if (resetStates) resetStates()

    yield call(SET_DOC, { payload: { orderRef } })
  }

  yield put({
    type: 'order/SET_STATE',
    payload: {
      loadingAction: false,
    },
  })
}

export function* LIKE_COMMENT({ payload }) {
  const { resetForm, resetStates, commentRef, orderRef } = payload

  yield put({
    type: 'order/SET_STATE',
    payload: {
      loadingAction: true,
    },
  })

  const success = yield call(addCommentLike, commentRef)

  if (success) {
    notification.success({
      key: 'success',
      message: 'Success',
      description: 'Comment was liked by you',
    })

    if (resetForm) resetForm()
    if (resetStates) resetStates()

    yield call(SET_DOC, { payload: { orderRef } })
  }

  yield put({
    type: 'order/SET_STATE',
    payload: {
      loadingAction: false,
    },
  })
}

export function* UNLIKE_COMMENT({ payload }) {
  const { resetForm, resetStates, commentRef, orderRef } = payload

  yield put({
    type: 'order/SET_STATE',
    payload: {
      loadingAction: true,
    },
  })

  const success = yield call(removeCommentLike, commentRef)

  if (success) {
    notification.success({
      key: 'success',
      message: 'Success',
      description: 'Comment was unliked by you',
    })

    if (resetForm) resetForm()
    if (resetStates) resetStates()

    yield call(SET_DOC, { payload: { orderRef } })
  }

  yield put({
    type: 'order/SET_STATE',
    payload: {
      loadingAction: false,
    },
  })
}

export function* DELETE_COMMENT({ payload }) {
  const { orderRef, commentRef } = payload

  yield put({
    type: 'order/SET_STATE',
    payload: {
      loadingAction: true,
    },
  })

  const success = yield call(deleteComment, commentRef)

  if (success) {
    notification.success({
      key: 'success',
      message: 'Success',
      description: 'Comment has been deleted',
    })

    yield call(SET_DOC, { payload: { orderRef } })
  }

  yield put({
    type: 'order/SET_STATE',
    payload: {
      loadingAction: false,
    },
  })
}

export function* CREATE_PAYMENT({ payload }) {
  const { /*  amount, paymentDate,  */ contextDispatch, orderRef, resetForm, resetStates } = payload

  yield put({
    type: 'order/SET_STATE',
    payload: {
      loadingAction: true,
    },
  })

  const success = yield call(addOrderPayment, payload)

  if (success) {
    if (orderRef) yield call(SET_DOC, { payload: { orderRef } })
    yield put({
      type: 'order/SET_STATE',
      payload: {
        paymentsDrawerState: false,
        createPaymentModalState: false,
      },
    })
    notification.success({
      message: 'Success',
      description: 'Payment has been added for order',
    })

    if (contextDispatch && contextDispatch.type) yield put(contextDispatch)
    if (resetForm) resetForm()
    if (resetStates) resetStates()
  }

  yield put({
    type: 'order/SET_STATE',
    payload: {
      loadingAction: false,
    },
  })
}

export function* CANCEL_PAYMENT({ payload }) {
  const { paymentDate, orderRef, resetForm, resetStates } = payload

  yield put({
    type: 'order/SET_STATE',
    payload: {
      loadingAction: true,
    },
  })

  const success = yield call(cancelOrderPayment, {
    paymentDate,
    orderRef,
  })

  if (success) {
    yield call(SET_DOC, { payload: { orderRef } })
    notification.success({
      message: 'Success',
      description: 'Payment has been canceled for order',
    })

    if (resetForm) resetForm()
    if (resetStates) resetStates()
  }

  yield put({
    type: 'order/SET_STATE',
    payload: {
      loadingAction: false,
    },
  })
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.CREATE, CREATE),
    takeEvery(actions.CREATE_WITH_REQUEST, CREATE_WITH_REQUEST),
    takeEvery(actions.UPDATE, UPDATE),
    takeEvery(actions.SET_FILTERS, SET_FILTERS),
    takeEvery(actions.DELETE, DELETE),
    takeEvery(actions.CREATE_ITEM, CREATE_ITEM),
    takeEvery(actions.UPDATE_ITEM, UPDATE_ITEM),
    takeEvery(actions.DELETE_ITEM, DELETE_ITEM),
    takeEvery(actions.CREATE_COMMENT, CREATE_COMMENT),
    takeEvery(actions.UPDATE_COMMENT, UPDATE_COMMENT),
    takeEvery(actions.LIKE_COMMENT, LIKE_COMMENT),
    takeEvery(actions.UNLIKE_COMMENT, UNLIKE_COMMENT),
    takeEvery(actions.DELETE_COMMENT, DELETE_COMMENT),
    takeEvery(actions.SUBMIT, SUBMIT),
    takeEvery(actions.OPEN, OPEN),
    takeEvery(actions.REVISE, REVISE),
    takeEvery(actions.APPROVE, APPROVE),
    takeEvery(actions.REJECT, REJECT),
    takeEvery(actions.CREATE_PAYMENT, CREATE_PAYMENT),
    takeEvery(actions.CANCEL_PAYMENT, CANCEL_PAYMENT),
    takeEvery(actions.COMPLETE, COMPLETE),
    takeEvery(actions.SET_DOCS, SET_DOCS),
    takeEvery(actions.SET_DOC, SET_DOC),
    takeEvery(actions.SET_CURRENT_PAGE, SET_CURRENT_PAGE),
    takeEvery(actions.SET_LIMIT, SET_LIMIT),
    takeEvery(actions.SET_SORTING, SET_SORTING),
    takeEvery(actions.SET_SEARCH_TEXT, SET_SEARCH_TEXT),
    debounce(500, actions.SET_SEARCH_TEXT, THROTTLE_SET_SEARCH_TEXT),
  ])
}

import { all, put, call, takeEvery, select, debounce } from 'redux-saga/effects'
import { history } from 'index'
import {
  createClient,
  updateClient,
  addClientAddress,
  getClients,
  getClientsAsCSV,
  getClient,
  deleteClient,
  removeClientAddress,
} from 'services/api/client'
import { notification } from 'antd'
import actions from './actions'

export function* CREATE({ payload }) {
  const { resetForm, resetStates } = payload

  yield put({
    type: 'client/SET_STATE',
    payload: {
      loadingAction: true,
    },
  })

  const success = yield call(createClient, payload)

  if (success) {
    yield call(SET_DOCS, { payload: {} })
    notification.success({
      message: 'Success',
      description: 'New client added',
    })

    if (resetForm) resetForm()
    if (resetStates) resetStates()
  }

  yield put({
    type: 'client/SET_STATE',
    payload: {
      loadingAction: false,
    },
  })
}

export function* SET_DOCS() {
  yield put({
    type: 'client/SET_STATE',
    payload: {
      loadingDocs: true,
    },
  })

  // Get queries
  const {
    limit,
    searchText,
    page,
    pages,
    sortOrder,
    sortCol,
    filterReferrerType,
    filterReferrer,
  } = yield select(state => state.client)

  const success = yield call(getClients, {
    limit,
    searchText,
    page,
    pages,
    sortOrder,
    sortCol,
    filterReferrerType,
    filterReferrer,
  })

  if (success) {
    const { docs, totalDocs, totalPages } = success

    yield put({
      type: 'client/SET_STATE',
      payload: {
        docs,
        total: totalDocs,
        pages: totalPages,
      },
    })
  }

  yield put({
    type: 'client/SET_STATE',
    payload: {
      loadingDocs: false,
    },
  })
}

export function* SET_DOC({ payload }) {
  const { clientRef } = payload

  yield put({
    type: 'client/SET_STATE',
    payload: {
      loadingDoc: true,
    },
  })

  const doc = yield call(getClient, clientRef)

  yield put({
    type: 'client/SET_STATE',
    payload: {
      doc,
      loadingDoc: false,
    },
  })
}

export function* SET_CURRENT_PAGE({ payload }) {
  const { page } = payload

  yield put({
    type: 'client/SET_STATE',
    payload: {
      page,
    },
  })

  yield call(SET_DOCS)
}

export function* SET_LIMIT({ payload }) {
  const { limit } = payload

  yield put({
    type: 'client/SET_STATE',
    payload: {
      limit,
    },
  })

  yield call(SET_DOCS)
}

export function* SET_SORTING({ payload }) {
  const { sortOrder, sortCol } = payload

  yield put({
    type: 'client/SET_STATE',
    payload: {
      sortOrder,
      sortCol,
    },
  })

  yield call(SET_DOCS)
}

export function* SET_SEARCH_TEXT({ payload }) {
  const { searchText } = payload

  yield put({
    type: 'client/SET_STATE',
    payload: {
      searchText,
      page: 1,
    },
  })
}

export function* THROTTLE_SET_SEARCH_TEXT() {
  yield call(SET_DOCS)
}

export function* SET_FILTERS({ payload }) {
  const { filterReferrerType, filterReferrer } = payload

  yield put({
    type: 'client/SET_STATE',
    payload: {
      filterReferrer,
      filterReferrerType,
    },
  })

  yield call(SET_DOCS)
}

export function* GET_CSV() {
  yield put({
    type: 'client/SET_STATE',
    payload: {
      loadingAction: true,
    },
  })

  const { searchText, sortOrder, sortCol, filterReferrerType, filterReferrer } = yield select(
    state => state.client,
  )

  const success = yield call(getClientsAsCSV, {
    searchText,
    sortOrder,
    sortCol,
    filterReferrerType,
    filterReferrer,
  })

  if (success) {
    /*  notification.success({
      message: 'Success',
      description: 'success',
    }) */
  }

  yield put({
    type: 'client/SET_STATE',
    payload: {
      loadingAction: false,
    },
  })
}

export function* UPDATE({ payload }) {
  const { resetForm, resetStates, shouldFetchUser, hideNotification } = payload

  yield put({
    type: 'client/SET_STATE',
    payload: {
      loadingAction: true,
    },
  })

  const success = yield call(updateClient, payload)

  if (success) {
    if (!hideNotification) {
      notification.success({
        message: 'Success',
        description: 'Client has been updated',
      })
    }

    if (resetForm) resetForm()
    if (resetStates) resetStates()

    if (shouldFetchUser)
      yield put({
        type: 'user/LOAD_CURRENT_ACCOUNT',
      })
  }

  yield put({
    type: 'client/SET_STATE',
    payload: {
      loadingAction: false,
    },
  })
}

export function* DELETE({ payload }) {
  const { clientRef } = payload

  yield put({
    type: 'client/SET_STATE',
    payload: {
      loadingAction: true,
    },
  })

  const success = yield call(deleteClient, clientRef)

  if (success) {
    yield history.push('/client')

    notification.success({
      message: 'Success',
      description: 'Client has been deleted',
    })
  }

  yield put({
    type: 'client/SET_STATE',
    payload: {
      loadingAction: false,
    },
  })
}

export function* ADD_ADDRESS({ payload }) {
  const { resetForm, resetStates } = payload

  yield put({
    type: 'client/SET_STATE',
    payload: {
      loadingAction: true,
    },
  })

  const success = yield call(addClientAddress, payload)

  if (success) {
    yield call(SET_DOC, { payload: { clientRef: payload.clientRef } })
    notification.success({
      message: 'Success',
      description: 'Client Address has been added',
    })

    if (resetForm) resetForm()
    if (resetStates) resetStates()
  }

  yield put({
    type: 'client/SET_STATE',
    payload: {
      loadingAction: false,
    },
  })
}

export function* REMOVE_ADDRESS({ payload }) {
  const { clientAddRef, clientRef } = payload

  yield put({
    type: 'client/SET_STATE',
    payload: {
      loadingAction: true,
    },
  })

  const success = yield call(removeClientAddress, clientAddRef)

  if (success) {
    yield call(SET_DOC, { payload: { clientRef } })
    notification.success({
      message: 'Success',
      description: 'Client address has been deleted',
    })
  }

  yield put({
    type: 'client/SET_STATE',
    payload: {
      loadingAction: false,
    },
  })
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.CREATE, CREATE),
    takeEvery(actions.UPDATE, UPDATE),
    takeEvery(actions.ADD_ADDRESS, ADD_ADDRESS),
    takeEvery(actions.REMOVE_ADDRESS, REMOVE_ADDRESS),
    takeEvery(actions.DELETE, DELETE),
    takeEvery(actions.SET_DOCS, SET_DOCS),
    takeEvery(actions.GET_CSV, GET_CSV),
    takeEvery(actions.SET_DOC, SET_DOC),
    takeEvery(actions.SET_CURRENT_PAGE, SET_CURRENT_PAGE),
    takeEvery(actions.SET_LIMIT, SET_LIMIT),
    takeEvery(actions.SET_SORTING, SET_SORTING),
    takeEvery(actions.SET_SEARCH_TEXT, SET_SEARCH_TEXT),
    debounce(500, actions.SET_SEARCH_TEXT, THROTTLE_SET_SEARCH_TEXT),
    takeEvery(actions.SET_FILTERS, SET_FILTERS),
  ])
}

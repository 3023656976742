import { all, put, call, takeEvery, select, debounce } from 'redux-saga/effects'
import { history } from 'index'
import {
  createSale,
  assignProperty,
  getSales,
  getSalesAsCSV,
  getStatistics,
  getSale,
  deleteSale,
  addSalePayment,
  completeSale,
  cancelSale,
  getInstallmentInvoicePdf,
} from 'services/api/sale'
import { notification } from 'antd'
import actions from './actions'

export function* CREATE({ payload }) {
  const { resetForm, resetStates } = payload

  yield put({
    type: 'sale/SET_STATE',
    payload: {
      loadingAction: true,
    },
  })

  const success = yield call(createSale, payload)

  if (success) {
    yield call(SET_DOCS)
    notification.success({
      message: 'Success',
      description: 'New sale added',
    })

    if (resetForm) resetForm()
    if (resetStates) resetStates()
  }

  yield put({
    type: 'sale/SET_STATE',
    payload: {
      loadingAction: false,
    },
  })
}

export function* SET_DOCS() {
  yield put({
    type: 'sale/SET_STATE',
    payload: { loadingDocs: true },
  })

  // Get queries
  const {
    limit,
    searchText,
    page,
    pages,
    sortOrder,
    sortCol,
    filterProperty,
    filterProject,
    filterClient,
    filterReferrer,
    filterReferrerType,
    filterStatus,
    filterType,
    filterInstallmentDue,
    filterAllowAssign,
    filterDue,
  } = yield select(state => state.sale)

  const success = yield call(getSales, {
    limit,
    searchText,
    page,
    pages,
    sortOrder,
    sortCol,
    filterProperty,
    filterProject,
    filterClient,
    filterReferrer,
    filterReferrerType,
    filterStatus,
    filterType,
    filterInstallmentDue,
    filterDue,
    filterAllowAssign,
  })

  if (success) {
    const { docs, totalDocs, totalPages } = success

    yield put({
      type: 'sale/SET_STATE',
      payload: {
        docs,
        total: totalDocs,
        pages: totalPages,
      },
    })
  }

  yield put({
    type: 'sale/SET_STATE',
    payload: {
      loadingDocs: false,
    },
  })
}

export function* GET_CSV() {
  yield put({
    type: 'sale/SET_STATE',
    payload: {
      loadingAction: true,
    },
  })

  const {
    searchText,
    sortOrder,
    sortCol,
    filterProperty,
    filterProject,
    filterClient,
    filterReferrer,
    filterReferrerType,
    filterStatus,
    filterType,
    filterInstallmentDue,
    filterDue,
    filterAllowAssign,
  } = yield select(state => state.sale)

  const success = yield call(getSalesAsCSV, {
    searchText,
    sortOrder,
    sortCol,
    filterProperty,
    filterProject,
    filterClient,
    filterReferrer,
    filterReferrerType,
    filterStatus,
    filterType,
    filterInstallmentDue,
    filterDue,
    filterAllowAssign,
  })

  if (success) {
    /*  notification.success({
      message: 'Success',
      description: 'success',
    }) */
  }

  yield put({
    type: 'sale/SET_STATE',
    payload: {
      loadingAction: false,
    },
  })
}

export function* SET_STATS() {
  yield put({
    type: 'sale/SET_STATE',
    payload: { loadingStats: true },
  })

  // Get queries
  const {
    filterDatePreset,
    filterDateRangeStart,
    filterDateRangeEnd,
    searchText,
    filterProperty,
    filterProject,
    filterClient,
    filterReferrer,
    filterReferrerType,
    filterStatus,
    filterType,
    filterInstallmentDue,
    filterAllowAssign,
    filterDue,
  } = yield select(state => state.sale)

  const success = yield call(getStatistics, {
    filterDatePreset,
    filterDateRangeStart,
    filterDateRangeEnd,
    searchText,
    filterProperty,
    filterProject,
    filterClient,
    filterReferrer,
    filterReferrerType,
    filterStatus,
    filterType,
    filterInstallmentDue,
    filterDue,
    filterAllowAssign,
  })

  if (success) {
    yield put({
      type: 'sale/SET_STATE',
      payload: {
        stats: success,
      },
    })
  }

  yield put({
    type: 'sale/SET_STATE',
    payload: {
      loadingStats: false,
    },
  })
}

export function* SET_DOC({ payload }) {
  const { saleRef } = payload

  yield put({
    type: 'sale/SET_STATE',
    payload: {
      loadingDoc: true,
    },
  })

  const doc = yield call(getSale, saleRef)

  yield put({
    type: 'sale/SET_STATE',
    payload: {
      doc,
      loadingDoc: false,
    },
  })
}

export function* SET_CURRENT_PAGE({ payload }) {
  const { page } = payload

  yield put({
    type: 'sale/SET_STATE',
    payload: {
      page,
    },
  })

  yield call(SET_DOCS)
}

export function* SET_LIMIT({ payload }) {
  const { limit } = payload

  yield put({
    type: 'sale/SET_STATE',
    payload: {
      limit,
    },
  })

  yield call(SET_DOCS)
}

export function* SET_SORTING({ payload }) {
  const { sortOrder, sortCol } = payload

  yield put({
    type: 'sale/SET_STATE',
    payload: {
      sortOrder,
      sortCol,
    },
  })

  yield call(SET_DOCS)
}

export function* SET_SEARCH_TEXT({ payload }) {
  const { searchText } = payload

  yield put({
    type: 'sale/SET_STATE',
    payload: {
      searchText,
      page: 1,
    },
  })
}

export function* THROTTLE_SET_SEARCH_TEXT() {
  yield call(SET_DOCS)
  yield call(SET_STATS)
}

export function* SET_FILTERS({ payload }) {
  const {
    filterProperty,
    filterProject,
    filterClient,
    filterReferrer,
    filterReferrerType,
    filterStatus,
    filterType,
    filterInstallmentDue,
    filterDue,
    filterAllowAssign,
  } = payload

  yield put({
    type: 'sale/SET_STATE',
    payload: {
      filterProperty,
      filterProject,
      filterClient,
      filterReferrer,
      filterReferrerType,
      filterStatus,
      filterType,
      filterInstallmentDue,
      filterDue,
      filterAllowAssign,
    },
  })

  yield call(SET_DOCS)
  yield call(SET_STATS)
}

export function* ASSIGN_PROPERTY({ payload }) {
  const { resetForm, resetStates } = payload

  yield put({
    type: 'sale/SET_STATE',
    payload: {
      loadingAction: true,
    },
  })

  const success = yield call(assignProperty, payload)

  if (success) {
    notification.success({
      message: 'Success',
      description: 'Property has been assigned to Sale',
    })

    if (resetForm) resetForm()
    if (resetStates) resetStates()
  }

  yield put({
    type: 'sale/SET_STATE',
    payload: {
      loadingAction: false,
    },
  })
}

export function* DELETE({ payload }) {
  const { saleRef } = payload

  yield put({
    type: 'sale/SET_STATE',
    payload: {
      loadingAction: true,
    },
  })

  const success = yield call(deleteSale, saleRef)

  if (success) {
    yield history.push('/sale')

    notification.success({
      message: 'Success',
      description: 'Property Sale Contract has been deleted',
    })
  }

  yield put({
    type: 'sale/SET_STATE',
    payload: {
      loadingAction: false,
    },
  })
}

export function* CREATE_PAYMENT({ payload }) {
  const { saleRef, resetForm, resetStates } = payload

  yield put({
    type: 'sale/SET_STATE',
    payload: {
      loadingAction: true,
    },
  })

  const success = yield call(addSalePayment, payload)

  if (success) {
    yield call(SET_DOC, { payload: { saleRef } })
    yield put({
      type: 'sale/SET_STATE',
      payload: {
        paymentsDrawerState: false,
        createPaymentModalState: false,
      },
    })
    notification.success({
      message: 'Success',
      description: 'Payment has been created for installment',
    })

    if (resetForm) resetForm()
    if (resetStates) resetStates()
  }

  yield put({
    type: 'sale/SET_STATE',
    payload: {
      loadingAction: false,
    },
  })
}

export function* COMPLETE({ payload }) {
  const { saleRef } = payload

  yield put({
    type: 'sale/SET_STATE',
    payload: {
      loadingAction: true,
    },
  })

  const success = yield call(completeSale, saleRef)

  if (success) {
    notification.success({
      message: 'Success',
      description: 'Property Sale contract has been completed',
    })

    yield call(SET_DOC, { payload: { saleRef } })
  }

  yield put({
    type: 'sale/SET_STATE',
    payload: {
      loadingAction: false,
    },
  })
}

export function* CANCEL({ payload }) {
  const { saleRef } = payload

  yield put({
    type: 'sale/SET_STATE',
    payload: {
      loadingAction: true,
    },
  })

  const success = yield call(cancelSale, saleRef)

  if (success) {
    notification.success({
      message: 'Success',
      description: 'Property Sale contract has been canceled',
    })

    yield call(SET_DOC, { payload: { saleRef } })
  }

  yield put({
    type: 'sale/SET_STATE',
    payload: {
      loadingAction: false,
    },
  })
}

export function* INVOICE_PDF({ payload }) {
  const { installmentRef } = payload

  yield put({
    type: 'sale/SET_STATE',
    payload: {
      loadingAction: true,
    },
  })

  const success = yield call(getInstallmentInvoicePdf, installmentRef)

  if (success) {
    /*  notification.success({
      message: 'Success',
      description: 'Property Sale contract has been canceled',
    })

    yield call(SET_DOC, { payload: { installmentRef } }) */
  }

  yield put({
    type: 'sale/SET_STATE',
    payload: {
      loadingAction: false,
    },
  })
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.CREATE, CREATE),
    takeEvery(actions.ASSIGN_PROPERTY, ASSIGN_PROPERTY),
    takeEvery(actions.CREATE_PAYMENT, CREATE_PAYMENT),
    takeEvery(actions.COMPLETE, COMPLETE),
    takeEvery(actions.CANCEL, CANCEL),
    takeEvery(actions.INVOICE_PDF, INVOICE_PDF),
    takeEvery(actions.DELETE, DELETE),
    takeEvery(actions.SET_DOCS, SET_DOCS),
    takeEvery(actions.SET_STATS, SET_STATS),
    takeEvery(actions.GET_CSV, GET_CSV),
    takeEvery(actions.SET_DOC, SET_DOC),
    takeEvery(actions.SET_CURRENT_PAGE, SET_CURRENT_PAGE),
    takeEvery(actions.SET_LIMIT, SET_LIMIT),
    takeEvery(actions.SET_SORTING, SET_SORTING),
    takeEvery(actions.SET_SEARCH_TEXT, SET_SEARCH_TEXT),
    debounce(500, actions.SET_SEARCH_TEXT, THROTTLE_SET_SEARCH_TEXT),
    takeEvery(actions.SET_FILTERS, SET_FILTERS),
  ])
}

import apiClient from '../axios'

export async function createExpenseRequest(body) {
  return apiClient
    .post(`/expense-request/create`, body.data, {
      headers: body.headers,
    })
    .then(response => (response && response.data ? response.data : false)) // eslint-disable-line
    .catch(error => console.log(error.message))
}

export async function getExpenseRequests({
  page,
  pages,
  searchText,
  limit,
  sortOrder,
  sortCol,
  filterType,
  filterCreatedBy,
  filterCreatedByType,
  filterStatus,
  filterDateRangeStart,
  filterDateRangeEnd,
}) {
  return apiClient
    .get(
      `/expense-request?page=${
        pages >= page ? page : 1
      }&search=${searchText.trim()}&limit=${limit}&sort_order=${sortOrder}&sort_col=${sortCol}&filter_type=${filterType}&filter_createdBy=${filterCreatedBy}&filter_createdByType=${filterCreatedByType}&filter_status=${filterStatus}&filter_dateRangeStart=${filterDateRangeStart}&filter_dateRangeEnd=${filterDateRangeEnd}`,
    )
    .then(response => (response && response.data ? response.data : false))
    .catch(error => console.log(error))
}

export async function getExpenseRequest(expenseRequestRef) {
  return apiClient
    .get(`/expense-request/${expenseRequestRef}`)
    .then(response => (response && response.data ? response.data : false))
    .catch(error => console.log(error))
}

export async function updateExpenseRequest(body) {
  return apiClient
    .post(`/expense-request/update/${body.expenseRequestRef}`, body)
    .then(response => (response && response.data ? response.data : false)) // eslint-disable-line
    .catch(error => console.log(error))
}

export async function submitExpenseRequest(expenseRequestRef) {
  return apiClient
    .post(`/expense-request/submit/${expenseRequestRef}`)
    .then(response => (response && response.data ? response.data : false)) // eslint-disable-line
    .catch(error => console.log(error))
}

export async function openExpenseRequest(expenseRequestRef) {
  return apiClient
    .post(`/expense-request/open/${expenseRequestRef}`)
    .then(response => (response && response.data ? response.data : false)) // eslint-disable-line
    .catch(error => console.log(error))
}

export async function completeExpenseRequest(expenseRequestRef) {
  return apiClient
    .post(`/expense-request/complete/${expenseRequestRef}`)
    .then(response => (response && response.data ? response.data : false)) // eslint-disable-line
    .catch(error => console.log(error))
}

export async function reviseExpenseRequest(expenseRequestRef) {
  return apiClient
    .post(`/expense-request/revise/${expenseRequestRef}`)
    .then(response => (response && response.data ? response.data : false)) // eslint-disable-line
    .catch(error => console.log(error))
}

export async function approveExpenseRequest(expenseRequestRef) {
  return apiClient
    .post(`/expense-request/approve/${expenseRequestRef}`)
    .then(response => (response && response.data ? response.data : false)) // eslint-disable-line
    .catch(error => console.log(error))
}

export async function rejectExpenseRequest(expenseRequestRef) {
  return apiClient
    .post(`/expense-request/reject/${expenseRequestRef}`)
    .then(response => (response && response.data ? response.data : false)) // eslint-disable-line
    .catch(error => console.log(error))
}

export async function createComment(body) {
  return apiClient
    .post(`/expense-request/comment/create`, body)
    .then(response => (response && response.data ? response.data : false)) // eslint-disable-line
    .catch(error => console.log(error.message))
}

export async function updateComment(body) {
  return apiClient
    .post(`/expense-request/comment/update/${body.commentRef}`, body)
    .then(response => (response && response.data ? response.data : false)) // eslint-disable-line
    .catch(error => console.log(error))
}

export async function addCommentLike(commentRef) {
  return apiClient
    .post(`/expense-request/comment/add-like/${commentRef}`)
    .then(response => (response && response.data ? response.data : false)) // eslint-disable-line
    .catch(error => console.log(error))
}

export async function removeCommentLike(commentRef) {
  return apiClient
    .post(`/expense-request/comment/remove-like/${commentRef}`)
    .then(response => (response && response.data ? response.data : false)) // eslint-disable-line
    .catch(error => console.log(error))
}

export async function deleteComment(commentRef) {
  return apiClient
    .post(`/expense-request/comment/delete/${commentRef}`)
    .then(response => (response && response.data ? response.data : false)) // eslint-disable-line
    .catch(error => console.log(error))
}

export async function deleteExpenseRequest(body) {
  return apiClient
    .post(`/expense-request/delete/${body.expenseRequestRef}`, body)
    .then(response => (response && response.data ? response.data : false)) // eslint-disable-line
    .catch(error => console.log(error))
}

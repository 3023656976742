import { eventChannel /* END, channel */ /* delay */ } from 'redux-saga'
import {
  all,
  put,
  call,
  takeEvery,
  debounce,
  select,
  take,
  fork,
  cancel,
} from /* , race */ 'redux-saga/effects'
import socketIOClient from 'socket.io-client'
import store from 'store'
import { history } from 'index'
import {
  createTask,
  getTask,
  getTasks,
  updateTask,
  updateTaskStatusToTodo,
  updateTaskStatusToInProgress,
  updateTaskStatusToCompleted,
  updateTaskStatusToClosed,
  createCheckList,
  updateCheckList,
  updateCheckListResolve,
  deleteCheckList,
  createComment,
  updateComment,
  addCommentLike,
  removeCommentLike,
  deleteComment,
  deleteTask,
} from 'services/api/task'
import { notification } from 'antd'
import actions from './actions'

/* eslint-disable */

export function* CREATE({ payload }) {
  const { contextDispatch, resetForm, resetStates } = payload

  yield put({
    type: 'task/SET_STATE',
    payload: {
      loadingAction: true,
    },
  })

  const success = yield call(createTask, payload)

  if (success) {
    notification.success({
      key: 'success',
      message: 'Success',
      description: 'Task has been added',
    })

    if (contextDispatch && contextDispatch.type) yield put(contextDispatch)
    if (resetForm) resetForm()
    if (resetStates) resetStates()
  }

  yield put({
    type: 'task/SET_STATE',
    payload: {
      loadingAction: false,
    },
  })
}

export function* SET_DOCS() {
  yield put({
    type: 'task/SET_STATE',
    payload: {
      loadingDocs: true,
    },
  })

  // Get queries
  const {
    limit,
    searchText,
    page,
    pages,
    sortOrder,
    sortCol,
    filterStatus,
    filterDueDate,
    filterPriority,
    filterDepartment,
    filterCategory,
    filterSubCategory,
    filterHasBlockingDependency,
    filterHasWaitingOnDependency,
    filterCreatedBy,
    filterCollaborators,
    filterAssignees,
    filterFollowers,
    filterType,
    filterContractors,
    filterOwner,
    filterOwnerModel,
  } = yield select(state => state.task)

  const success = yield call(getTasks, {
    limit,
    searchText,
    page,
    pages,
    sortOrder,
    sortCol,
    filterStatus,
    filterDueDate,
    filterPriority,
    filterDepartment,
    filterCategory,
    filterSubCategory,
    filterHasBlockingDependency,
    filterHasWaitingOnDependency,
    filterCreatedBy,
    filterCollaborators,
    filterAssignees,
    filterFollowers,
    filterType,
    filterContractors,
    filterOwner,
    filterOwnerModel,
  })

  if (success) {
    const { docs, totalDocs, totalPages } = success

    yield put({
      type: 'task/SET_STATE',
      payload: {
        docs,
        total: totalDocs,
        pages: totalPages,
      },
    })
  }

  yield put({
    type: 'task/SET_STATE',
    payload: {
      loadingDocs: false,
    },
  })
}

export function* SET_DOC({ payload }) {
  const { taskRef } = payload

  yield put({
    type: 'task/SET_STATE',
    payload: {
      loadingDoc: true,
    },
  })

  const doc = yield call(getTask, taskRef)

  yield put({
    type: 'task/SET_STATE',
    payload: {
      doc,
      loadingDoc: false,
    },
  })
}

export function* SET_CURRENT_PAGE({ payload }) {
  const { page } = payload

  yield put({
    type: 'task/SET_STATE',
    payload: {
      page,
    },
  })

  // yield call(SET_DOCS)
  yield put({
    type: 'task/RE_SET_DOCS_IO',
    payload: {},
  })
}

export function* SET_LIMIT({ payload }) {
  const { limit } = payload

  yield put({
    type: 'task/SET_STATE',
    payload: {
      limit,
    },
  })

  // yield call(SET_DOCS)
  yield put({
    type: 'task/RE_SET_DOCS_IO',
    payload: {},
  })
}

export function* SET_SORTING({ payload }) {
  const { sortOrder, sortCol } = payload

  yield put({
    type: 'task/SET_STATE',
    payload: {
      sortOrder,
      sortCol,
    },
  })

  // yield call(SET_DOCS)
  yield put({
    type: 'task/RE_SET_DOCS_IO',
    payload: {},
  })
}

export function* SET_SEARCH_TEXT({ payload }) {
  const { searchText } = payload

  yield put({
    type: 'task/SET_STATE',
    payload: {
      searchText,
      page: 1,
    },
  })
}

export function* THROTTLE_SET_SEARCH_TEXT() {
  yield call(SET_DOCS)
}

export function* SET_FILTERS({ payload }) {
  const {
    filterStatus,
    filterDueDate,
    filterPriority,
    filterDepartment,
    filterCategory,
    filterSubCategory,
    filterHasBlockingDependency,
    filterHasWaitingOnDependency,
    filterCreatedBy,
    filterCollaborators,
    filterAssignees,
    filterFollowers,
    filterType,
    filterContractors,
    filterOwner,
    filterOwnerModel,
  } = payload

  yield put({
    type: 'task/SET_STATE',
    payload: {
      filterStatus,
      filterDueDate,
      filterPriority,
      filterDepartment,
      filterCategory,
      filterSubCategory,
      filterHasBlockingDependency,
      filterHasWaitingOnDependency,
      filterCreatedBy,
      filterCollaborators,
      filterAssignees,
      filterFollowers,
      filterType,
      filterContractors,
      filterOwner,
      filterOwnerModel,
    },
  })

  yield call(SET_DOCS)
}

export function* UPDATE({ payload }) {
  const { resetForm, resetStates, taskRef, shouldUpdate } = payload

  yield put({
    type: 'task/SET_STATE',
    payload: {
      loadingAction: true,
    },
  })

  const success = yield call(updateTask, payload)

  if (success) {
    notification.success({
      key: 'success',
      message: 'Success',
      description: 'Task has been updated',
    })

    if (resetForm) resetForm()
    if (resetStates) resetStates()

    if (shouldUpdate) yield call(SET_DOC, { payload: { taskRef } })
  }

  yield put({
    type: 'task/SET_STATE',
    payload: {
      loadingAction: false,
    },
  })
}

export function* UPDATE_STATUS_TO_TODO({ payload }) {
  const { taskRef, resetForm, resetStates } = payload

  yield put({
    type: 'task/SET_STATE',
    payload: {
      loadingAction: true,
    },
  })

  const success = yield call(updateTaskStatusToTodo, taskRef)

  if (success) {
    notification.success({
      key: 'success',
      message: 'Success',
      description: 'Task Status has been updated to todo',
    })

    if (resetForm) resetForm()
    if (resetStates) resetStates()

    yield call(SET_DOC, { payload: { taskRef } })
  }

  yield put({
    type: 'task/SET_STATE',
    payload: {
      loadingAction: false,
    },
  })
}

export function* UPDATE_STATUS_TO_IN_PROGRESS({ payload }) {
  const { taskRef, resetForm, resetStates } = payload

  yield put({
    type: 'task/SET_STATE',
    payload: {
      loadingAction: true,
    },
  })

  const success = yield call(updateTaskStatusToInProgress, taskRef)

  if (success) {
    notification.success({
      key: 'success',
      message: 'Success',
      description: 'Task Status has been updated to in-progress',
    })

    if (resetForm) resetForm()
    if (resetStates) resetStates()

    yield call(SET_DOC, { payload: { taskRef } })
  }

  yield put({
    type: 'task/SET_STATE',
    payload: {
      loadingAction: false,
    },
  })
}

export function* UPDATE_STATUS_TO_COMPLETED({ payload }) {
  const { taskRef, resetForm, resetStates } = payload

  yield put({
    type: 'task/SET_STATE',
    payload: {
      loadingAction: true,
    },
  })

  const success = yield call(updateTaskStatusToCompleted, taskRef)

  if (success) {
    notification.success({
      key: 'success',
      message: 'Success',
      description: 'Task Status has been updated to completed',
    })

    if (resetForm) resetForm()
    if (resetStates) resetStates()

    yield call(SET_DOC, { payload: { taskRef } })
  }

  yield put({
    type: 'task/SET_STATE',
    payload: {
      loadingAction: false,
    },
  })
}

export function* UPDATE_STATUS_TO_CLOSED({ payload }) {
  const { taskRef, resetForm, resetStates } = payload

  yield put({
    type: 'task/SET_STATE',
    payload: {
      loadingAction: true,
    },
  })

  const success = yield call(updateTaskStatusToClosed, taskRef)

  if (success) {
    notification.success({
      key: 'success',
      message: 'Success',
      description: 'Task Status has been updated to closed',
    })

    if (resetForm) resetForm()
    if (resetStates) resetStates()

    yield call(SET_DOC, { payload: { taskRef } })
  }

  yield put({
    type: 'task/SET_STATE',
    payload: {
      loadingAction: false,
    },
  })
}

export function* CREATE_CHECKLIST({ payload }) {
  const { resetForm, resetStates, taskRef } = payload

  yield put({
    type: 'task/SET_STATE',
    payload: {
      loadingAction: true,
    },
  })

  const success = yield call(createCheckList, payload)

  if (success) {
    notification.success({
      key: 'success',
      message: 'Success',
      description: 'Checklist has been added',
    })

    if (resetForm) resetForm()
    if (resetStates) resetStates()

    yield call(SET_DOC, { payload: { taskRef } })
  }

  yield put({
    type: 'task/SET_STATE',
    payload: {
      loadingAction: false,
    },
  })
}

export function* UPDATE_CHECKLIST({ payload }) {
  const { resetForm, resetStates, taskRef, shouldUpdate } = payload

  yield put({
    type: 'task/SET_STATE',
    payload: {
      loadingAction: true,
    },
  })

  const success = yield call(updateCheckList, payload)

  if (success) {
    notification.success({
      key: 'success',
      message: 'Success',
      description: 'Checklist has been updated',
    })

    if (resetForm) resetForm()
    if (resetStates) resetStates()

    if (shouldUpdate) yield call(SET_DOC, { payload: { taskRef } })
  }

  yield put({
    type: 'task/SET_STATE',
    payload: {
      loadingAction: false,
    },
  })
}

export function* RESOLVE_CHECKLIST({ payload }) {
  const { resetForm, resetStates, taskRef } = payload

  yield put({
    type: 'task/SET_STATE',
    payload: {
      loadingAction: true,
    },
  })

  const success = yield call(updateCheckListResolve, { ...payload, status: 'true' })

  if (success) {
    notification.success({
      key: 'success',
      message: 'Success',
      description: 'Checklist has been resolved',
    })

    if (resetForm) resetForm()
    if (resetStates) resetStates()

    yield call(SET_DOC, { payload: { taskRef } })
  }

  yield put({
    type: 'task/SET_STATE',
    payload: {
      loadingAction: false,
    },
  })
}

export function* UNRESOLVE_CHECKLIST({ payload }) {
  const { resetForm, resetStates, taskRef } = payload

  yield put({
    type: 'task/SET_STATE',
    payload: {
      loadingAction: true,
    },
  })

  const success = yield call(updateCheckListResolve, { ...payload, status: 'false' })

  if (success) {
    notification.success({
      key: 'success',
      message: 'Success',
      description: 'Checklist has been unresolved',
    })

    if (resetForm) resetForm()
    if (resetStates) resetStates()

    yield call(SET_DOC, { payload: { taskRef } })
  }

  yield put({
    type: 'task/SET_STATE',
    payload: {
      loadingAction: false,
    },
  })
}

export function* DELETE_CHECKLIST({ payload }) {
  const { taskRef, checkListRef } = payload

  yield put({
    type: 'task/SET_STATE',
    payload: {
      loadingAction: true,
    },
  })

  const success = yield call(deleteCheckList, checkListRef)

  if (success) {
    notification.success({
      key: 'success',
      message: 'Success',
      description: 'CheckList has been deleted',
    })

    yield call(SET_DOC, { payload: { taskRef } })
  }

  yield put({
    type: 'task/SET_STATE',
    payload: {
      loadingAction: false,
    },
  })
}

export function* CREATE_COMMENT({ payload }) {
  const { resetForm, resetStates, taskRef } = payload

  yield put({
    type: 'task/SET_STATE',
    payload: {
      loadingAction: true,
    },
  })

  const success = yield call(createComment, payload)

  if (success) {
    notification.success({
      key: 'success',
      message: 'Success',
      description: 'Comment has been added',
    })

    if (resetForm) resetForm()
    if (resetStates) resetStates()

    yield call(SET_DOC, { payload: { taskRef } })
  }

  yield put({
    type: 'task/SET_STATE',
    payload: {
      loadingAction: false,
    },
  })
}

export function* UPDATE_COMMENT({ payload }) {
  const { resetForm, resetStates, taskRef } = payload

  yield put({
    type: 'task/SET_STATE',
    payload: {
      loadingAction: true,
    },
  })

  const success = yield call(updateComment, payload)

  if (success) {
    notification.success({
      key: 'success',
      message: 'Success',
      description: 'Comment has been updated',
    })

    if (resetForm) resetForm()
    if (resetStates) resetStates()

    yield call(SET_DOC, { payload: { taskRef } })
  }

  yield put({
    type: 'task/SET_STATE',
    payload: {
      loadingAction: false,
    },
  })
}

export function* LIKE_COMMENT({ payload }) {
  const { resetForm, resetStates, commentRef, taskRef } = payload

  yield put({
    type: 'task/SET_STATE',
    payload: {
      loadingAction: true,
    },
  })

  const success = yield call(addCommentLike, commentRef)

  if (success) {
    notification.success({
      key: 'success',
      message: 'Success',
      description: 'Comment was liked by you',
    })

    if (resetForm) resetForm()
    if (resetStates) resetStates()

    yield call(SET_DOC, { payload: { taskRef } })
  }

  yield put({
    type: 'task/SET_STATE',
    payload: {
      loadingAction: false,
    },
  })
}

export function* UNLIKE_COMMENT({ payload }) {
  const { resetForm, resetStates, commentRef, taskRef } = payload

  yield put({
    type: 'task/SET_STATE',
    payload: {
      loadingAction: true,
    },
  })

  const success = yield call(removeCommentLike, commentRef)

  if (success) {
    notification.success({
      key: 'success',
      message: 'Success',
      description: 'Comment was unliked by you',
    })

    if (resetForm) resetForm()
    if (resetStates) resetStates()

    yield call(SET_DOC, { payload: { taskRef } })
  }

  yield put({
    type: 'task/SET_STATE',
    payload: {
      loadingAction: false,
    },
  })
}

export function* DELETE_COMMENT({ payload }) {
  const { taskRef, commentRef } = payload

  yield put({
    type: 'task/SET_STATE',
    payload: {
      loadingAction: true,
    },
  })

  const success = yield call(deleteComment, commentRef)

  if (success) {
    notification.success({
      key: 'success',
      message: 'Success',
      description: 'Comment has been deleted',
    })

    yield call(SET_DOC, { payload: { taskRef } })
  }

  yield put({
    type: 'task/SET_STATE',
    payload: {
      loadingAction: false,
    },
  })
}

export function* DELETE({ payload }) {
  const { taskRef } = payload

  yield put({
    type: 'task/SET_STATE',
    payload: {
      loadingAction: true,
    },
  })

  const success = yield call(deleteTask, taskRef)

  if (success) {
    yield history.push('/task')

    notification.success({
      key: 'success',
      message: 'Success',
      description: 'Task has been deleted',
    })
  }

  yield put({
    type: 'task/SET_STATE',
    payload: {
      loadingAction: false,
    },
  })
}

export function* connect() {
  yield put({
    type: 'task/SET_STATE',
    payload: {
      socket: socketIOClient('/task', {
        auth: {
          refreshToken: store.get('refreshToken'),
          accessToken: store.get('accessToken'),
        },
        // forceNew: true,
      }),
    },
  })

  const { socket, clientOnlineStatus } = yield select(state => state.task)

  return new Promise(resolve => {
    socket.on('connect', () => {
      return resolve(socket)
    })

    // socket.on('connect_error', () => {
    //   socket.connect()
    // })

    // socket.io.on('reconnect', () => {
    //   console.log('reconnecting')
    // })

    // socket.io.on('reconnect_failed', () => {
    //   console.log('reconnection failed')
    // })

    // socket.io.on('disconnect', () => {
    //   connect()
    // })
  })
}

export function subscribeToTask({ payload }) {
  const { taskRef, socket, location } = payload
  socket.emit('task:get_one', { taskRef })
  return eventChannel(emit => {
    const handler = data => {
      if (data) {
        emit(data)
      } else {
        notification.error({
          key: 'error',
          message: 'no data in task stream',
        })
      }
    }

    socket.on('task', handler)
    socket.on('error', data =>
      /* data.message === 'Bad taskRef' &&  */ location.pathname.split('/')[2]
        ? notification.error({
            key: 'error',
            message: data.message,
          })
        : null,
    )

    return () => {
      socket.off('task', handler)
    }
  })
}

function* SET_DOC_IO({ payload }) {
  const { taskRef } = payload
  const { socket, clientOnlineStatus } = yield select(state => state.task)
  const { location } = yield select(state => state.router)
  const channel = yield call(subscribeToTask, { payload: { taskRef, socket, location } })

  while (true) {
    const data = yield take(channel)
    yield put({
      type: 'task/SET_STATE',
      payload: {
        doc: data,
        loadingDoc: false,
      },
    })
  }
}

export function subscribeToTasks({ payload }) {
  const { socket, location, clientOnlineStatus } = payload
  socket.emit('task:get_many', { ...payload, socket: {} })

  return eventChannel(emit => {
    const handler = data => {
      if (data) {
        emit(data)
      } else {
        notification.error({
          key: 'error',
          message: 'no data in task stream',
        })
      }
    }

    socket.on('tasks', handler)
    socket.on('error', data =>
      data.message !== 'Bad taskRef' && !location.pathname.split('/')[2]
        ? notification.error({
            key: 'error',
            message: data.message,
          })
        : null,
    )

    return () => {
      socket.off('tasks', handler)
    }
  })
}

function* SET_DOCS_IO(/* { payload } */) {
  const { location } = yield select(state => state.router)

  // Get queries
  const {
    socket,
    limit,
    searchText,
    page,
    pages,
    sortOrder,
    sortCol,
    filterStatus,
    filterDueDate,
    filterPriority,
    filterDepartment,
    filterCategory,
    filterSubCategory,
    filterHasBlockingDependency,
    filterHasWaitingOnDependency,
    filterCreatedBy,
    filterCollaborators,
    filterAssignees,
    filterFollowers,
    filterType,
    filterContractors,
    filterOwner,
    filterOwnerModel,
  } = yield select(state => state.task)

  // convert to query_string format
  const channel = yield call(subscribeToTasks, {
    payload: {
      socket,
      location,
      limit,
      search: searchText,
      page,
      pages,
      sort_order: sortOrder,
      sort_col: sortCol,
      filter_status: filterStatus,
      filter_dueDate: filterDueDate,
      filter_priority: filterPriority,
      filter_department: filterDepartment,
      filter_category: filterCategory,
      filter_subCategory: filterSubCategory,
      filter_hasBlockingDependency: filterHasBlockingDependency,
      filter_hasWaitingOnDependency: filterHasWaitingOnDependency,
      filter_createdBy: filterCreatedBy,
      filter_collaborators: filterCollaborators,
      filter_assignees: filterAssignees,
      filter_followers: filterFollowers,
      filter_type: filterType,
      filter_contractors: filterContractors,
      filter_owner: filterOwner,
      filter_ownerModel: filterOwnerModel,
    },
  })

  while (true) {
    const data = yield take(channel)
    const { docs, totalDocs: total, totalPages } = data

    yield put({
      type: 'task/SET_STATE',
      payload: {
        docs,
        total,
        pages: totalPages,
        loadingDocs: false,
      },
    })
  }
}

export function* SET_FILTERS_IO({ payload }) {
  const {
    filterStatus,
    filterDueDate,
    filterPriority,
    filterDepartment,
    filterCategory,
    filterSubCategory,
    filterHasBlockingDependency,
    filterHasWaitingOnDependency,
    filterCreatedBy,
    filterCollaborators,
    filterAssignees,
    filterFollowers,
    filterType,
    filterContractors,
    filterOwner,
    filterOwnerModel,
  } = payload
  console.log(payload)

  yield put({
    type: 'task/SET_STATE',
    payload: {
      filterStatus,
      filterDueDate,
      filterPriority,
      filterDepartment,
      filterCategory,
      filterSubCategory,
      filterHasBlockingDependency,
      filterHasWaitingOnDependency,
      filterCreatedBy,
      filterCollaborators,
      filterAssignees,
      filterFollowers,
      filterType,
      filterContractors,
      filterOwner,
      filterOwnerModel,
    },
  })

  yield put({
    type: 'task/RE_SET_DOCS_IO',
    payload: {},
  })
}

function* RE_SET_DOCS_IO(/* { payload } */) {
  while (true) {
    yield take('task/RE_SET_DOCS_IO')

    const task = yield fork(SET_DOCS_IO, {})
    yield cancel(task)
  }
}

export function* SET_SEARCH_TEXT_IO({ payload }) {
  const { searchText } = payload

  yield put({
    type: 'task/SET_STATE',
    payload: {
      searchText,
      page: 1,
    },
  })
}

export function* THROTTLE_SET_SEARCH_TEXT_IO() {
  yield put({
    type: 'task/RE_SET_DOCS_IO',
    payload: {},
  })
}

function* CREATE_IO() {
  let wrapPayload, response
  while (true) {
    wrapPayload = yield take('task/CREATE_IO')

    yield put({
      type: 'task/SET_STATE',
      payload: { loadingAction: true },
    })

    const { socket, clientOnlineStatus } = yield select(state => state.task)

    if (socket.connected && clientOnlineStatus) {
      socket.volatile.emit('task:create', wrapPayload.payload)
    } else {
      notification.error({
        key: 'error',
        message: 'Error',
        description: 'You are offline',
      })
    }

    try {
      response = yield new Promise((resolve, reject) => {
        socket.on('refresh_tasks', () => resolve(true))
        socket.on('error', error => reject(error))
      })
    } catch (error) {
      console.log(error)
      notification.error({
        key: 'error',
        message: 'Error',
        description: error.message,
      })
    }

    if (response) {
      if (wrapPayload.payload.resetStates) wrapPayload.payload.resetStates()
      notification.success({
        key: 'success',
        message: 'Success',
        description: 'Task created',
      })
      yield put({
        type: 'task/SET_STATE',
        payload: { modalState: false },
      })
      const task = yield fork(SET_DOCS_IO, {})
      const task2 = yield fork(SET_DOC_IO, { payload: { taskRef: wrapPayload.payload.taskRef } })
      yield cancel(task)
      yield cancel(task2)
    }

    yield put({
      type: 'task/SET_STATE',
      payload: { loadingAction: false },
    })
  }
}

function* DUPLICATE_IO({ payload }) {
  let wrapPayload, response
  while (true) {
    wrapPayload = yield take('task/DUPLICATE_IO')

    yield put({
      type: 'task/SET_STATE',
      payload: { loadingAction: true },
    })

    const { socket, clientOnlineStatus } = yield select(state => state.task)

    if (socket.connected && clientOnlineStatus) {
      socket.volatile.emit('task:duplicate', wrapPayload.payload)
    } else {
      notification.error({
        key: 'error',
        message: 'Error',
        description: 'You are offline',
      })
    }

    try {
      response = yield new Promise((resolve, reject) => {
        socket.on('refresh_task', resData => resolve(resData))
        socket.on('error', error => reject(error))
      })
    } catch (error) {
      console.log(error)
      notification.error({
        key: 'error',
        message: 'Error',
        description: error.message,
      })
    }

    if (response) {
      if (wrapPayload.payload.resetStates) wrapPayload.payload.resetStates()

      yield history.push(`/task/${response.taskRef}`)
      /* notification.success({
      key: 'success',
        message: 'Success',
        description: 'Task has been updated',
      }) */

      /* const task = yield fork(SET_DOC_IO, {
        payload: {
          taskRef: wrapPayload.payload.pageRef
            ? wrapPayload.payload.pageRef
            : wrapPayload.payload.taskRef,
        },
      })
      yield cancel(task) */
    }

    yield put({
      type: 'task/SET_STATE',
      payload: { loadingAction: false },
    })
  }
}

function* UPDATE_IO({ payload }) {
  let wrapPayload, response
  while (true) {
    wrapPayload = yield take('task/UPDATE_IO')

    yield put({
      type: 'task/SET_STATE',
      payload: { loadingAction: true },
    })

    const { socket, clientOnlineStatus } = yield select(state => state.task)

    if (socket.connected && clientOnlineStatus) {
      socket.volatile.emit('task:update', wrapPayload.payload)
    } else {
      notification.error({
        key: 'error',
        message: 'Error',
        description: 'You are offline',
      })
    }

    try {
      response = yield new Promise((resolve, reject) => {
        socket.on('refresh_task', () => resolve(true))
        socket.on('error', error => reject(error))
      })
    } catch (error) {
      console.log(error)
      notification.error({
        key: 'error',
        message: 'Error',
        description: error.message,
      })
    }

    if (response) {
      if (wrapPayload.payload.resetStates) wrapPayload.payload.resetStates()
      /* notification.success({
      key: 'success',
        message: 'Success',
        description: 'Task has been updated',
      }) */

      const task = yield fork(SET_DOC_IO, {
        payload: {
          taskRef: wrapPayload.payload.pageRef
            ? wrapPayload.payload.pageRef
            : wrapPayload.payload.taskRef,
        },
      })
      yield cancel(task)
    }

    yield put({
      type: 'task/SET_STATE',
      payload: { loadingAction: false },
    })
  }
}

function* UPDATE_STATUS_TO_TODO_IO({ payload }) {
  let wrapPayload, response
  while (true) {
    wrapPayload = yield take('task/UPDATE_STATUS_TO_TODO_IO')

    yield put({
      type: 'task/SET_STATE',
      payload: { loadingAction: true },
    })

    const { socket, clientOnlineStatus } = yield select(state => state.task)

    if (socket.connected && clientOnlineStatus) {
      socket.volatile.emit('task:update_status_todo', wrapPayload.payload)
    } else {
      notification.error({
        key: 'error',
        message: 'Error',
        description: 'You are offline',
      })
    }

    try {
      response = yield new Promise((resolve, reject) => {
        socket.on('refresh_task', () => resolve(true))
        socket.on('error', error => reject(error))
      })
    } catch (error) {
      console.log(error)
      notification.error({
        key: 'error',
        message: 'Error',
        description: error.message,
      })
    }

    if (response) {
      if (wrapPayload.payload.resetStates) wrapPayload.payload.resetStates()
      /* notification.success({
      key: 'success',
        message: 'Success',
        description: 'Task status has been updated to todo',
      }) */

      const task = yield fork(SET_DOC_IO, {
        payload: {
          taskRef: wrapPayload.payload.pageRef
            ? wrapPayload.payload.pageRef
            : wrapPayload.payload.taskRef,
        },
      })
      yield cancel(task)
    }

    yield put({
      type: 'task/SET_STATE',
      payload: { loadingAction: false },
    })
  }
}

function* UPDATE_STATUS_TO_IN_PROGRESS_IO({ payload }) {
  let wrapPayload, response
  while (true) {
    wrapPayload = yield take('task/UPDATE_STATUS_TO_IN_PROGRESS_IO')

    yield put({
      type: 'task/SET_STATE',
      payload: { loadingAction: true },
    })

    const { socket, clientOnlineStatus } = yield select(state => state.task)

    if (socket.connected && clientOnlineStatus) {
      socket.volatile.emit('task:update_status_in_progress', wrapPayload.payload)
    } else {
      notification.error({
        key: 'error',
        message: 'Error',
        description: 'You are offline',
      })
    }

    try {
      response = yield new Promise((resolve, reject) => {
        socket.on('refresh_task', () => resolve(true))
        socket.on('error', error => reject(error))
      })
    } catch (error) {
      console.log(error)
      notification.error({
        key: 'error',
        message: 'Error',
        description: error.message,
      })
    }

    if (response) {
      if (wrapPayload.payload.resetStates) wrapPayload.payload.resetStates()
      /* notification.success({
      key: 'success',
        message: 'Success',
        description: 'Task Status has been updated to in-progress',
      }) */

      const task = yield fork(SET_DOC_IO, {
        payload: {
          taskRef: wrapPayload.payload.pageRef
            ? wrapPayload.payload.pageRef
            : wrapPayload.payload.taskRef,
        },
      })
      yield cancel(task)
    }

    yield put({
      type: 'task/SET_STATE',
      payload: { loadingAction: false },
    })
  }
}

function* UPDATE_STATUS_TO_COMPLETED_IO({ payload }) {
  let wrapPayload, response
  while (true) {
    wrapPayload = yield take('task/UPDATE_STATUS_TO_COMPLETED_IO')

    yield put({
      type: 'task/SET_STATE',
      payload: { loadingAction: true },
    })

    const { socket, clientOnlineStatus } = yield select(state => state.task)

    if (socket.connected && clientOnlineStatus) {
      socket.volatile.emit('task:update_status_completed', wrapPayload.payload)
    } else {
      notification.error({
        key: 'error',
        message: 'Error',
        description: 'You are offline',
      })
    }

    try {
      response = yield new Promise((resolve, reject) => {
        socket.on('refresh_task', () => resolve(true))
        socket.on('error', error => reject(error))
      })
    } catch (error) {
      console.log(error)
      notification.error({
        key: 'error',
        message: 'Error',
        description: error.message,
      })
    }

    if (response) {
      if (wrapPayload.payload.resetStates) wrapPayload.payload.resetStates()
      /* notification.success({
      key: 'success',
        message: 'Success',
        description: 'Task Status has been updated to completed',
      }) */

      const task = yield fork(SET_DOC_IO, {
        payload: {
          taskRef: wrapPayload.payload.pageRef
            ? wrapPayload.payload.pageRef
            : wrapPayload.payload.taskRef,
        },
      })
      yield cancel(task)
    }

    yield put({
      type: 'task/SET_STATE',
      payload: { loadingAction: false },
    })
  }
}

function* UPDATE_STATUS_TO_CLOSED_IO({ payload }) {
  let wrapPayload, response
  while (true) {
    wrapPayload = yield take('task/UPDATE_STATUS_TO_CLOSED_IO')

    yield put({
      type: 'task/SET_STATE',
      payload: { loadingAction: true },
    })

    const { socket, clientOnlineStatus } = yield select(state => state.task)

    if (socket.connected && clientOnlineStatus) {
      socket.volatile.emit('task:update_status_closed', wrapPayload.payload)
    } else {
      notification.error({
        key: 'error',
        message: 'Error',
        description: 'You are offline',
      })
    }

    try {
      response = yield new Promise((resolve, reject) => {
        socket.on('refresh_task', () => resolve(true))
        socket.on('error', error => reject(error))
      })
    } catch (error) {
      console.log(error)
      notification.error({
        key: 'error',
        message: 'Error',
        description: error.message,
      })
    }

    if (response) {
      if (wrapPayload.payload.resetStates) wrapPayload.payload.resetStates()
      /* notification.success({
      key: 'success',
        message: 'Success',
        description: 'Task Status has been updated to closed',
      }) */

      const task = yield fork(SET_DOC_IO, {
        payload: {
          taskRef: wrapPayload.payload.pageRef
            ? wrapPayload.payload.pageRef
            : wrapPayload.payload.taskRef,
        },
      })
      yield cancel(task)
    }

    yield put({
      type: 'task/SET_STATE',
      payload: { loadingAction: false },
    })
  }
}

function* CREATE_CHECKLIST_IO({ payload }) {
  let wrapPayload, response
  while (true) {
    wrapPayload = yield take('task/CREATE_CHECKLIST_IO')

    yield put({
      type: 'task/SET_STATE',
      payload: { loadingAction: true },
    })

    const { socket, clientOnlineStatus } = yield select(state => state.task)

    if (socket.connected && clientOnlineStatus) {
      socket.volatile.emit('task:create_checklist', wrapPayload.payload)
    } else {
      notification.error({
        key: 'error',
        message: 'Error',
        description: 'You are offline',
      })
    }

    try {
      response = yield new Promise((resolve, reject) => {
        socket.on('refresh_task', () => resolve(true))
        socket.on('error', error => reject(error))
      })
    } catch (error) {
      console.log(error)
      notification.error({
        key: 'error',
        message: 'Error',
        description: error.message,
      })
    }

    if (response) {
      if (wrapPayload.payload.resetStates) wrapPayload.payload.resetStates()
      /* notification.success({
      key: 'success',
        message: 'Success',
        description: 'Checklist has been added',
      }) */

      const task = yield fork(SET_DOC_IO, { payload: { taskRef: wrapPayload.payload.taskRef } })
      yield cancel(task)
    }

    yield put({
      type: 'task/SET_STATE',
      payload: { loadingAction: false },
    })
  }
}

function* UPDATE_CHECKLIST_IO({ payload }) {
  let wrapPayload, response
  while (true) {
    wrapPayload = yield take('task/UPDATE_CHECKLIST_IO')

    yield put({
      type: 'task/SET_STATE',
      payload: { loadingAction: true },
    })

    const { socket, clientOnlineStatus } = yield select(state => state.task)

    if (socket.connected && clientOnlineStatus) {
      socket.volatile.emit('task:update_checklist', wrapPayload.payload)
    } else {
      notification.error({
        key: 'error',
        message: 'Error',
        description: 'You are offline',
      })
    }

    try {
      response = yield new Promise((resolve, reject) => {
        socket.on('refresh_task', () => resolve(true))
        socket.on('error', error => reject(error))
      })
    } catch (error) {
      console.log(error)
      notification.error({
        key: 'error',
        message: 'Error',
        description: error.message,
      })
    }

    if (response) {
      if (wrapPayload.payload.resetStates) wrapPayload.payload.resetStates()
      /* notification.success({
      key: 'success',
        message: 'Success',
        description: 'Checklist has been updated',
      }) */

      const task = yield fork(SET_DOC_IO, { payload: { taskRef: wrapPayload.payload.taskRef } })
      yield cancel(task)
    }

    yield put({
      type: 'task/SET_STATE',
      payload: { loadingAction: false },
    })
  }
}

function* RESOLVE_CHECKLIST_IO({ payload }) {
  let wrapPayload, response
  while (true) {
    wrapPayload = yield take('task/RESOLVE_CHECKLIST_IO')

    yield put({
      type: 'task/SET_STATE',
      payload: { loadingAction: true },
    })

    const { socket, clientOnlineStatus } = yield select(state => state.task)

    if (socket.connected && clientOnlineStatus) {
      socket.volatile.emit('task:update_checklist_resolve', {
        ...wrapPayload.payload,
        status: 'true',
      })
    } else {
      notification.error({
        key: 'error',
        message: 'Error',
        description: 'You are offline',
      })
    }

    try {
      response = yield new Promise((resolve, reject) => {
        socket.on('refresh_task', () => resolve(true))
        socket.on('error', error => reject(error))
      })
    } catch (error) {
      console.log(error)
      notification.error({
        key: 'error',
        message: 'Error',
        description: error.message,
      })
    }

    if (response) {
      if (wrapPayload.payload.resetStates) wrapPayload.payload.resetStates()
      /* notification.success({
      key: 'success',
        message: 'Success',
        description: 'Checklist has been resolved',
      }) */

      const task = yield fork(SET_DOC_IO, { payload: { taskRef: wrapPayload.payload.taskRef } })
      yield cancel(task)
    }

    yield put({
      type: 'task/SET_STATE',
      payload: { loadingAction: false },
    })
  }
}

function* UNRESOLVE_CHECKLIST_IO({ payload }) {
  let wrapPayload, response
  while (true) {
    wrapPayload = yield take('task/UNRESOLVE_CHECKLIST_IO')

    yield put({
      type: 'task/SET_STATE',
      payload: { loadingAction: true },
    })

    const { socket, clientOnlineStatus } = yield select(state => state.task)

    if (socket.connected && clientOnlineStatus) {
      socket.volatile.emit('task:update_checklist_resolve', {
        ...wrapPayload.payload,
        status: 'false',
      })
    } else {
      notification.error({
        key: 'error',
        message: 'Error',
        description: 'You are offline',
      })
    }

    try {
      response = yield new Promise((resolve, reject) => {
        socket.on('refresh_task', () => resolve(true))
        socket.on('error', error => reject(error))
      })
    } catch (error) {
      console.log(error)
      notification.error({
        key: 'error',
        message: 'Error',
        description: error.message,
      })
    }

    if (response) {
      if (wrapPayload.payload.resetStates) wrapPayload.payload.resetStates()
      /* notification.success({
      key: 'success',
        message: 'Success',
        description: 'Checklist has been unresolved',
      }) */

      const task = yield fork(SET_DOC_IO, { payload: { taskRef: wrapPayload.payload.taskRef } })
      yield cancel(task)
    }

    yield put({
      type: 'task/SET_STATE',
      payload: { loadingAction: false },
    })
  }
}

function* DELETE_CHECKLIST_IO({ payload }) {
  let wrapPayload, response
  while (true) {
    wrapPayload = yield take('task/DELETE_CHECKLIST_IO')

    yield put({
      type: 'task/SET_STATE',
      payload: { loadingAction: true },
    })

    const { socket, clientOnlineStatus } = yield select(state => state.task)

    if (socket.connected && clientOnlineStatus) {
      socket.volatile.emit('task:delete_checklist', wrapPayload.payload)
    } else {
      notification.error({
        key: 'error',
        message: 'Error',
        description: 'You are offline',
      })
    }

    try {
      response = yield new Promise((resolve, reject) => {
        socket.on('refresh_task', () => resolve(true))
        socket.on('error', error => reject(error))
      })
    } catch (error) {
      console.log(error)
      notification.error({
        key: 'error',
        message: 'Error',
        description: error.message,
      })
    }

    if (response) {
      if (wrapPayload.payload.resetStates) wrapPayload.payload.resetStates()
      /* notification.success({
      key: 'success',
        message: 'Success',
        description: 'Checklist has been deleted',
      }) */

      const task = yield fork(SET_DOC_IO, { payload: { taskRef: wrapPayload.payload.taskRef } })
      yield cancel(task)
    }

    yield put({
      type: 'task/SET_STATE',
      payload: { loadingAction: false },
    })
  }
}

function* CREATE_COMMENT_IO({ payload }) {
  let wrapPayload, response
  while (true) {
    wrapPayload = yield take('task/CREATE_COMMENT_IO')

    yield put({
      type: 'task/SET_STATE',
      payload: { loadingAction: true },
    })

    const { socket, clientOnlineStatus } = yield select(state => state.task)

    if (socket.connected && clientOnlineStatus) {
      socket.volatile.emit('task:create_comment', wrapPayload.payload)
    } else {
      notification.error({
        key: 'error',
        message: 'Error',
        description: 'You are offline',
      })
    }

    try {
      response = yield new Promise((resolve, reject) => {
        socket.on('refresh_task', () => resolve(true))
        socket.on('error', error => reject(error))
      })
    } catch (error) {
      console.log(error)
      notification.error({
        key: 'error',
        message: 'Error',
        description: error.message,
      })
    }

    if (response) {
      if (wrapPayload.payload.resetStates) wrapPayload.payload.resetStates()
      /* notification.success({
      key: 'success',
        message: 'Success',
        description: 'Comment has been added',
      }) */

      const task = yield fork(SET_DOC_IO, { payload: { taskRef: wrapPayload.payload.taskRef } })
      yield cancel(task)
    }

    yield put({
      type: 'task/SET_STATE',
      payload: { loadingAction: false },
    })
  }
}

function* UPDATE_COMMENT_IO({ payload }) {
  let wrapPayload, response
  while (true) {
    wrapPayload = yield take('task/UPDATE_COMMENT_IO')

    yield put({
      type: 'task/SET_STATE',
      payload: { loadingAction: true },
    })

    const { socket, clientOnlineStatus } = yield select(state => state.task)

    if (socket.connected && clientOnlineStatus) {
      socket.volatile.emit('task:update_comment', wrapPayload.payload)
    } else {
      notification.error({
        key: 'error',
        message: 'Error',
        description: 'You are offline',
      })
    }

    try {
      response = yield new Promise((resolve, reject) => {
        socket.on('refresh_task', () => resolve(true))
        socket.on('error', error => reject(error))
      })
    } catch (error) {
      console.log(error)
      notification.error({
        key: 'error',
        message: 'Error',
        description: error.message,
      })
    }

    if (response) {
      if (wrapPayload.payload.resetStates) wrapPayload.payload.resetStates()
      /* notification.success({
      key: 'success',
        message: 'Success',
        description: 'Comment has been updated',
      }) */

      const task = yield fork(SET_DOC_IO, { payload: { taskRef: wrapPayload.payload.taskRef } })
      yield cancel(task)
    }

    yield put({
      type: 'task/SET_STATE',
      payload: { loadingAction: false },
    })
  }
}

function* LIKE_COMMENT_IO({ payload }) {
  let wrapPayload, response
  while (true) {
    wrapPayload = yield take('task/LIKE_COMMENT_IO')

    yield put({
      type: 'task/SET_STATE',
      payload: { loadingAction: true },
    })

    const { socket, clientOnlineStatus } = yield select(state => state.task)

    if (socket.connected && clientOnlineStatus) {
      socket.volatile.emit('task:like_comment', wrapPayload.payload)
    } else {
      notification.error({
        key: 'error',
        message: 'Error',
        description: 'You are offline',
      })
    }

    try {
      response = yield new Promise((resolve, reject) => {
        socket.on('refresh_task', () => resolve(true))
        socket.on('error', error => reject(error))
      })
    } catch (error) {
      console.log(error)
      notification.error({
        key: 'error',
        message: 'Error',
        description: error.message,
      })
    }

    if (response) {
      if (wrapPayload.payload.resetStates) wrapPayload.payload.resetStates()
      /* notification.success({
      key: 'success',
        message: 'Success',
        description: 'Comment was liked by you',
      }) */

      const task = yield fork(SET_DOC_IO, { payload: { taskRef: wrapPayload.payload.taskRef } })
      yield cancel(task)
    }

    yield put({
      type: 'task/SET_STATE',
      payload: { loadingAction: false },
    })
  }
}

function* UNLIKE_COMMENT_IO({ payload }) {
  let wrapPayload, response
  while (true) {
    wrapPayload = yield take('task/UNLIKE_COMMENT_IO')

    yield put({
      type: 'task/SET_STATE',
      payload: { loadingAction: true },
    })

    const { socket, clientOnlineStatus } = yield select(state => state.task)

    if (socket.connected && clientOnlineStatus) {
      socket.volatile.emit('task:unlike_comment', wrapPayload.payload)
    } else {
      notification.error({
        key: 'error',
        message: 'Error',
        description: 'You are offline',
      })
    }

    try {
      response = yield new Promise((resolve, reject) => {
        socket.on('refresh_task', () => resolve(true))
        socket.on('error', error => reject(error))
      })
    } catch (error) {
      console.log(error)
      notification.error({
        key: 'error',
        message: 'Error',
        description: error.message,
      })
    }

    if (response) {
      if (wrapPayload.payload.resetStates) wrapPayload.payload.resetStates()
      /* notification.success({
      key: 'success',
        message: 'Success',
        description: 'Comment was unliked by you',
      }) */

      const task = yield fork(SET_DOC_IO, { payload: { taskRef: wrapPayload.payload.taskRef } })
      yield cancel(task)
    }

    yield put({
      type: 'task/SET_STATE',
      payload: { loadingAction: false },
    })
  }
}

function* DELETE_COMMENT_IO({ payload }) {
  let wrapPayload, response
  while (true) {
    wrapPayload = yield take('task/DELETE_COMMENT_IO')

    yield put({
      type: 'task/SET_STATE',
      payload: { loadingAction: true },
    })

    const { socket, clientOnlineStatus } = yield select(state => state.task)

    if (socket.connected && clientOnlineStatus) {
      socket.volatile.emit('task:delete_comment', wrapPayload.payload)
    } else {
      notification.error({
        key: 'error',
        message: 'Error',
        description: 'You are offline',
      })
    }

    try {
      response = yield new Promise((resolve, reject) => {
        socket.on('refresh_task', () => resolve(true))
        socket.on('error', error => reject(error))
      })
    } catch (error) {
      console.log(error)
      notification.error({
        key: 'error',
        message: 'Error',
        description: error.message,
      })
    }

    if (response) {
      if (wrapPayload.payload.resetStates) wrapPayload.payload.resetStates()
      /* notification.success({
      key: 'success',
        message: 'Success',
        description: 'Comment has been deleted',
      }) */

      const task = yield fork(SET_DOC_IO, { payload: { taskRef: wrapPayload.payload.taskRef } })
      yield cancel(task)
    }

    yield put({
      type: 'task/SET_STATE',
      payload: { loadingAction: false },
    })
  }
}

function* DELETE_IO({ payload }) {
  let wrapPayload, response
  while (true) {
    wrapPayload = yield take('task/DELETE_IO')

    yield put({
      type: 'task/SET_STATE',
      payload: { loadingAction: true },
    })

    const { socket, clientOnlineStatus } = yield select(state => state.task)

    if (socket.connected && clientOnlineStatus) {
      socket.volatile.emit('task:delete', wrapPayload.payload)
    } else {
      notification.error({
        key: 'error',
        message: 'Error',
        description: 'You are offline',
      })
    }

    try {
      response = yield new Promise((resolve, reject) => {
        /* if (wrapPayload.payload.pageRef)  */ socket.on('refresh_task', () => resolve(true))
        /* if (!wrapPayload.payload.pageRef)  */ socket.on('refresh_tasks', () => resolve(true))
        socket.on('error', error => reject(error))
      })
    } catch (error) {
      console.log(error)
      notification.error({ key: 'error', message: 'Error', description: error.message })
    }

    if (response) {
      if (wrapPayload.payload.shouldRedirect) yield history.push('/task')
      if (wrapPayload.payload.resetStates) wrapPayload.payload.resetStates()
      /* notification.success({
      key: 'success',
        message: 'Success',
        description: 'Task has been deleted',
      }) */

      if (wrapPayload.payload.pageRef) {
        const task = yield fork(SET_DOC_IO, {
          payload: {
            taskRef: wrapPayload.payload.pageRef,
          },
        })
        yield cancel(task)
      }
    }

    yield put({
      type: 'task/SET_STATE',
      payload: { loadingAction: false },
    })
  }
}

export function* flow({ payload }) {
  const { clientOnlineStatus } = yield select(state => state.task)

  // yield fork(disconnectSaga)
  yield fork(SET_DOCS_IO, { payload })
  yield fork(SET_DOC_IO, { payload })
  yield fork(RE_SET_DOCS_IO, { payload })
  if (clientOnlineStatus) {
    yield fork(CREATE_IO, { payload })
    yield fork(DUPLICATE_IO, { payload })
    yield fork(UPDATE_IO, { payload })
    yield fork(UPDATE_STATUS_TO_TODO_IO, { payload })
    yield fork(UPDATE_STATUS_TO_IN_PROGRESS_IO, { payload })
    yield fork(UPDATE_STATUS_TO_COMPLETED_IO, { payload })
    yield fork(UPDATE_STATUS_TO_CLOSED_IO, { payload })
    yield fork(CREATE_CHECKLIST_IO, { payload })
    yield fork(UPDATE_CHECKLIST_IO, { payload })
    yield fork(RESOLVE_CHECKLIST_IO, { payload })
    yield fork(UNRESOLVE_CHECKLIST_IO, { payload })
    yield fork(DELETE_CHECKLIST_IO, { payload })
    yield fork(CREATE_COMMENT_IO, { payload })
    yield fork(UPDATE_COMMENT_IO, { payload })
    yield fork(LIKE_COMMENT_IO, { payload })
    yield fork(UNLIKE_COMMENT_IO, { payload })
    yield fork(DELETE_COMMENT_IO, { payload })
    yield fork(DELETE_IO, { payload })
  }
  yield fork(connectionSaga, { payload })
}

export function* OPEN_SOCKET_CONNECTION({ payload }) {
  yield call(connect)
  // let { staffRef } = yield select(state => state.user)
  /* yield put({
    type: 'staff/UPDATE',
    payload: { online: 'true', staffRef, dontShowNotification: true, dontFetchDoc: true },
  }) */
  yield fork(flow, { payload })
}

export function* disconnect() {
  const { socket, clientOnlineStatus } = yield select(state => state.task)
  if (socket) {
    socket.close()
  }
  // let { staffRef } = yield select(state => state.user)
  /* yield put({
    type: 'staff/UPDATE',
    payload: { online: 'false', staffRef, dontShowNotification: true, dontFetchDoc: true },
  }) */
}

/* function* disconnectListener() {
  const { socket, clientOnlineStatus } = yield select(state => state.task)
  return new Promise(resolve => {
    socket.on('disconnect', reason => {
      return resolve(reason)
    })
  })
}

function* disconnectSaga() {
  while (true) {
    let reason = yield call(disconnectListener)
    if (reason === 'io server disconnect' || reason === 'transport error') {
      // let { socket } = yield select(state => state.task)
      // socket.auth.refreshToken = store.get('refreshToken')
      // socket.auth.accessToken = store.get('accessToken')
      // socket.connect()
    }

    // yield put({type: SERVER_OFF});
  }
} */

function* connectionListener() {
  const { socket, clientOnlineStatus } = yield select(state => state.task)
  return new Promise(resolve => {
    socket.on('connect', () => {
      return resolve('ok')
    })
  })
}

function* connectionSaga({ payload }) {
  while (true) {
    const result = yield call(connectionListener)
    yield result
    let { socket } = yield select(state => state.task)

    if (result) {
      socket.emit('task:join', {
        taskRef: payload && payload.taskRef ? payload.taskRef : undefined,
        ken: 'turkey',
      })
    }
    // socket.auth.refreshToken = store.get('refreshToken')
    // socket.auth.accessToken = store.get('accessToken')
    // socket.connect()
    // yield call(connect)

    // yield put({type: SERVER_OFF});
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.CREATE, CREATE),
    takeEvery(actions.SET_DOCS, SET_DOCS),
    takeEvery(actions.SET_DOC, SET_DOC),
    takeEvery(actions.SET_CURRENT_PAGE, SET_CURRENT_PAGE),
    takeEvery(actions.SET_LIMIT, SET_LIMIT),
    takeEvery(actions.SET_SORTING, SET_SORTING),
    takeEvery(actions.SET_SEARCH_TEXT, SET_SEARCH_TEXT),
    debounce(500, actions.SET_SEARCH_TEXT, THROTTLE_SET_SEARCH_TEXT),
    takeEvery(actions.SET_FILTERS, SET_FILTERS),
    takeEvery(actions.UPDATE, UPDATE),
    takeEvery(actions.UPDATE_STATUS_TO_TODO, UPDATE_STATUS_TO_TODO),
    takeEvery(actions.UPDATE_STATUS_TO_IN_PROGRESS, UPDATE_STATUS_TO_IN_PROGRESS),
    takeEvery(actions.UPDATE_STATUS_TO_COMPLETED, UPDATE_STATUS_TO_COMPLETED),
    takeEvery(actions.UPDATE_STATUS_TO_CLOSED, UPDATE_STATUS_TO_CLOSED),
    takeEvery(actions.CREATE_CHECKLIST, CREATE_CHECKLIST),
    takeEvery(actions.UPDATE_CHECKLIST, UPDATE_CHECKLIST),
    takeEvery(actions.RESOLVE_CHECKLIST, RESOLVE_CHECKLIST),
    takeEvery(actions.UNRESOLVE_CHECKLIST, UNRESOLVE_CHECKLIST),
    takeEvery(actions.DELETE_CHECKLIST, DELETE_CHECKLIST),
    takeEvery(actions.CREATE_COMMENT, CREATE_COMMENT),
    takeEvery(actions.UPDATE_COMMENT, UPDATE_COMMENT),
    takeEvery(actions.LIKE_COMMENT, LIKE_COMMENT),
    takeEvery(actions.UNLIKE_COMMENT, UNLIKE_COMMENT),
    takeEvery(actions.DELETE_COMMENT, DELETE_COMMENT),
    takeEvery(actions.DELETE, DELETE),

    takeEvery(actions.SET_SOCKET, connect),
    takeEvery(actions.OPEN_SOCKET_CONNECTION, OPEN_SOCKET_CONNECTION),
    takeEvery(actions.CLOSE_SOCKET_CONNECTION, disconnect),
    takeEvery(actions.SET_FILTERS_IO, SET_FILTERS_IO),
    takeEvery(actions.SET_SEARCH_TEXT_IO, SET_SEARCH_TEXT_IO),
    debounce(500, actions.SET_SEARCH_TEXT_IO, THROTTLE_SET_SEARCH_TEXT_IO),
  ])
}

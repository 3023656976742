const actions = {
  CREATE: 'category/CREATE',
  UPDATE: 'category/UPDATE',
  SET_FILTERS: 'category/SET_FILTERS',
  DELETE: 'category/DELETE',
  SET_STATE: 'category/SET_STATE',
  SET_DOCS: 'category/SET_DOCS',
  SET_DOC: 'category/SET_DOC',
  SET_CURRENT_PAGE: 'category/SET_CURRENT_PAGE',
  SET_LIMIT: 'category/SET_LIMIT',
  SET_SORTING: 'category/SET_SORTING',
  SET_SEARCH_TEXT: 'category/SET_SEARCH_TEXT',
}

export default actions

import apiClient from '../axios'

export async function uploadMedia(body) {
  return apiClient
    .post(`/media/upload`, body.data, {
      headers: body.headers,
    })
    .then(response => (response && response.data ? response.data : false)) // eslint-disable-line
    .catch(error => console.log(error.message))
}

export async function deleteMedia(key) {
  return apiClient
    .post(`/media/delete/${key}`)
    .then(response => (response && response.data ? response.data : false)) // eslint-disable-line
    .catch(error => console.log(error))
}

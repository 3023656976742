import { all, put, call, takeEvery, select, debounce } from 'redux-saga/effects'
import { history } from 'index'
import {
  createOrderRequest,
  updateOrderRequest,
  submitOrderRequest,
  reviseOrderRequest,
  approveOrderRequest,
  rejectOrderRequest,
  addOrderItem,
  updateOrderItem,
  deleteOrderItem,
  createComment,
  updateComment,
  addCommentLike,
  removeCommentLike,
  deleteComment,
  getOrderRequests,
  getOrderRequest,
  deleteOrderRequest,
  openOrderRequest,
} from 'services/api/orderRequest'
import { notification } from 'antd'
import actions from './actions'

export function* CREATE({ payload }) {
  const {
    priority,
    deliveryLocation,
    vendor,
    /* tax, deliveryDate, */ resetForm,
    resetStates,
  } = payload

  yield put({
    type: 'orderRequest/SET_STATE',
    payload: {
      loadingAction: true,
    },
  })

  const success = yield call(createOrderRequest, {
    priority,
    deliveryLocation,
    vendor,
    // tax,
    // deliveryDate,
  })

  if (success) {
    notification.success({
      message: 'Success',
      description: 'New orderRequest added',
    })

    if (resetForm) resetForm()
    if (resetStates) resetStates()

    yield history.push(`/purchase-requisition/${success.orderRequestRef}`)
  }

  yield put({
    type: 'orderRequest/SET_STATE',
    payload: {
      loadingAction: false,
    },
  })
}

export function* SET_DOCS() {
  yield put({
    type: 'orderRequest/SET_STATE',
    payload: {
      loadingDocs: true,
    },
  })

  // Get queries
  const {
    limit,
    searchText,
    page,
    pages,
    sortOrder,
    sortCol,
    filterPriority,
    filterLocation,
    filterDepartment,
    filterRequester,
    filterVendor,
    filterStatus,
    filterPaymentStatus,
    filterDateRangeStart,
    filterDateRangeEnd,
  } = yield select(state => state.orderRequest)

  const success = yield call(getOrderRequests, {
    limit,
    searchText,
    page,
    pages,
    sortOrder,
    sortCol,
    filterPriority,
    filterLocation,
    filterDepartment,
    filterRequester,
    filterVendor,
    filterStatus,
    filterPaymentStatus,
    filterDateRangeStart,
    filterDateRangeEnd,
  })

  if (success) {
    const { docs, totalDocs, totalPages } = success

    yield put({
      type: 'orderRequest/SET_STATE',
      payload: {
        docs,
        total: totalDocs,
        pages: totalPages,
      },
    })
  }

  yield put({
    type: 'orderRequest/SET_STATE',
    payload: {
      loadingDocs: false,
    },
  })
}

export function* SET_DOC({ payload }) {
  const { orderRequestRef } = payload

  yield put({
    type: 'orderRequest/SET_STATE',
    payload: {
      loadingDoc: true,
    },
  })

  const doc = yield call(getOrderRequest, orderRequestRef)

  yield put({
    type: 'orderRequest/SET_STATE',
    payload: {
      doc,
      loadingDoc: false,
    },
  })
}

export function* SET_CURRENT_PAGE({ payload }) {
  const { page } = payload

  yield put({
    type: 'orderRequest/SET_STATE',
    payload: {
      page,
    },
  })

  yield call(SET_DOCS)
}

export function* SET_LIMIT({ payload }) {
  const { limit } = payload

  yield put({
    type: 'orderRequest/SET_STATE',
    payload: {
      limit,
    },
  })

  yield call(SET_DOCS)
}

export function* SET_SORTING({ payload }) {
  const { sortOrder, sortCol } = payload

  yield put({
    type: 'orderRequest/SET_STATE',
    payload: {
      sortOrder,
      sortCol,
    },
  })

  yield call(SET_DOCS)
}

export function* SET_SEARCH_TEXT({ payload }) {
  const { searchText } = payload

  yield put({
    type: 'orderRequest/SET_STATE',
    payload: {
      searchText,
      page: 1,
    },
  })
}

export function* THROTTLE_SET_SEARCH_TEXT() {
  yield call(SET_DOCS)
}

export function* SET_FILTERS({ payload }) {
  const {
    filterStatus,
    filterPriority,
    filterLocation,
    filterDepartment,
    filterRequester,
    filterVendor,
    filterDateRangeStart,
    filterDateRangeEnd,
  } = payload

  yield put({
    type: 'orderRequest/SET_STATE',
    payload: {
      filterStatus,
      filterPriority,
      filterLocation,
      filterDepartment,
      filterRequester,
      filterVendor,
      filterDateRangeStart,
      filterDateRangeEnd,
    },
  })

  yield call(SET_DOCS)
}

export function* UPDATE({ payload }) {
  const {
    priority,
    deliveryLocation,
    vendor,
    tax,
    deliveryDate,
    orderRequestRef,
    resetForm,
    resetStates,
  } = payload

  yield put({
    type: 'orderRequest/SET_STATE',
    payload: {
      loadingAction: true,
    },
  })

  const success = yield call(updateOrderRequest, {
    priority,
    deliveryLocation,
    vendor,
    tax,
    deliveryDate,
    orderRequestRef,
  })

  if (success) {
    notification.success({
      message: 'Success',
      description: 'OrderRequest has been updated',
    })

    if (resetForm) resetForm()
    if (resetStates) resetStates()
  }

  yield put({
    type: 'orderRequest/SET_STATE',
    payload: {
      loadingAction: false,
    },
  })
}

export function* SUBMIT({ payload }) {
  const { orderRequestRef, resetForm, resetStates } = payload

  yield put({
    type: 'orderRequest/SET_STATE',
    payload: {
      loadingAction: true,
    },
  })

  const success = yield call(submitOrderRequest, orderRequestRef)

  if (success) {
    yield call(SET_DOC, { payload: { orderRequestRef } })
    notification.success({
      message: 'Success',
      description: 'OrderRequest has been submitted',
    })

    if (resetForm) resetForm()
    if (resetStates) resetStates()
  }

  yield put({
    type: 'orderRequest/SET_STATE',
    payload: {
      loadingAction: false,
    },
  })
}

export function* OPEN({ payload }) {
  const { orderRequestRef, resetForm, resetStates } = payload

  yield put({
    type: 'orderRequest/SET_STATE',
    payload: {
      loadingAction: true,
    },
  })

  const success = yield call(openOrderRequest, orderRequestRef)

  if (success) {
    yield call(SET_DOC, { payload: { orderRequestRef } })
    notification.success({
      message: 'Success',
      description: 'purchase requisition is opened',
    })

    if (resetForm) resetForm()
    if (resetStates) resetStates()
  }

  yield put({
    type: 'orderRequest/SET_STATE',
    payload: {
      loadingAction: false,
    },
  })
}

export function* REVISE({ payload }) {
  const { orderRequestRef, resetForm, resetStates } = payload

  yield put({
    type: 'orderRequest/SET_STATE',
    payload: {
      loadingAction: true,
    },
  })

  const success = yield call(reviseOrderRequest, orderRequestRef)

  if (success) {
    yield call(SET_DOC, { payload: { orderRequestRef } })
    notification.success({
      message: 'Success',
      description: 'OrderRequest has been set to revise',
    })

    if (resetForm) resetForm()
    if (resetStates) resetStates()
  }

  yield put({
    type: 'orderRequest/SET_STATE',
    payload: {
      loadingAction: false,
    },
  })
}

export function* APPROVE({ payload }) {
  const { orderRequestRef, resetForm, resetStates } = payload

  yield put({
    type: 'orderRequest/SET_STATE',
    payload: {
      loadingAction: true,
    },
  })

  const success = yield call(approveOrderRequest, orderRequestRef)

  if (success) {
    yield call(SET_DOC, { payload: { orderRequestRef } })
    notification.success({
      message: 'Success',
      description: 'OrderRequest has been approved',
    })

    if (resetForm) resetForm()
    if (resetStates) resetStates()
  }

  yield put({
    type: 'orderRequest/SET_STATE',
    payload: {
      loadingAction: false,
    },
  })
}

export function* REJECT({ payload }) {
  const { orderRequestRef, resetForm, resetStates } = payload

  yield put({
    type: 'orderRequest/SET_STATE',
    payload: {
      loadingAction: true,
    },
  })

  const success = yield call(rejectOrderRequest, orderRequestRef)

  if (success) {
    yield call(SET_DOC, { payload: { orderRequestRef } })
    notification.success({
      message: 'Success',
      description: 'OrderRequest has been rejected',
    })

    if (resetForm) resetForm()
    if (resetStates) resetStates()
  }

  yield put({
    type: 'orderRequest/SET_STATE',
    payload: {
      loadingAction: false,
    },
  })
}

/* export function* COMPLETE({ payload }) {
  const { orderRequestRef, resetForm, resetStates } = payload

  yield put({
    type: 'orderRequest/SET_STATE',
    payload: {
      loadingAction: true,
    },
  })

  const success = yield call(completeOrderRequest, orderRequestRef)

  if (success) {
    notification.success({
      message: 'Success',
      description: 'OrderRequest has been rejected',
    })

    if (resetForm) resetForm()
    if (resetStates) resetStates()
  }

  yield put({
    type: 'orderRequest/SET_STATE',
    payload: {
      loadingAction: false,
    },
  })
} */

export function* DELETE({ payload }) {
  const { orderRequestRef } = payload

  yield put({
    type: 'orderRequest/SET_STATE',
    payload: {
      loadingAction: true,
    },
  })

  const success = yield call(deleteOrderRequest, orderRequestRef)

  if (success) {
    yield history.push('/purchase-requisition')
    notification.success({
      message: 'Success',
      description: 'OrderRequest has been deleted',
    })
  }

  yield put({
    type: 'orderRequest/SET_STATE',
    payload: {
      loadingAction: false,
    },
  })
}

export function* CREATE_ITEM({ payload }) {
  const { description, orderRequestRef, resetForm, resetStates } = payload

  yield put({
    type: 'orderRequest/SET_STATE',
    payload: {
      loadingAction: true,
    },
  })

  const success = yield call(addOrderItem, {
    description,
    orderRequestRef,
  })

  if (success) {
    yield call(SET_DOC, { payload: { orderRequestRef } })
    notification.success({
      message: 'Success',
      description: 'Order Item has been added',
    })

    if (resetForm) resetForm()
    if (resetStates) resetStates()
  }

  yield put({
    type: 'orderRequest/SET_STATE',
    payload: {
      loadingAction: false,
    },
  })
}

export function* UPDATE_ITEM({ payload }) {
  const {
    description,
    quantity,
    unit,
    unitPrice,
    category,
    subCategory,
    orderItemRef,
    orderRequestRef,
    resetForm,
    resetStates,
  } = payload

  yield put({
    type: 'orderRequest/SET_STATE',
    payload: {
      loadingAction: true,
    },
  })

  const success = yield call(updateOrderItem, {
    description,
    quantity,
    unit,
    unitPrice,
    category,
    subCategory,
    orderItemRef,
    orderRequestRef,
  })

  if (success) {
    yield call(SET_DOC, { payload: { orderRequestRef } })
    notification.success({
      message: 'Success',
      description: 'Order Item has been updated',
    })

    if (resetForm) resetForm()
    if (resetStates) resetStates()
  }

  yield put({
    type: 'orderRequest/SET_STATE',
    payload: {
      loadingAction: false,
    },
  })
}

export function* DELETE_ITEM({ payload }) {
  const { orderItemRef, orderRequestRef, resetForm, resetStates } = payload

  yield put({
    type: 'orderRequest/SET_STATE',
    payload: {
      loadingAction: true,
    },
  })

  const success = yield call(deleteOrderItem, {
    orderItemRef,
    orderRequestRef,
  })

  if (success) {
    yield call(SET_DOC, { payload: { orderRequestRef } })
    notification.success({
      message: 'Success',
      description: 'Order Item has been deleted',
    })

    if (resetForm) resetForm()
    if (resetStates) resetStates()
  }

  yield put({
    type: 'orderRequest/SET_STATE',
    payload: {
      loadingAction: false,
    },
  })
}

export function* CREATE_COMMENT({ payload }) {
  const { resetForm, resetStates, orderRequestRef } = payload

  yield put({
    type: 'orderRequest/SET_STATE',
    payload: {
      loadingAction: true,
    },
  })

  const success = yield call(createComment, payload)

  if (success) {
    notification.success({
      key: 'success',
      message: 'Success',
      description: 'Comment has been added',
    })

    if (resetForm) resetForm()
    if (resetStates) resetStates()

    yield call(SET_DOC, { payload: { orderRequestRef } })
  }

  yield put({
    type: 'orderRequest/SET_STATE',
    payload: {
      loadingAction: false,
    },
  })
}

export function* UPDATE_COMMENT({ payload }) {
  const { resetForm, resetStates, orderRequestRef } = payload

  yield put({
    type: 'orderRequest/SET_STATE',
    payload: {
      loadingAction: true,
    },
  })

  const success = yield call(updateComment, payload)

  if (success) {
    notification.success({
      key: 'success',
      message: 'Success',
      description: 'Comment has been updated',
    })

    if (resetForm) resetForm()
    if (resetStates) resetStates()

    yield call(SET_DOC, { payload: { orderRequestRef } })
  }

  yield put({
    type: 'orderRequest/SET_STATE',
    payload: {
      loadingAction: false,
    },
  })
}

export function* LIKE_COMMENT({ payload }) {
  const { resetForm, resetStates, commentRef, orderRequestRef } = payload

  yield put({
    type: 'orderRequest/SET_STATE',
    payload: {
      loadingAction: true,
    },
  })

  const success = yield call(addCommentLike, commentRef)

  if (success) {
    notification.success({
      key: 'success',
      message: 'Success',
      description: 'Comment was liked by you',
    })

    if (resetForm) resetForm()
    if (resetStates) resetStates()

    yield call(SET_DOC, { payload: { orderRequestRef } })
  }

  yield put({
    type: 'orderRequest/SET_STATE',
    payload: {
      loadingAction: false,
    },
  })
}

export function* UNLIKE_COMMENT({ payload }) {
  const { resetForm, resetStates, commentRef, orderRequestRef } = payload

  yield put({
    type: 'orderRequest/SET_STATE',
    payload: {
      loadingAction: true,
    },
  })

  const success = yield call(removeCommentLike, commentRef)

  if (success) {
    notification.success({
      key: 'success',
      message: 'Success',
      description: 'Comment was unliked by you',
    })

    if (resetForm) resetForm()
    if (resetStates) resetStates()

    yield call(SET_DOC, { payload: { orderRequestRef } })
  }

  yield put({
    type: 'orderRequest/SET_STATE',
    payload: {
      loadingAction: false,
    },
  })
}

export function* DELETE_COMMENT({ payload }) {
  const { orderRequestRef, commentRef } = payload

  yield put({
    type: 'orderRequest/SET_STATE',
    payload: {
      loadingAction: true,
    },
  })

  const success = yield call(deleteComment, commentRef)

  if (success) {
    notification.success({
      key: 'success',
      message: 'Success',
      description: 'Comment has been deleted',
    })

    yield call(SET_DOC, { payload: { orderRequestRef } })
  }

  yield put({
    type: 'orderRequest/SET_STATE',
    payload: {
      loadingAction: false,
    },
  })
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.CREATE, CREATE),
    takeEvery(actions.UPDATE, UPDATE),
    takeEvery(actions.SET_FILTERS, SET_FILTERS),
    takeEvery(actions.DELETE, DELETE),
    takeEvery(actions.CREATE_ITEM, CREATE_ITEM),
    takeEvery(actions.UPDATE_ITEM, UPDATE_ITEM),
    takeEvery(actions.DELETE_ITEM, DELETE_ITEM),
    takeEvery(actions.CREATE_COMMENT, CREATE_COMMENT),
    takeEvery(actions.UPDATE_COMMENT, UPDATE_COMMENT),
    takeEvery(actions.LIKE_COMMENT, LIKE_COMMENT),
    takeEvery(actions.UNLIKE_COMMENT, UNLIKE_COMMENT),
    takeEvery(actions.DELETE_COMMENT, DELETE_COMMENT),
    takeEvery(actions.SUBMIT, SUBMIT),
    takeEvery(actions.OPEN, OPEN),
    takeEvery(actions.REVISE, REVISE),
    takeEvery(actions.APPROVE, APPROVE),
    takeEvery(actions.REJECT, REJECT),
    takeEvery(actions.SET_DOCS, SET_DOCS),
    takeEvery(actions.SET_DOC, SET_DOC),
    takeEvery(actions.SET_CURRENT_PAGE, SET_CURRENT_PAGE),
    takeEvery(actions.SET_LIMIT, SET_LIMIT),
    takeEvery(actions.SET_SORTING, SET_SORTING),
    takeEvery(actions.SET_SEARCH_TEXT, SET_SEARCH_TEXT),
    debounce(500, actions.SET_SEARCH_TEXT, THROTTLE_SET_SEARCH_TEXT),
  ])
}

import { all, takeEvery, put, call, select, debounce } from 'redux-saga/effects'
import { notification } from 'antd'
import { history } from 'index'
import * as firebase from 'services/firebase'
import * as jwt from 'services/jwt'
import {
  getUsers,
  getAC,
  deactivateUser,
  activateUser,
  updateUser,
  changePassword,
  sendActivationLink,
} from 'services/api/user'
import actions from './actions'

const mapAuthProviders = {
  firebase: {
    login: firebase.login,
    register: firebase.register,
    currentAccount: firebase.currentAccount,
    logout: firebase.logout,
  },
  jwt: {
    login: jwt.login,
    activateAccount: jwt.activateAccount,
    resetPassword: jwt.resetPassword,
    forgotPassword: jwt.forgotPassword,
    register: jwt.register,
    currentAccount: jwt.currentAccount,
    logout: jwt.logout,
  },
}

export function* LOGIN({ payload }) {
  const { email, password } = payload
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const { location } = yield select(state => state.router)
  const { authProvider: autProviderName } = yield select(state => state.settings)
  const success = yield call(mapAuthProviders[autProviderName].login, email, password)
  if (success) {
    yield put({
      type: 'user/LOAD_CURRENT_ACCOUNT',
    })
    yield call(SET_AC)
    if (location.state && location.state.page) {
      yield history.push(location.state.page)
    } else {
      yield history.push('/')
    }
  }
  if (!success) {
    notification.error({
      message: 'Error',
      description: 'Your Email or Password is not correct',
    })
    yield put({
      type: 'user/SET_STATE',
      payload: {
        loading: false,
      },
    })
  }
}

export function* REGISTER({ payload }) {
  const { email, password, firstname, lastname } = payload
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const { authProvider } = yield select(state => state.settings)
  const success = yield call(
    mapAuthProviders[authProvider].register,
    email,
    password,
    firstname,
    lastname,
  )
  if (success) {
    yield put({
      type: 'user/LOAD_CURRENT_ACCOUNT',
    })
    yield history.push('/auth/login')
    notification.success({
      message: 'Succesful Registered',
      description: 'You have successfully registered!',
    })
  }
  if (!success) {
    yield put({
      type: 'user/SET_STATE',
      payload: {
        loading: false,
      },
    })
  }
}

export function* ACTIVATE_ACCOUNT({ payload }) {
  const { password, firstname, lastname } = payload
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const { authProvider } = yield select(state => state.settings)

  const success = yield call(
    mapAuthProviders[authProvider].activateAccount,
    password,
    firstname,
    lastname,
  )

  if (success) {
    yield history.push('/auth/login')
    notification.success({
      message: 'Account is active',
      description: 'Your account is now active',
    })
  }

  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export function* FORGOT_PASSWORD({ payload }) {
  const { email, resetForm } = payload
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const { authProvider } = yield select(state => state.settings)

  const success = yield call(mapAuthProviders[authProvider].forgotPassword, email)

  if (success) {
    notification.success({
      message: 'Success',
      description: 'Check your mail',
    })
    if (resetForm) resetForm()
  }

  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export function* RESET_PASSWORD({ payload }) {
  const { password, cPassword, resetForm } = payload
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const { authProvider } = yield select(state => state.settings)

  const success = yield call(mapAuthProviders[authProvider].resetPassword, password, cPassword)

  if (success) {
    if (resetForm) resetForm()

    yield history.push('/auth/login')

    notification.success({
      message: 'Success',
      description: 'Login with your new password',
    })
  }

  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export function* LOAD_CURRENT_ACCOUNT() {
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const { authProvider } = yield select(state => state.settings)
  const response = yield call(mapAuthProviders[authProvider].currentAccount)
  if (response) {
    // console.log(response)
    const {
      _id,
      lastSeen,
      staffRef,
      agentRef,
      clientRef,
      guestRef,
      email,
      firstname,
      lastname,
      avatar,
      type,
      role,
      telephone,
      transactions,
      expenseRequests,
      balance,
      // Client specifics start
      referrer,
      referrerModel,
      nextOfKin,
      reference,
      addresses,
      photo,
      identityProof,
      addressProof,
      totalSuccessfulPayment,
      totalProperties,
      totalOutstandingBalance,
      hasToured,
      // Client specifics end
    } = response

    yield put({
      type: 'user/SET_STATE',
      payload: {
        _id,
        lastSeen,
        staffRef,
        agentRef,
        clientRef,
        guestRef,
        firstname,
        lastname,
        telephone,
        email,
        avatar,
        type,
        role,
        balance,
        authorized: true,
        recentTransactions: transactions,
        recentExpenseRequests: expenseRequests,
        // Client specifics start
        referrer,
        referrerType: referrerModel,
        nextOfKin,
        reference,
        addresses,
        photo,
        identityProof,
        addressProof,
        totalSuccessfulPayment,
        totalProperties,
        totalOutstandingBalance,
        hasToured,
        // Client specifics end
      },
    })
  }
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export function* LOGOUT() {
  const { authProvider } = yield select(state => state.settings)
  const { _id } = yield select(state => state.user)
  yield call(mapAuthProviders[authProvider].logout, _id)
  yield put({
    type: 'user/SET_STATE',
    payload: {
      _id: '',
      staffRef: '',
      firstname: '',
      telephone: '',
      lastname: '',
      type: '',
      role: '',
      email: '',
      avatar: '',
      authorized: false,
      loading: false,
    },
  })
}

export function* SET_DOCS() {
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loadingDocs: true,
    },
  })

  const {
    page,
    pages: initalPages,
    searchText,
    limit,
    sortOrder,
    sortCol,
    // overwriteDocs,
    // docs: initialDocs,
  } = yield select(state => state.user)

  const { docs, totalDocs: total, totalPages: pages } = yield call(getUsers, {
    page,
    pages: initalPages,
    searchText,
    limit,
    sortOrder,
    sortCol,
  })

  yield put({
    type: 'user/SET_STATE',
    payload: {
      docs,
      total,
      pages,
    },
  })

  yield put({
    type: 'user/SET_STATE',
    payload: {
      loadingDocs: false,
    },
  })
}

export function* SET_SEARCH_TEXT({ payload }) {
  const { searchText } = payload

  yield put({
    type: 'user/SET_STATE',
    payload: {
      searchText,
      page: 1,
    },
  })
}

export function* THROTTLE_SET_SEARCH_TEXT() {
  yield call(SET_DOCS)
}

export function* SET_AC() {
  const ac = yield call(getAC)

  yield put({
    type: 'user/SET_STATE',
    payload: {
      ac,
    },
  })
}

export function* ACTIVATE_USER({ payload }) {
  const { resetForm, userRef } = payload

  yield put({
    type: 'user/SET_STATE',
    payload: {
      loadingAction: true,
    },
  })

  const success = yield call(activateUser, userRef)

  if (success) {
    // yield call(SET_DOCS)
    yield put({
      type: 'staff/SET_DOCS',
      payload: {},
    })
    notification.success({
      message: 'Success',
      description: 'User has been activated',
    })

    if (resetForm) resetForm()
  }

  yield put({
    type: 'user/SET_STATE',
    payload: {
      loadingAction: false,
    },
  })
}

export function* DEACTIVATE_USER({ payload }) {
  const { resetForm, userRef } = payload

  yield put({
    type: 'user/SET_STATE',
    payload: {
      loadingAction: true,
    },
  })

  const success = yield call(deactivateUser, userRef)

  if (success) {
    // yield call(SET_DOCS)
    yield put({
      type: 'staff/SET_DOCS',
      payload: {},
    })
    notification.success({
      message: 'Success',
      description: 'User have been deactivated',
    })

    if (resetForm) resetForm()
  }

  yield put({
    type: 'user/SET_STATE',
    payload: {
      loadingAction: false,
    },
  })
}

export function* UPDATE({ payload }) {
  const { contextDispatch, resetForm, resetStates, resetEditState, shouldGetUser } = payload

  yield put({
    type: 'user/SET_STATE',
    payload: {
      loadingAction: true,
    },
  })

  const success = yield call(updateUser, payload)

  if (success) {
    notification.success({
      message: 'Success',
      description: 'User has been updated',
    })

    if (shouldGetUser) yield call(LOAD_CURRENT_ACCOUNT)
    if (contextDispatch && contextDispatch.type) yield put(contextDispatch)
    if (resetForm) resetForm()
    if (resetStates) resetStates()
    if (resetEditState) resetEditState()
  }

  yield put({
    type: 'user/SET_STATE',
    payload: {
      loadingAction: false,
    },
  })
}

export function* CHANGE_PASSWORD({ payload }) {
  const { resetForm, resetStates, setSuccess } = payload

  yield put({
    type: 'user/SET_STATE',
    payload: {
      loadingAction: true,
    },
  })

  const success = yield call(changePassword, payload)

  if (success) {
    notification.success({
      message: 'Success',
      description: 'Password successfully changed',
    })

    if (resetForm) resetForm()
    if (resetStates) resetStates()
    if (setSuccess) setSuccess(true)

    yield put({
      type: actions.LOGOUT,
      payload: {},
    })
  }

  yield put({
    type: 'user/SET_STATE',
    payload: {
      loadingAction: false,
    },
  })
}

export function* SEND_ACTIVATION_LINK({ payload }) {
  const { resetForm, userRef } = payload

  yield put({
    type: 'user/SET_STATE',
    payload: {
      loadingAction: true,
    },
  })

  const success = yield call(sendActivationLink, { userRef })

  if (success) {
    notification.success({
      message: 'Success',
      description: 'Activation Link Sent',
    })

    if (resetForm) resetForm()
  }

  yield put({
    type: 'user/SET_STATE',
    payload: {
      loadingAction: false,
    },
  })
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.SET_DOCS, SET_DOCS),
    takeEvery(actions.SET_SEARCH_TEXT, SET_SEARCH_TEXT),
    debounce(500, actions.SET_SEARCH_TEXT, THROTTLE_SET_SEARCH_TEXT),
    takeEvery(actions.SET_AC, SET_AC),
    takeEvery(actions.LOGIN, LOGIN),
    takeEvery(actions.REGISTER, REGISTER),
    takeEvery('user/ACTIVATE_ACCOUNT', ACTIVATE_ACCOUNT),
    takeEvery('user/DEACTIVATE_USER', DEACTIVATE_USER),
    takeEvery('user/ACTIVATE_USER', ACTIVATE_USER),
    takeEvery('user/FORGOT_PASSWORD', FORGOT_PASSWORD),
    takeEvery('user/RESET_PASSWORD', RESET_PASSWORD),
    takeEvery(actions.LOAD_CURRENT_ACCOUNT, LOAD_CURRENT_ACCOUNT),
    takeEvery(actions.LOGOUT, LOGOUT),
    takeEvery(actions.UPDATE, UPDATE),
    takeEvery(actions.CHANGE_PASSWORD, CHANGE_PASSWORD),
    takeEvery(actions.SEND_ACTIVATION_LINK, SEND_ACTIVATION_LINK),
    LOAD_CURRENT_ACCOUNT(), // run once on app load to check user auth
    SET_AC(), // run once on app load to check user auth
  ])
}

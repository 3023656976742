import actions from './actions'

const initialState = {
  page: 1,
  pages: 1,
  docs: [],
  limit: 10,
  total: 0,
  sortOrder: 'descend',
  sortCol: 'paymentDate',
  filterType: '',
  filterAccount: '',
  filterDateRangeStart: 0,
  filterDateRangeEnd: 0,
  drawerState: false,
  loadingDocs: false,
  loadingAction: false,
}

export default function transactionReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload }

    default:
      return state
  }
}

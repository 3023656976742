import { all, put, call, takeEvery, select, debounce } from 'redux-saga/effects'
import { createSite, updateSite, getSites, getSite, deleteSite } from 'services/api/site'
import { notification } from 'antd'
import actions from './actions'

export function* CREATE({ payload }) {
  const {
    name,
    description,
    country,
    state,
    locality,
    street,
    zip,
    suite,
    resetForm,
    resetStates,
  } = payload

  yield put({
    type: 'site/SET_STATE',
    payload: {
      loadingAction: true,
    },
  })

  const success = yield call(createSite, {
    name,
    description,
    country,
    state,
    locality,
    street,
    zip,
    suite,
  })

  if (success) {
    yield call(SET_DOCS, { payload: {} })
    notification.success({
      message: 'Success',
      description: 'New site added',
    })

    if (resetForm) resetForm()
    if (resetStates) resetStates()
  }

  yield put({
    type: 'site/SET_STATE',
    payload: {
      loadingAction: false,
    },
  })
}

export function* SET_DOCS() {
  yield put({
    type: 'site/SET_STATE',
    payload: {
      loadingDocs: true,
    },
  })

  // Get queries
  const { limit, searchText, page, pages, sortOrder, sortCol } = yield select(state => state.site)

  const success = yield call(getSites, {
    limit,
    searchText,
    page,
    pages,
    sortOrder,
    sortCol,
  })

  if (success) {
    const { docs, totalDocs, totalPages } = success

    yield put({
      type: 'site/SET_STATE',
      payload: {
        docs,
        total: totalDocs,
        pages: totalPages,
      },
    })
  }

  yield put({
    type: 'site/SET_STATE',
    payload: {
      loadingDocs: false,
    },
  })
}

export function* SET_DOC({ payload }) {
  const { siteRef } = payload

  yield put({
    type: 'site/SET_STATE',
    payload: {
      loadingDoc: true,
    },
  })

  const doc = yield call(getSite, siteRef)

  yield put({
    type: 'site/SET_STATE',
    payload: {
      doc,
      loadingDoc: false,
    },
  })
}

export function* SET_CURRENT_PAGE({ payload }) {
  const { page } = payload

  yield put({
    type: 'site/SET_STATE',
    payload: {
      page,
    },
  })

  yield call(SET_DOCS)
}

export function* SET_LIMIT({ payload }) {
  const { limit } = payload

  yield put({
    type: 'site/SET_STATE',
    payload: {
      limit,
    },
  })

  yield call(SET_DOCS)
}

export function* SET_SORTING({ payload }) {
  const { sortOrder, sortCol } = payload

  yield put({
    type: 'site/SET_STATE',
    payload: {
      sortOrder,
      sortCol,
    },
  })

  yield call(SET_DOCS)
}

export function* SET_SEARCH_TEXT({ payload }) {
  const { searchText } = payload

  yield put({
    type: 'site/SET_STATE',
    payload: {
      searchText,
      page: 1,
    },
  })
}

export function* THROTTLE_SET_SEARCH_TEXT() {
  yield call(SET_DOCS)
}

export function* UPDATE({ payload }) {
  const {
    name,
    country,
    state,
    locality,
    street,
    zip,
    description,
    suite,
    siteRef,
    resetForm,
    resetStates,
  } = payload

  yield put({
    type: 'site/SET_STATE',
    payload: {
      loadingAction: true,
    },
  })

  const success = yield call(updateSite, {
    name,
    country,
    state,
    locality,
    street,
    zip,
    description,
    suite,
    siteRef,
  })

  if (success) {
    notification.success({
      message: 'Success',
      description: 'Site has been updated',
    })

    if (resetForm) resetForm()
    if (resetStates) resetStates()
  }

  yield put({
    type: 'site/SET_STATE',
    payload: {
      loadingAction: false,
    },
  })
}

export function* DELETE({ payload }) {
  const { siteRef } = payload

  yield put({
    type: 'site/SET_STATE',
    payload: {
      loadingAction: true,
    },
  })

  const success = yield call(deleteSite, siteRef)

  if (success) {
    yield call(SET_DOCS, {})
    notification.success({
      message: 'Success',
      description: 'Site has been deleted',
    })
  }

  yield put({
    type: 'site/SET_STATE',
    payload: {
      loadingAction: false,
    },
  })
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.CREATE, CREATE),
    takeEvery(actions.UPDATE, UPDATE),
    takeEvery(actions.DELETE, DELETE),
    takeEvery(actions.SET_DOCS, SET_DOCS),
    takeEvery(actions.SET_DOC, SET_DOC),
    takeEvery(actions.SET_CURRENT_PAGE, SET_CURRENT_PAGE),
    takeEvery(actions.SET_LIMIT, SET_LIMIT),
    takeEvery(actions.SET_SORTING, SET_SORTING),
    takeEvery(actions.SET_SEARCH_TEXT, SET_SEARCH_TEXT),
    debounce(500, actions.SET_SEARCH_TEXT, THROTTLE_SET_SEARCH_TEXT),
  ])
}

import { all, put, call, takeEvery, select, debounce } from 'redux-saga/effects'
import { notification } from 'antd'
import { history } from 'index'
import {
  createExpenditure,
  updateExpenditure,
  getExpenditures,
  getExpenditure,
  deleteExpenditure,
  addExpenditurePayment,
  completeExpenditure,
  cancelExpenditure,
  getExpendituresPaymentReport,
} from 'services/api/expenditure'
import actions from './actions'

export function* CREATE({ payload }) {
  const { contextDispatch, resetForm, resetStates } = payload

  yield put({
    type: 'expenditure/SET_STATE',
    payload: {
      loadingAction: true,
    },
  })

  const success = yield call(createExpenditure, payload)

  if (success) {
    yield call(SET_DOCS)
    notification.success({
      message: 'Success',
      description: 'New expenditure added',
    })

    if (contextDispatch && contextDispatch.type) yield put(contextDispatch)
    if (resetForm) resetForm()
    if (resetStates) resetStates()
  }

  yield put({
    type: 'expenditure/SET_STATE',
    payload: {
      loadingAction: false,
    },
  })
}

export function* SET_DOCS() {
  yield put({
    type: 'expenditure/SET_STATE',
    payload: { loadingDocs: true },
  })

  // Get queries
  const {
    limit,
    searchText,
    page,
    pages,
    sortOrder,
    sortCol,
    filterOwner,
    filterOwnerModel,
    filterProject,
    filterBlock,
    filterCategory,
    filterSubCategory,
    filterStatus,
    filterInstallmentDue,
  } = yield select(state => state.expenditure)

  const success = yield call(getExpenditures, {
    limit,
    searchText,
    page,
    pages,
    sortOrder,
    sortCol,
    filterOwner,
    filterOwnerModel,
    filterProject,
    filterBlock,
    filterCategory,
    filterSubCategory,
    filterStatus,
    filterInstallmentDue,
  })

  if (success) {
    const { docs, totalDocs, totalPages } = success

    yield put({
      type: 'expenditure/SET_STATE',
      payload: {
        docs,
        total: totalDocs,
        pages: totalPages,
      },
    })
  }

  yield put({
    type: 'expenditure/SET_STATE',
    payload: {
      loadingDocs: false,
    },
  })
}

export function* SET_PAYMENT_REPORT({ payload }) {
  yield put({
    type: 'expenditure/SET_STATE',
    payload: { loadingAction: true },
  })

  const { filterExpenditures } = payload

  const success = yield call(getExpendituresPaymentReport, {
    filterExpenditures,
  })

  if (success) {
    /* const { paymentDocs = [], totalPayment = 0, totalAmount = 0 } = success

    yield put({
      type: 'expenditure/SET_STATE',
      payload: {
        paymentDocs,
        totalPayment,
        totalAmount,
        totalBalance: totalAmount - totalPayment,
      },
    }) */
  }

  yield put({
    type: 'expenditure/SET_STATE',
    payload: {
      loadingAction: false,
    },
  })
}

export function* SET_DOC({ payload }) {
  const { expenditureRef } = payload

  yield put({
    type: 'expenditure/SET_STATE',
    payload: {
      loadingDoc: true,
    },
  })

  const doc = yield call(getExpenditure, expenditureRef)

  yield put({
    type: 'expenditure/SET_STATE',
    payload: {
      doc,
      loadingDoc: false,
    },
  })
}

export function* SET_CURRENT_PAGE({ payload }) {
  const { page } = payload

  yield put({
    type: 'expenditure/SET_STATE',
    payload: {
      page,
    },
  })

  yield call(SET_DOCS)
}

export function* SET_LIMIT({ payload }) {
  const { limit } = payload

  yield put({
    type: 'expenditure/SET_STATE',
    payload: {
      limit,
    },
  })

  yield call(SET_DOCS)
}

export function* SET_SORTING({ payload }) {
  const { sortOrder, sortCol } = payload

  yield put({
    type: 'expenditure/SET_STATE',
    payload: {
      sortOrder,
      sortCol,
    },
  })

  yield call(SET_DOCS)
}

export function* SET_SEARCH_TEXT({ payload }) {
  const { searchText } = payload

  yield put({
    type: 'expenditure/SET_STATE',
    payload: {
      searchText,
      page: 1,
    },
  })
}

export function* THROTTLE_SET_SEARCH_TEXT() {
  yield call(SET_DOCS)
}

export function* SET_FILTERS({ payload }) {
  const {
    filterStatus,
    filterOwner,
    filterOwnerModel,
    filterProject,
    filterBlock,
    filterCategory,
    filterSubCategory,
    filterInstallmentDue,
  } = payload

  const filterObj = {}

  if (filterStatus || filterStatus === '') {
    filterObj.filterStatus = filterStatus
  }
  if (filterOwner || filterOwner === '') {
    filterObj.filterOwner = filterOwner
  }
  if (filterOwnerModel || filterOwnerModel === '') {
    filterObj.filterOwnerModel = filterOwnerModel
  }
  if (filterProject || filterProject === '') {
    filterObj.filterProject = filterProject
  }
  if (filterBlock || filterBlock === '') {
    filterObj.filterBlock = filterBlock
  }
  if (filterCategory || filterCategory === '') {
    filterObj.filterCategory = filterCategory
  }
  if (filterSubCategory || filterSubCategory === '') {
    filterObj.filterSubCategory = filterSubCategory
  }
  if (filterInstallmentDue) {
    filterObj.filterInstallmentDue = true
  } else {
    filterObj.filterInstallmentDue = false
  }

  yield put({
    type: 'expenditure/SET_STATE',
    payload: filterObj,
  })

  yield call(SET_DOCS)
}

export function* UPDATE({ payload }) {
  const { resetForm, resetStates } = payload

  yield put({
    type: 'expenditure/SET_STATE',
    payload: {
      loadingAction: true,
    },
  })

  const success = yield call(updateExpenditure, payload)

  if (success) {
    notification.success({
      message: 'Success',
      description: 'Expenditure has been updated',
    })

    if (resetForm) resetForm()
    if (resetStates) resetStates()
  }

  yield put({
    type: 'expenditure/SET_STATE',
    payload: {
      loadingAction: false,
    },
  })
}

export function* DELETE({ payload }) {
  const { expenditureRef } = payload

  yield put({
    type: 'expenditure/SET_STATE',
    payload: {
      loadingAction: true,
    },
  })

  const success = yield call(deleteExpenditure, expenditureRef)

  if (success) {
    yield history.push('/expenditure')

    notification.success({
      message: 'Success',
      description: 'Expenditure has been deleted',
    })
  }

  yield put({
    type: 'expenditure/SET_STATE',
    payload: {
      loadingAction: false,
    },
  })
}

export function* CREATE_PAYMENT({ payload }) {
  const { expenditureRef, contextDispatch, resetForm, resetStates } = payload

  yield put({
    type: 'expenditure/SET_STATE',
    payload: {
      loadingAction: true,
    },
  })

  const success = yield call(addExpenditurePayment, payload)

  if (success) {
    if (expenditureRef) yield call(SET_DOC, { payload: { expenditureRef } })
    yield put({
      type: 'expenditure/SET_STATE',
      payload: {
        paymentsDrawerState: false,
        createPaymentModalState: false,
      },
    })
    notification.success({
      message: 'Success',
      description: 'Payment has been created for installment',
    })

    if (contextDispatch && contextDispatch.type) yield put(contextDispatch)
    if (resetForm) resetForm()
    if (resetStates) resetStates()
  }

  yield put({
    type: 'expenditure/SET_STATE',
    payload: {
      loadingAction: false,
    },
  })
}

export function* COMPLETE({ payload }) {
  const { expenditureRef, contextDispatch } = payload

  yield put({
    type: 'expenditure/SET_STATE',
    payload: {
      loadingAction: true,
    },
  })

  const success = yield call(completeExpenditure, expenditureRef)

  if (success) {
    notification.success({
      message: 'Success',
      description: 'Expenditure has been completed',
    })

    yield call(SET_DOC, { payload: { expenditureRef } })

    if (contextDispatch && contextDispatch.type) yield put(contextDispatch)
  }

  yield put({
    type: 'expenditure/SET_STATE',
    payload: {
      loadingAction: false,
    },
  })
}

export function* CANCEL({ payload }) {
  const { expenditureRef, contextDispatch } = payload

  yield put({
    type: 'expenditure/SET_STATE',
    payload: {
      loadingAction: true,
    },
  })

  const success = yield call(cancelExpenditure, expenditureRef)

  if (success) {
    notification.success({
      message: 'Success',
      description: 'Expenditure has been canceled',
    })

    yield call(SET_DOC, { payload: { expenditureRef } })
    if (contextDispatch && contextDispatch.type) yield put(contextDispatch)
  }

  yield put({
    type: 'expenditure/SET_STATE',
    payload: {
      loadingAction: false,
    },
  })
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.CREATE, CREATE),
    takeEvery(actions.UPDATE, UPDATE),
    takeEvery(actions.CREATE_PAYMENT, CREATE_PAYMENT),
    takeEvery(actions.COMPLETE, COMPLETE),
    takeEvery(actions.CANCEL, CANCEL),
    takeEvery(actions.DELETE, DELETE),
    takeEvery(actions.SET_DOCS, SET_DOCS),
    takeEvery(actions.SET_DOC, SET_DOC),
    takeEvery(actions.SET_CURRENT_PAGE, SET_CURRENT_PAGE),
    takeEvery(actions.SET_LIMIT, SET_LIMIT),
    takeEvery(actions.SET_SORTING, SET_SORTING),
    takeEvery(actions.SET_SEARCH_TEXT, SET_SEARCH_TEXT),
    debounce(500, actions.SET_SEARCH_TEXT, THROTTLE_SET_SEARCH_TEXT),
    takeEvery(actions.SET_FILTERS, SET_FILTERS),
    takeEvery(actions.SET_PAYMENT_REPORT, SET_PAYMENT_REPORT),
  ])
}

import { all } from 'redux-saga/effects'
import user from './user/sagas'
import menu from './menu/sagas'
import settings from './settings/sagas'
import meeting from './meeting/sagas'
import asset from './asset/sagas'
import category from './category/sagas'
import subCategory from './subCategory/sagas'
import department from './department/sagas'
import location from './location/sagas'
import order from './order/sagas'
import orderRequest from './orderRequest/sagas'
import role from './role/sagas'
import site from './site/sagas'
import vendor from './vendor/sagas'
import workflow from './workflow/sagas'
import payment from './payment/sagas'
import staff from './staff/sagas'
import block from './block/sagas'
import property from './property/sagas'
import project from './project/sagas'
import lead from './lead/sagas'
import client from './client/sagas'
import plan from './plan/sagas'
import sale from './sale/sagas'
import agent from './agent/sagas'
import media from './media/sagas'
import task from './task/sagas'
import contractor from './contractor/sagas'
import consultant from './consultant/sagas'
import expenditure from './expenditure/sagas'
import expenseRequest from './expenseRequest/sagas'
import transaction from './transaction/sagas'

export default function* rootSaga() {
  yield all([
    payment(),
    user(),
    menu(),
    settings(),
    meeting(),
    asset(),
    category(),
    subCategory(),
    department(),
    location(),
    order(),
    orderRequest(),
    role(),
    site(),
    vendor(),
    workflow(),
    staff(),
    block(),
    property(),
    project(),
    lead(),
    client(),
    plan(),
    sale(),
    agent(),
    media(),
    task(),
    contractor(),
    consultant(),
    expenditure(),
    expenseRequest(),
    transaction(),
  ])
}

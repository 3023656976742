const actions = {
  CREATE: 'block/CREATE',
  CREATE_BULK: 'block/CREATE_BULK',
  CREATE_BULK_WITH_PROPERTIES: 'block/CREATE_BULK_WITH_PROPERTIES',
  UPDATE: 'block/UPDATE',
  DELETE: 'block/DELETE',
  SET_STATE: 'block/SET_STATE',
  SET_DOCS: 'block/SET_DOCS',
  SET_DOC: 'block/SET_DOC',
  SET_CURRENT_PAGE: 'block/SET_CURRENT_PAGE',
  SET_LIMIT: 'block/SET_LIMIT',
  SET_SORTING: 'block/SET_SORTING',
  SET_SEARCH_TEXT: 'block/SET_SEARCH_TEXT',
  SET_FILTERS: 'block/SET_FILTERS',
}

export default actions

// import store from 'store'
import apiClient from '../axios'

export async function createStaff(body) {
  return apiClient
    .post(`/staff/create`, body)
    .then(response => (response && response.data ? response.data : false)) // eslint-disable-line
    .catch(error => console.log(error.message))
}

export async function getStaffs({ page, pages, searchText, limit, sortOrder, sortCol }) {
  return apiClient
    .get(
      `/staff?page=${
        pages >= page ? page : 1
      }&search=${searchText.trim()}&limit=${limit}&sort_order=${sortOrder}&sort_col=${sortCol}`,
    )
    .then(response => (response && response.data ? response.data : false))
    .catch(error => console.log(error))
}

export async function updateStaff(body) {
  return apiClient
    .post(`/staff/update/${body.staffRef}`, body)
    .then(response => (response && response.data ? response.data : false)) // eslint-disable-line
    .catch(error => console.log(error))
}

export async function creditBalance(body) {
  return apiClient
    .post(`/staff/credit-balance`, body)
    .then(response => (response && response.data ? response.data : false)) // eslint-disable-line
    .catch(error => console.log(error))
}

export async function debitBalance(body) {
  return apiClient
    .post(`/staff/debit-balance`, body)
    .then(response => (response && response.data ? response.data : false)) // eslint-disable-line
    .catch(error => console.log(error))
}

export async function deleteStaff(staffRef) {
  return apiClient
    .post(`/staff/delete/${staffRef}`)
    .then(response => (response && response.data ? response.data : false)) // eslint-disable-line
    .catch(error => console.log(error))
}

export async function assignRole(body) {
  return apiClient
    .post(`/staff/assign-role/${body.staffRef}`, body)
    .then(response => (response && response.data ? response.data : false)) // eslint-disable-line
    .catch(error => console.log(error))
}

import apiClient from '../axios'

export async function createWorkflow(body) {
  return apiClient
    .post(`/workflow/create`, body)
    .then(response => (response && response.data ? response.data : false)) // eslint-disable-line
    .catch(error => console.log(error.message))
}

export async function getWorkflows({
  page,
  pages,
  searchText,
  limit,
  sortOrder,
  sortCol,
  filterType,
}) {
  return apiClient
    .get(
      `/workflow?page=${
        pages >= page ? page : 1
      }&search=${searchText.trim()}&limit=${limit}&sort_order=${sortOrder}&sort_col=${sortCol}&filter_type=${filterType}`,
    )
    .then(response => (response && response.data ? response.data : false))
    .catch(error => console.log(error))
}

export async function getWorkflow(workflowRef) {
  return apiClient
    .get(`/workflow/${workflowRef}`)
    .then(response => (response && response.data ? response.data : false))
    .catch(error => console.log(error))
}

export async function updateWorkflow(body) {
  return apiClient
    .post(`/workflow/update/${body.workflowRef}`, body)
    .then(response => (response && response.data ? response.data : false)) // eslint-disable-line
    .catch(error => console.log(error))
}

export async function addWorkflowStep(workflowRef) {
  return apiClient
    .post(`/workflow/step/create/${workflowRef}`)
    .then(response => (response && response.data ? response.data : false)) // eslint-disable-line
    .catch(error => console.log(error))
}

export async function assignApproverToWorkflowStep(body) {
  return apiClient
    .post(`/workflow/step/assign-approver/${body.workflowStepRef}`, body)
    .then(response => (response && response.data ? response.data : false)) // eslint-disable-line
    .catch(error => console.log(error))
}

export async function removeWorkflowStep(workflowStepRef) {
  return apiClient
    .post(`/workflow/step/delete/${workflowStepRef}`)
    .then(response => (response && response.data ? response.data : false)) // eslint-disable-line
    .catch(error => console.log(error))
}

export async function deleteWorkflow(workflowRef) {
  return apiClient
    .post(`/workflow/delete/${workflowRef}`)
    .then(response => (response && response.data ? response.data : false)) // eslint-disable-line
    .catch(error => console.log(error))
}

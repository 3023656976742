/* eslint-disable */
import React from 'react'
import { Tooltip, Button } from 'antd'
import { useSelector } from 'react-redux'
import FavPages from './FavPages'
// import Search from './Search'
// import IssuesHistory from './IssuesHistory'
// import ProjectManagement from './ProjectManagement'
// import LanguageSwitcher from './LanguageSwitcher'
// import Actions from './Actions'
import UserMenu from './UserMenu'
import style from './style.module.scss'

const TopBar = () => {
  const { role, ac: accessControl, staffRef, clientRef, referrer } = useSelector(
    state => state.user,
  )

  return (
    <div className={style.topbar}>
      <div className="mr-auto">
        <FavPages />
      </div>
      {/* 
      <div className="mr-auto">
        <Search />
      </div>
      <div className="mr-4 d-none d-md-block">
        <IssuesHistory />
      </div>
      <div className="mb-0 mr-auto d-xl-block d-none">
        <ProjectManagement />
      </div>
      <div className="mr-4 d-none d-sm-block">
        <LanguageSwitcher />
      </div>
      <div className="mr-4 d-none d-sm-block">
        <Actions />
      </div> */}
      {staffRef && (
        <div className="mr-4 d-none d-sm-block">
          <Tooltip title="To your mail">
            <a href="https://kairoshof.com/webmail" target="_blank">
              <Button type="link" icon={<i className="fe fe-mail" />} />
            </a>
          </Tooltip>
        </div>
      )}
      {clientRef && referrer && referrer.user.telephone && (
        <div className="mr-3 d-none d-sm-block">
          <Tooltip title="Call Agent">
            <a href={`tel:${referrer.user.telephone}`} target="_blank">
              <Button size="large" type="link" icon={<i className="fa fa-phone" />} />
            </a>
          </Tooltip>
        </div>
      )}
      <div>
        <UserMenu />
      </div>
    </div>
  )
}

export default TopBar

import { all, put, call, takeEvery, select, debounce } from 'redux-saga/effects'
import {
  createConsultant,
  updateConsultant,
  getConsultants,
  getConsultant,
  deleteConsultant,
} from 'services/api/consultant'
import { notification } from 'antd'
import actions from './actions'

export function* CREATE({ payload }) {
  const { resetForm, resetStates } = payload

  yield put({
    type: 'consultant/SET_STATE',
    payload: {
      loadingAction: true,
    },
  })

  const success = yield call(createConsultant, payload)

  if (success) {
    yield call(SET_DOCS, { payload: {} })
    notification.success({
      message: 'Success',
      description: 'New consultant added',
    })

    if (resetForm) resetForm()
    if (resetStates) resetStates()
  }

  yield put({
    type: 'consultant/SET_STATE',
    payload: {
      loadingAction: false,
    },
  })
}

export function* SET_DOCS() {
  yield put({
    type: 'consultant/SET_STATE',
    payload: {
      loadingDocs: true,
    },
  })

  // Get queries
  const { limit, searchText, page, pages, sortOrder, sortCol } = yield select(
    state => state.consultant,
  )

  const success = yield call(getConsultants, {
    limit,
    searchText,
    page,
    pages,
    sortOrder,
    sortCol,
  })

  if (success) {
    const { docs, totalDocs, totalPages } = success

    yield put({
      type: 'consultant/SET_STATE',
      payload: {
        docs,
        total: totalDocs,
        pages: totalPages,
      },
    })
  }

  yield put({
    type: 'consultant/SET_STATE',
    payload: {
      loadingDocs: false,
    },
  })
}

export function* SET_DOC({ payload }) {
  const { consultantRef } = payload

  yield put({
    type: 'consultant/SET_STATE',
    payload: {
      loadingDoc: true,
    },
  })

  const doc = yield call(getConsultant, consultantRef)

  yield put({
    type: 'consultant/SET_STATE',
    payload: {
      doc,
      loadingDoc: false,
    },
  })
}

export function* SET_CURRENT_PAGE({ payload }) {
  const { page } = payload

  yield put({
    type: 'consultant/SET_STATE',
    payload: {
      page,
    },
  })

  yield call(SET_DOCS)
}

export function* SET_LIMIT({ payload }) {
  const { limit } = payload

  yield put({
    type: 'consultant/SET_STATE',
    payload: {
      limit,
    },
  })

  yield call(SET_DOCS)
}

export function* SET_SORTING({ payload }) {
  const { sortOrder, sortCol } = payload

  yield put({
    type: 'consultant/SET_STATE',
    payload: {
      sortOrder,
      sortCol,
    },
  })

  yield call(SET_DOCS)
}

export function* SET_SEARCH_TEXT({ payload }) {
  const { searchText } = payload

  yield put({
    type: 'consultant/SET_STATE',
    payload: {
      searchText,
      page: 1,
    },
  })
}

export function* THROTTLE_SET_SEARCH_TEXT() {
  yield call(SET_DOCS)
}

export function* UPDATE({ payload }) {
  const { resetForm, resetStates } = payload

  yield put({
    type: 'consultant/SET_STATE',
    payload: {
      loadingAction: true,
    },
  })

  const success = yield call(updateConsultant, payload)

  if (success) {
    notification.success({
      message: 'Success',
      description: 'Consultant has been updated',
    })

    if (resetForm) resetForm()
    if (resetStates) resetStates()
  }

  yield put({
    type: 'consultant/SET_STATE',
    payload: {
      loadingAction: false,
    },
  })
}

export function* DELETE({ payload }) {
  const { consultantRef } = payload

  yield put({
    type: 'consultant/SET_STATE',
    payload: {
      loadingAction: true,
    },
  })

  const success = yield call(deleteConsultant, consultantRef)

  if (success) {
    yield call(SET_DOCS, {})
    notification.success({
      message: 'Success',
      description: 'Consultant has been deleted',
    })
  }

  yield put({
    type: 'consultant/SET_STATE',
    payload: {
      loadingAction: false,
    },
  })
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.CREATE, CREATE),
    takeEvery(actions.UPDATE, UPDATE),
    takeEvery(actions.DELETE, DELETE),
    takeEvery(actions.SET_DOCS, SET_DOCS),
    takeEvery(actions.SET_DOC, SET_DOC),
    takeEvery(actions.SET_CURRENT_PAGE, SET_CURRENT_PAGE),
    takeEvery(actions.SET_LIMIT, SET_LIMIT),
    takeEvery(actions.SET_SORTING, SET_SORTING),
    takeEvery(actions.SET_SEARCH_TEXT, SET_SEARCH_TEXT),
    debounce(500, actions.SET_SEARCH_TEXT, THROTTLE_SET_SEARCH_TEXT),
  ])
}

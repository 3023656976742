import apiClient from '../axios'

export async function createTask(body) {
  return apiClient
    .post(`/task/create`, body.data, {
      headers: body.headers,
    })
    .then(response => (response && response.data ? response.data : false)) // eslint-disable-line
    .catch(error => console.log(error.message))
}

export async function getTasks({
  page,
  pages,
  searchText,
  limit,
  sortOrder,
  sortCol,
  filterStatus,
  filterDueDate,
  filterPriority,
  filterDepartment,
  filterCategory,
  filterSubCategory,
  filterHasBlockingDependency,
  filterHasWaitingOnDependency,
  filterCreatedBy,
  filterAssignees,
  filterFollowers,
  filterType,
  filterContractors,
  filterOwner,
  filterOwnerModel,
}) {
  return apiClient
    .get(
      `/task?page=${
        pages >= page ? page : 1
      }&search=${searchText.trim()}&limit=${limit}&sort_order=${sortOrder}&sort_col=${sortCol}&filter_status=${filterStatus}&filter_dueDate=${filterDueDate}&filter_priority=${filterPriority}&filter_department=${filterDepartment}&filter_category=${filterCategory}&filter_subCategory=${filterSubCategory}&filter_hasBlockingDependency=${filterHasBlockingDependency}&filter_hasWaitingOnDependency=${filterHasWaitingOnDependency}&filter_createdBy=${filterCreatedBy}&filter_assignees=${filterAssignees}&filter_followers=${filterFollowers}&filter_type=${filterType}&filter_contractors=${filterContractors}&filter_owner=${filterOwner}&filter_ownerModel=${filterOwnerModel}`,
    )
    .then(response => (response && response.data ? response.data : false))
    .catch(error => console.log(error))
}

export async function getTask(taskRef) {
  return apiClient
    .get(`/task/${taskRef}`)
    .then(response => (response && response.data ? response.data : false))
    .catch(error => console.log(error))
}

export async function updateTask(body) {
  return apiClient
    .post(`/task/update/${body.taskRef}`, body)
    .then(response => (response && response.data ? response.data : false)) // eslint-disable-line
    .catch(error => console.log(error))
}

export async function updateTaskStatusToTodo(taskRef) {
  return apiClient
    .post(`/task/update-status-to-todo/${taskRef}`)
    .then(response => (response && response.data ? response.data : false)) // eslint-disable-line
    .catch(error => console.log(error))
}

export async function updateTaskStatusToInProgress(taskRef) {
  return apiClient
    .post(`/task/update-status-to-in_progress/${taskRef}`)
    .then(response => (response && response.data ? response.data : false)) // eslint-disable-line
    .catch(error => console.log(error))
}

export async function updateTaskStatusToCompleted(taskRef) {
  return apiClient
    .post(`/task/update-status-to-completed/${taskRef}`)
    .then(response => (response && response.data ? response.data : false)) // eslint-disable-line
    .catch(error => console.log(error))
}

export async function updateTaskStatusToClosed(taskRef) {
  return apiClient
    .post(`/task/update-status-to-closed/${taskRef}`)
    .then(response => (response && response.data ? response.data : false)) // eslint-disable-line
    .catch(error => console.log(error))
}

export async function createCheckList(body) {
  return apiClient
    .post(`/task/checklist/create`, body)
    .then(response => (response && response.data ? response.data : false)) // eslint-disable-line
    .catch(error => console.log(error.message))
}

export async function updateCheckList(body) {
  return apiClient
    .post(`/task/checklist/update/${body.checkListRef}`, body)
    .then(response => (response && response.data ? response.data : false)) // eslint-disable-line
    .catch(error => console.log(error))
}

export async function updateCheckListResolve(body) {
  return apiClient
    .post(`/task/checklist/update-resolve/${body.checkListRef}`, body)
    .then(response => (response && response.data ? response.data : false)) // eslint-disable-line
    .catch(error => console.log(error))
}

export async function deleteCheckList(checkListRef) {
  return apiClient
    .post(`/task/checklist/delete/${checkListRef}`)
    .then(response => (response && response.data ? response.data : false)) // eslint-disable-line
    .catch(error => console.log(error))
}

export async function createComment(body) {
  return apiClient
    .post(`/task/comment/create`, body)
    .then(response => (response && response.data ? response.data : false)) // eslint-disable-line
    .catch(error => console.log(error.message))
}

export async function updateComment(body) {
  return apiClient
    .post(`/task/comment/update/${body.commentRef}`, body)
    .then(response => (response && response.data ? response.data : false)) // eslint-disable-line
    .catch(error => console.log(error))
}

export async function addCommentLike(commentRef) {
  return apiClient
    .post(`/task/comment/add-like/${commentRef}`)
    .then(response => (response && response.data ? response.data : false)) // eslint-disable-line
    .catch(error => console.log(error))
}

export async function removeCommentLike(commentRef) {
  return apiClient
    .post(`/task/comment/remove-like/${commentRef}`)
    .then(response => (response && response.data ? response.data : false)) // eslint-disable-line
    .catch(error => console.log(error))
}

export async function deleteComment(commentRef) {
  return apiClient
    .post(`/task/comment/delete/${commentRef}`)
    .then(response => (response && response.data ? response.data : false)) // eslint-disable-line
    .catch(error => console.log(error))
}

export async function deleteTask(taskRef) {
  return apiClient
    .post(`/task/delete/${taskRef}`)
    .then(response => (response && response.data ? response.data : false)) // eslint-disable-line
    .catch(error => console.log(error))
}

import apiClient from '../axios'

export async function createConsultant(body) {
  return apiClient
    .post(`/consultant/create`, body)
    .then(response => (response && response.data ? response.data : false)) // eslint-disable-line
    .catch(error => console.log(error.message))
}

export async function getConsultants({ page, pages, searchText, limit, sortOrder, sortCol }) {
  return apiClient
    .get(
      `/consultant?page=${
        pages >= page ? page : 1
      }&search=${searchText.trim()}&limit=${limit}&sort_order=${sortOrder}&sort_col=${sortCol}`,
    )
    .then(response => (response && response.data ? response.data : false))
    .catch(error => console.log(error))
}

export async function getConsultant(consultantRef) {
  return apiClient
    .get(`/consultant/${consultantRef}`)
    .then(response => (response && response.data ? response.data : false))
    .catch(error => console.log(error))
}

export async function updateConsultant(body) {
  return apiClient
    .post(`/consultant/update/${body.consultantRef}`, body)
    .then(response => (response && response.data ? response.data : false)) // eslint-disable-line
    .catch(error => console.log(error))
}

export async function deleteConsultant(consultantRef) {
  return apiClient
    .post(`/consultant/delete/${consultantRef}`)
    .then(response => (response && response.data ? response.data : false)) // eslint-disable-line
    .catch(error => console.log(error))
}

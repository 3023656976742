import apiClient from '../axios'

export async function createContractor(body) {
  return apiClient
    .post(`/contractor/create`, body)
    .then(response => (response && response.data ? response.data : false)) // eslint-disable-line
    .catch(error => console.log(error.message))
}

export async function getContractors({ page, pages, searchText, limit, sortOrder, sortCol }) {
  return apiClient
    .get(
      `/contractor?page=${
        pages >= page ? page : 1
      }&search=${searchText.trim()}&limit=${limit}&sort_order=${sortOrder}&sort_col=${sortCol}`,
    )
    .then(response => (response && response.data ? response.data : false))
    .catch(error => console.log(error))
}

export async function getContractor(contractorRef) {
  return apiClient
    .get(`/contractor/${contractorRef}`)
    .then(response => (response && response.data ? response.data : false))
    .catch(error => console.log(error))
}

export async function updateContractor(body) {
  return apiClient
    .post(`/contractor/update/${body.contractorRef}`, body)
    .then(response => (response && response.data ? response.data : false)) // eslint-disable-line
    .catch(error => console.log(error))
}

export async function deleteContractor(contractorRef) {
  return apiClient
    .post(`/contractor/delete/${contractorRef}`)
    .then(response => (response && response.data ? response.data : false)) // eslint-disable-line
    .catch(error => console.log(error))
}

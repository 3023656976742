import { all, put, call, takeEvery /* select, debounce */ } from 'redux-saga/effects'
import { uploadMedia, deleteMedia } from 'services/api/media'
import { notification } from 'antd'
import actions from './actions'

export function* UPLOAD({ payload }) {
  const { contextDispatch, resetForm, resetStates, resetFileList } = payload

  yield put({
    type: 'media/SET_STATE',
    payload: {
      loadingAction: true,
    },
  })

  const success = yield call(uploadMedia, payload)

  if (success) {
    notification.success({
      message: 'Success',
      description: 'media added',
    })

    if (contextDispatch && contextDispatch.type) yield put(contextDispatch)
    if (resetForm) resetForm()
    if (resetStates) resetStates()
    if (resetFileList) resetFileList()
  }

  yield put({
    type: 'media/SET_STATE',
    payload: {
      loadingAction: false,
    },
  })
}

export function* DELETE({ payload }) {
  const { contextDispatch, key } = payload

  yield put({
    type: 'media/SET_STATE',
    payload: {
      loadingAction: true,
    },
  })

  const success = yield call(deleteMedia, key)

  if (success) {
    notification.success({
      message: 'Success',
      description: 'Media has been deleted',
    })

    if (contextDispatch && contextDispatch.type) yield put(contextDispatch)
  }

  yield put({
    type: 'media/SET_STATE',
    payload: {
      loadingAction: false,
    },
  })
}

export default function* rootSaga() {
  yield all([takeEvery(actions.UPLOAD, UPLOAD), takeEvery(actions.DELETE, DELETE)])
}

const actions = {
  CREATE: 'vendor/CREATE',
  UPDATE: 'vendor/UPDATE',
  DELETE: 'vendor/DELETE',
  SET_STATE: 'vendor/SET_STATE',
  SET_DOCS: 'vendor/SET_DOCS',
  SET_DOC: 'vendor/SET_DOC',
  SET_CURRENT_PAGE: 'vendor/SET_CURRENT_PAGE',
  SET_LIMIT: 'vendor/SET_LIMIT',
  SET_SORTING: 'vendor/SET_SORTING',
  SET_SEARCH_TEXT: 'vendor/SET_SEARCH_TEXT',
}

export default actions

import apiClient from '../axios'

export async function createSite(body) {
  return apiClient
    .post(`/site/create`, body)
    .then(response => (response && response.data ? response.data : false)) // eslint-disable-line
    .catch(error => console.log(error.message))
}

export async function getSites({ page, pages, searchText, limit, sortOrder, sortCol }) {
  return apiClient
    .get(
      `/site?page=${
        pages >= page ? page : 1
      }&search=${searchText.trim()}&limit=${limit}&sort_order=${sortOrder}&sort_col=${sortCol}`,
    )
    .then(response => (response && response.data ? response.data : false))
    .catch(error => console.log(error))
}

export async function getSite(siteRef) {
  return apiClient
    .get(`/site/${siteRef}`)
    .then(response => (response && response.data ? response.data : false))
    .catch(error => console.log(error))
}

export async function updateSite(body) {
  return apiClient
    .post(`/site/update/${body.siteRef}`, body)
    .then(response => (response && response.data ? response.data : false)) // eslint-disable-line
    .catch(error => console.log(error))
}

export async function deleteSite(siteRef) {
  return apiClient
    .post(`/site/delete/${siteRef}`)
    .then(response => (response && response.data ? response.data : false)) // eslint-disable-line
    .catch(error => console.log(error))
}

const actions = {
  CREATE: 'client/CREATE',
  UPDATE: 'client/UPDATE',
  ADD_ADDRESS: 'client/ADD_ADDRESS',
  REMOVE_ADDRESS: 'client/REMOVE_ADDRESS',
  DELETE: 'client/DELETE',
  SET_STATE: 'client/SET_STATE',
  SET_DOCS: 'client/SET_DOCS',
  GET_CSV: 'client/GET_CSV',
  SET_DOC: 'client/SET_DOC',
  SET_CURRENT_PAGE: 'client/SET_CURRENT_PAGE',
  SET_LIMIT: 'client/SET_LIMIT',
  SET_SORTING: 'client/SET_SORTING',
  SET_SEARCH_TEXT: 'client/SET_SEARCH_TEXT',
  SET_FILTERS: 'client/SET_FILTERS',
}

export default actions

/* eslint-disable */
import { all, put, call, select, takeEvery } from 'redux-saga/effects'
import getMenuData from 'services/menu'
import actions from './actions'

export function* GET_DATA() {
  const { role, type: userType, ac: accessControl } = yield select(state => state.user)

  const menuData = yield call(getMenuData)

  const filteredMenuData = menuData.filter(item => {
    if (['dashboard'].includes(item.key)) {
      return true
    }

    if (
      item.key === 'meetings' &&
      accessControl &&
      accessControl._grants[role] &&
      accessControl._grants[role]['meeting']
    ) {
      return (
        accessControl._grants[role].meeting['read:any'] ||
        accessControl._grants[role].meeting['read:own']
      )
    }

    if (
      item.key === 'payment' &&
      accessControl &&
      accessControl._grants[role] &&
      accessControl._grants[role]['payment']
    ) {
      return (
        accessControl._grants[role].payment['read:any'] ||
        accessControl._grants[role].payment['read:own']
      )
    }

    if (
      item.key === 'staff' &&
      accessControl &&
      accessControl._grants[role] &&
      accessControl._grants[role]['staff']
    ) {
      return accessControl._grants[role].staff['read:any']
    }

    if (
      item.key === 'agent' &&
      accessControl &&
      accessControl._grants[role] &&
      accessControl._grants[role]['agent']
    ) {
      return accessControl._grants[role].agent['read:any']
    }

    if (['client', 'property'].includes(item.key) && userType === 'CLIENT') {
      return false
    }

    if (accessControl && accessControl._grants[role] && accessControl._grants[role][item.key]) {
      return (
        accessControl._grants[role][item.key]['read:any'] ||
        accessControl._grants[role][item.key]['read:own']
      )
    }

    return false
  })

  yield put({
    type: 'menu/SET_STATE',
    payload: {
      menuData: filteredMenuData,
    },
  })
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.GET_DATA, GET_DATA),
    GET_DATA(), // run once on app load to fetch menu data
  ])
}

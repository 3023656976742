const actions = {
  SET_STATE: 'user/SET_STATE',
  SET_DOCS: 'user/SET_DOCS',
  SET_AC: 'user/SET_AC',
  SET_CURRENT_PAGE: 'user/SET_CURRENT_PAGE',
  SET_LIMIT: 'user/SET_LIMIT',
  SET_SORTING: 'user/SET_SORTING',
  SET_SEARCH_TEXT: 'user/SET_SEARCH_TEXT',
  THROTTLE_SET_SEARCH_TEXT: 'user/THROTTLE_SET_SEARCH_TEXT',
  LOGIN: 'user/LOGIN',
  REGISTER: 'user/REGISTER',
  LOAD_CURRENT_ACCOUNT: 'user/LOAD_CURRENT_ACCOUNT',
  ASSIGN_ROLE: 'user/ASSIGN_ROLE',
  LOGOUT: 'user/LOGOUT',
  UPDATE: 'user/UPDATE',
  CHANGE_PASSWORD: 'user/CHANGE_PASSWORD',
  SEND_ACTIVATION_LINK: 'user/SEND_ACTIVATION_LINK',
}

export default actions
